<template>
  <div class="container mx-auto py-8 px-4 lg:px-8 bg-orange-50 rounded-lg my-8 lg:my-24" :style="{ maxWidth: '1440px' }">
    <div>
      <h1 class="text-4xl font-bold mb-4 text-center">Condition Assessment</h1>
      <hr class="mb-8">
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
      <div v-for="(category, index) in categories" :key="index" class="mb-8 lg:mb-0">
        <p class="text-sm font-semibold mb-2">{{ category.name }}</p>
        <hr class="mb-4">
        <ul>
          <li v-for="(condition, conditionIndex) in category.conditions" :key="conditionIndex">{{ condition }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConditionAssessment',
  data() {
    return {
      categories: [
        {
          name: 'Engine',
          conditions: ['Condition 1 for Engine', 'Condition 2 for Engine', 'Condition 3 for Engine' , 'Condition 4 for Engine', 'Condition 5 for Engine', 'Condition 6 for Engine']
        },
        {
          name: 'Electronics',
          conditions: ['Condition 1 for Electronics', 'Condition 2 for Electronics', 'Condition 3 for Electronics' , 'Condition 4 for Electronics' , 'Condition 5 for Electronics' , 'Condition 6 for Electronics']
        },
        {
          name: 'Transmission & Clutch',
          conditions: ['Condition 1 for Transmission & Clutch', 'Condition 2 for Transmission & Clutch', 'Condition 3 for Transmission & Clutch' , 'Condition 4 for Transmission & Clutch', 'Condition 5 for Transmission & Clutch', 'Condition 6 for Transmission & Clutch']
        },
      ]
    };
  }
};
</script>

<style scoped>
/* Add any additional styling here */

/* Example of media query for smaller screens */
@media (min-width: 768px) {
  .grid-cols-1 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .grid-cols-1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
</style>
