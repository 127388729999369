<template>
  <section>
    <div class="bg-white py-6 rounded-lg px-4">
      <div class="flex">
        <div class="font-[sarabun] ml-2 grid content-center w-full">
          <div class="flex font-[oxanium] font-semibold mb-3">
            <p class="basis-2/5">#NV{{ orderItem.id.split("-")[0] }}</p>
            <p class="basis-3/5 text-right">Ksh {{ orderItem.total_price.toLocaleString() }}</p>
          </div>
          <label class="font-[sarabun] text-gray-400"
            >Order Items:
            <label class="text-black ml-3">{{ orderItem.total_items }}</label>
          </label>
          <label class="font-[sarabun] text-gray-400"
            >Order Date:
            <label class="text-black ml-3">{{ formatDate(orderItem.created_at) }}</label>
          </label>
          <div class="flex mt-6 space-x-6 mb-8">
            <div class="flex items-center space-x-4 border rounded py-2 px-4">
              <div class="bg-purple-100 rounded-full py-2 px-3">
                <font-awesome-icon
                  :icon="['fas', 'basket-shopping']"
                  class="text-purple-600"
                />
              </div>
              <div>
                <p class="font-[oxanium] font-semibold">{{ orderItem.vehicle_order_item.length }}</p>
                <p class="font-[sarabun] text-gray-400">Total Items</p>
              </div>
            </div>
            <div class="flex items-center space-x-4 border rounded py-2 px-4">
              <div class="bg-green-100 rounded-full py-2 px-3">
                <font-awesome-icon
                  :icon="['fas', 'basket-shopping']"
                  class="text-green-600"
                />
              </div>
              <div>
                <p class="font-[oxanium] font-semibold">2</p>
                <p class="font-[sarabun] text-gray-400">Active Items</p>
              </div>
            </div>
            <div class="flex items-center space-x-4 border rounded py-2 px-4">
              <div class="bg-orange-100 rounded-full py-2 px-3">
                <font-awesome-icon
                  :icon="['fas', 'basket-shopping']"
                  class="text-orange-400"
                />
              </div>
              <div>
                <p class="font-[oxanium] font-semibold">4</p>
                <p class="font-[sarabun] text-gray-400">Completed Items</p>
              </div>
            </div>
            <div class="flex items-center space-x-4 border rounded py-2 px-4">
              <div class="bg-red-100 rounded-full py-2 px-3">
                <font-awesome-icon
                  :icon="['fas', 'basket-shopping']"
                  class="text-red-600"
                />
              </div>
              <div>
                <p class="font-[oxanium] font-semibold">4</p>
                <p class="font-[sarabun] text-gray-400">Cancelled Items</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    orderItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(dateToFormat) {
      const dateObject = new Date(dateToFormat);
      
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};
      return dateObject.toLocaleString(undefined, options);
    }
  }
};
</script>
