import SupabaseAdapter from "../adapters/SupabaseAdapter"
import { authenticated, logger } from "../decorators/decorator"
import DataProxy from "./DataProxy"
import store from "@/store"

class DataRepository {
    constructor() {
        const adapter = new SupabaseAdapter()
        this.service = new DataProxy(adapter)
    }

    @logger
    registerUser(email, password) {
        return this.service.register({ email: email, password: password, phoneNumber: null })
    }

    @logger
    loginUser(email, password) {
        return this.service.login({ email: email, password: password }).then(response => {
            if (response.success.user != null) {
                store.commit('updateUserData', response.success.user)
            }

            if (response.success.session != null) {
                store.commit('updateUserSession', response.success.session)
                store.commit('updateLoginState', true)
            }
            return response
        })

    }

    @logger
    signOutUser() {
        return this.service.signOut().then(response => {
            if (response.error == null) {
                store.commit('updateUserSession', null)
                store.commit('updateUserData', null)
                store.commit('updateLoginState', false)
            }
            return response
        })
    }

    @logger
    getUser(token = null) {
        return this.service.getUser(token)
    }

    @logger
    getUserSession() {
        return this.service.getUserSession().then(response => {
            if (response.success.session) {
                store.commit('updateUserSession', response.success.session)
                store.commit('updateUserData', response.success.session.user)
                store.commit('updateLoginState', true)

            }

            return response
        })
    }

    @logger
    forgotPassword(email) {
        return this.service.forgotPassword(email)
    }

    @logger
    @authenticated
    fetchAllUsers() {
        return this.service.fetchData({ schema: "auth" })
    }

    @logger
    @authenticated
    updateUserDetails(userDetailsJson) {
        return this.service.updateUserDetails({ newUserDetails: userDetailsJson })
    }

    @logger
    @authenticated
    deleteUserAccount(userId) {
        return this.service.deleteUser(userId)
    }

    @logger
    fetchVehicles() {
        return this.service.fetchData({
            table: 'vehicle',
            column: '*, brand_model(*, brand(*))'
        })
    }

    @logger
    fetchContactTypes() {
        return this.service.fetchData({ table: 'contact_type' })
    }

    @logger
    fetchVehicleBrands() {
        return this.service.fetchData({ table: 'brand' })
    }

    @logger
    fetchVehicleBrandModels(brandId) {
        return this.service.fetchData({
            table: 'brand_model',
            column: `*`,
            filters: [
                { column: 'brand', operator: 'eq', value: brandId }
            ]
        })
    }

    @logger
    fetchAssessments() {
        return this.service.fetchData({ table: "assessment" })
    }

    @logger
    fetchVehicleBodyType() {
        return this.service.fetchData({ table: 'body_type' })
    }

    @logger
    @authenticated
    fetchUserVehicleOrders(user_id) {
        return this.service.fetchData({
            table: "vehicle_order",
            column: "*, vehicle_order_item(*)",
            filters: [
                { column: "user_id", operator: "eq", value: user_id }
            ]
        })
    }

    @logger
    fetchInterests() {
        return this.service.fetchData({
            table: 'interest'
        })
    }

    @logger
    createProfile(user_id) {
        return this.service.postData({
            table: "profile",
            data: {
                user_id: user_id
            }
        })
    }

    @logger
    @authenticated
    fetchUserVehicleSellOrders(user_id) {
        return this.service.fetchData({
            table: "sell_vehicle",
            filters: [
                { column: "posted_by", operator: "eq", value: user_id }
            ]
        })
    }

    @logger
    sellVehicle(vehicle) {
        return this.service.postData({
            table: "sell_vehicle",
            data: vehicle
        })
    }

    @logger
    @authenticated
    fetchUserCartItems(userId) {
        return this.service.fetchData({
            table: 'profile',
            columns: 'cart',
            filters: [
                { column: 'user_id', operator: 'eq', value: userId }
            ]
        })
    }

    @logger
    filterVehicles(filters) {
        return this.service.fetchData({
            table: 'vehicle',
            column: '*, brand_model!inner(*, brand(*))',
            filters: filters
        })
    }

    @logger
    sortVehicles(orderBy) {
        return this.service.fetchData({
            table: 'vehicle',
            column: '*, brand_model!inner(*, brand(*))',
            orderBy: orderBy
        })
    }

    @logger
    postContactFormDetails(formData) {
        return this.service.postData({
            table: "contact",
            data: formData
        })
    }

    @logger
    postVehicleOrder(functionName, params) {
        return this.service.rpc({ functionName: functionName, params: params })
    }

    @logger
    signInWithGoogle(token) {
        return this.service.signInWithGoogle({ token: token }).then(response => {
            if (response.success && !response.error) {
                if (response.success.user) {
                    store.commit('updateUserData', response.success.user)
                }

                if (response.success.session != null) {
                    store.commit('updateUserSession', response.success.session)
                    store.commit('updateLoginState', true)
                }
            }

            return response

        })
    }

}


export default new DataRepository()