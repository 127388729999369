import ApiState from '@/model/ApiState'
import axios from 'axios'
import { createStore } from 'vuex'
import RegistrationError from './RegistrationError';
import DataRepository from '@/infrastructure/services/DataRepository';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export default createStore({
  state: {
    loggedIn: false,
    redirectionUrl: "/",
    session: null,
    user: null,
    apiState: new ApiState(),
    vehiclesList: [],
    vehicleFetchError: null,
    selectedVehicle: {},
    assetFinancingData: {},
    includeGps: false,
    assetFinanceInterestRate: [],
    tradeInVehicleData: null,
  },
  getters: {
    getAssetFinanceInterestRate: (state) => (month) => {
      const interestRate = state.assetFinanceInterestRate.find(interest => interest.month === month)
      return interestRate ? interestRate.rate : null
    },
    getUserData(state) {
      return state.user
    },
    getVehicleById: (state) => (vehicleId) => {
      const vehicle = state.vehiclesList.find(vehicle => vehicle.id === vehicleId)
      return vehicle ? vehicle : null
    },
  },

  mutations: {
    updateRedirection(state, path) {
      state.redirectionUrl = path
    },

    updateLoginState(state, loggedIn) {
      state.loggedIn = loggedIn
    },
    updateUserData(state, user) {
      state.user = user
    },
    updateUserSession(state, session) {
      state.session = session
    },
    updateToken(state, token) {
      state.token = token
    },
    updateAPIState(state, apiState) {
      state.apiState = apiState
    },
    resetAPIState(state) {
      state.apiState = new ApiState()
    },
    updateSelectedVehicle(state, selectedVehicle, includeGps) {
      state.selectedVehicle = selectedVehicle
      state.includeGps = includeGps
    },
    includeGps(state, isGpsSelected) {
      state.includeGps = isGpsSelected
    },
    updateAssetFinancingData(state, assetFinancingData) {
      state.assetFinancingData = assetFinancingData
    },
    updateVehicleList(state, updatedVehicleList) {
      state.vehiclesList = updatedVehicleList
    },
    setVehiclesList(state, fetchVehicleResponse) {
      if (fetchVehicleResponse.success && !fetchVehicleResponse.error) {
        state.vehiclesList = fetchVehicleResponse.success
      } else {
        this.commit('setVehicleFetchError', fetchVehicleResponse.error.message)
      }
    },
    setAssetFinanceInterestRate(state, interestRateJson) {
      state.assetFinanceInterestRate = interestRateJson
    },
    setVehicleFetchError(state, errorMessage) {
      state.vehicleFetchError = errorMessage
    },
    setTradeInVehicleDetails(state, tradeInVehicleData){
      state.tradeInVehicleData = tradeInVehicleData
    },
  },

  actions: {
    // Authentication APIs

    loginUser({ dispatch, commit }, userData) {
      commit('updateAPIState', new ApiState({ loading: true }))
      axios.post("https://backend.navi.co.ke/account/login/", userData).then(response => {
        commit('updateAPIState', new ApiState({ loading: false, success: true }))
        commit('updateToken', response.data.key)
        commit('updateLoginState', true)
        dispatch('retrieveUserDetails', userData)
      })
        .catch(error => {
          if (error.response.data) {
            commit('updateAPIState', new ApiState({ error: error.response.data.non_field_errors[0], loading: false }))
          } else {
            commit('updateAPIState', new ApiState({ error: error, loading: false }))
          }
        })
    },

    retrieveUserDetails({ commit }, userData) {
      commit('updateAPIState', new ApiState({ loading: true }))
      var basicAuth = 'Basic ' + btoa(userData.email + ':' + userData.password);

      const HEADERS = {
        headers: {
          'Authorization': basicAuth
        }
      }

      axios.get("https://backend.navi.co.ke/account/user/", HEADERS)
        .then(response => {
          commit('updateAPIState', new ApiState({ loading: false, success: true }))
          commit('updateUserData', {
            id: response.data.pk,
            email: response.data.email
          })
          commit('updateLoginState', true)
        }).catch(error => {
          commit('updateAPIState', new ApiState({ error: "Error while retrieving your profile", loading: false }))
        })
    },

    registerUser({ commit }, userCred) {
      commit('updateAPIState', new ApiState({ loading: true }))
      axios.post("https://backend.navi.co.ke/account/registration/", userCred)
        .then(response => {
          commit('updateAPIState', new ApiState({ loading: false, success: true }))
        }).catch(error => {
          if (error.response.data) {
            commit('updateAPIState', new ApiState({ error: new RegistrationError(error.response.data).getErrorMsg(), loading: false }))
          } else {
            commit('updateAPIState', new ApiState({ error: error.response, loading: false }))
          }
        })

    },


    // Vehicle APIs
    sellVehicle({ commit }, vehicleDetails) {
      commit('updateAPIState', new ApiState({ loading: true }))
      axios.post("https://backend.navi.co.ke/api/trade-in/", vehicleDetails)
        .then(response => {
          console.log(response.data);
          commit('updateAPIState', new ApiState({ loading: false, success: true }))
        })
        .catch(error => {
          console.log(error.response.data)
          commit('updateAPIState', new ApiState({ error: error.response.data, loading: false }))
        })
    },

    getUserDetails({ commit }) {
      commit('updateUserData', {})
    },
    // fetch vehicles
    async fetchVehicles({ commit }) {
      const result = await DataRepository.fetchVehicles()
      commit('setVehiclesList', result)
    },
    async fetchAssetFinanceInterestRate({ commit }) {
      const result = await DataRepository.fetchInterests()
      if (result.success && !result.error) {
        commit('setAssetFinanceInterestRate', result.success)
      }

    }
  },
  modules: {
  }
})
