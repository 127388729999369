<template>
  <header class="">
    <nav
      class="mx-auto flex max-w-7xl items-center justify-between p-6"
      aria-label="Global"
    >
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">Navi Credit Ltd</span>
          <RouterLink
            to="/"
            class="text-sm font-semibold font-[sarabun] leading-6 text-orange-900 uppercase"
          >
            <img class="h-6 w-auto" src="@/assets/navi-logo.png" />
          </RouterLink>
        </a>
      </div>

      <div class="flex lg:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <PopoverGroup class="hidden lg:flex lg:gap-x-12">
        <RouterLink
          to="/find-vehicles"
          class="text-sm font-semibold font-[sarabun] leading-6 text-orange-900 uppercase"
          >Find a Vehicle</RouterLink
        >
        <RouterLink
          to="/sell-vehicle"
          class="text-sm font-semibold font-[sarabun] leading-6 text-orange-900 uppercase"
          >Sell A Vehicle</RouterLink
        >
        <RouterLink
          to="/navi-fleet"
          class="text-sm font-semibold font-[sarabun] leading-6 text-orange-900 uppercase"
          >Navi Fleet</RouterLink
        >
        <RouterLink
          to="/telematics"
          class="text-sm font-semibold font-[sarabun] leading-6 text-orange-900 uppercase"
          >Vehicle Telematics</RouterLink
        >
        <Popover class="relative">
          <PopoverButton
            class="flex items-center gap-x-1 text-sm font-semibold font-[sarabun] text-orange-900 leading-6 uppercase outline-none"
          >
            More
            <ChevronDownIcon
              class="h-5 w-5 flex-none text-gray-400"
              aria-hidden="true"
            />
          </PopoverButton>

          <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1"
          >
            <PopoverPanel
              class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5"
            >
              <div class="p-4">
                <div
                  v-for="item in products"
                  :key="item.name"
                  class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                >
                  <div
                    class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-orange-50 group-hover:bg-orange-100"
                  >
                    <component
                      :is="item.icon"
                      class="h-6 w-6 text-gray-600 group-hover:text-orange-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="flex-auto">
                    <router-link :to="item.href">
                      {{ item.name }}
                      <span class="absolute inset-0" />
                    </router-link>
                    <p class="mt-1 text-gray-600">{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
      </PopoverGroup>
      <div
        class="hidden lg:flex lg:flex-1 lg:justify-end grid-cols-2 divide-x divide-gray-900/5"
      >
        <div v-for="item in callsToAction" :key="item.name">
          <router-link v-if="item.name !== 'LogIn'" :to="item.href">
            <p
              class="flex items-center justify-center gap-x-2.5 p-3 text-sm font-[sarabun] uppercase font-semibold leading-6 text-orange-900 hover:bg-gray-100"
            >
              <component
                :is="item.icon"
                class="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
              {{ item.name }}
            </p>
          </router-link>
          <router-link
            v-if="item.name === 'LogIn' && $store.state.user === null"
            :to="item.href"
          >
            <p
              class="flex items-center justify-center gap-x-2.5 p-3 text-sm font-[sarabun] uppercase font-semibold leading-6 text-orange-900 hover:bg-gray-100"
            >
              <component
                :is="item.icon"
                class="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
              {{ item.name }}
            </p>
          </router-link>

          <Menu
            v-else-if="item.name == 'LogIn' && $store.state.user !== null"
            as="div"
            class="relative inline-block text-left"
          >
            <MenuButton>
              <span
                class="flex items-center justify-center gap-x-2.5 p-3 text-sm font-[sarabun] uppercase font-semibold leading-6 text-orange-900 hover:bg-gray-100"
              >
                <component
                  :is="item.icon"
                  class="h-5 w-5 flex-none text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </MenuButton>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute right-0 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
              >
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :class="[
                        active ? 'bg-orange-100 text-black' : 'text-gray-900',
                        'group flex w-full items-center rounded-md px-2 py-2  font-[sarabun]',
                      ]"
                      to="/profile"
                    >
                      Profile
                    </router-link>
                  </MenuItem>
                </div>
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                    <div
                      :class="[
                        active ? 'bg-orange-100 text-black' : 'text-gray-900',
                        'group flex w-full items-center rounded-md px-2 py-2   font-[sarabun]',
                      ]"
                      @click="setIsOpen(true)"
                    >
                      Logout
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </nav>

    <!-- Logout Button -->
    <Dialog as="div" :open="logoutIsOpen" @close="setIsOpen">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/55" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
                >Log Out</DialogTitle
              >
              <div class="mt-2">
                <p class="text-sm text-gray-500 mt-3">
                  Are you sure you want to Log Out
                </p>
              </div>

              <div class="flex">
                <div class="mt-4 mr-3">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    @click="logOutUser"
                  >
                    Logout
                  </button>
                </div>
                <div class="mt-4">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    @click="setIsOpen(false)"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>

    <Dialog
      as="div"
      class="lg:hidden"
      @close="mobileMenuOpen = false"
      :open="mobileMenuOpen"
    >
      <div class="fixed inset-0 z-10" />
      <DialogPanel
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
      >
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Your Company</span>
            <img class="h-6 w-auto" src="@/assets/navi-logo.png" />
          </a>
          <button
            type="button"
            class="-m-2.5 rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = false"
          >
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <RouterLink
                @click="mobileMenuOpen = false"
                to="/find-vehicles"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50 text-orange-900 font-[sarabun] uppercase"
                >Find a Vehicle</RouterLink
              >
              <RouterLink
                @click="mobileMenuOpen = false"
                to="/sell-vehicle"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50 text-orange-900 font-[sarabun] uppercase"
                >Sell a Vehicle</RouterLink
              >
              <RouterLink
                @click="mobileMenuOpen = false"
                to="/navi-fleet"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50 text-orange-900 font-[sarabun] uppercase"
                >Navi Fleet</RouterLink
              >
              <RouterLink
                @click="mobileMenuOpen = false"
                to="/telematics"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50 text-orange-900 font-[sarabun] uppercase"
                >Vehicle Telematics</RouterLink
              >
              <Disclosure as="div" class="-mx-3" v-slot="{ open }">
                <DisclosureButton
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-orange-900 font-[sarabun] uppercase hover:bg-gray-50"
                >
                  More
                  <ChevronDownIcon
                    :class="[open ? 'rotate-180' : '', 'h-5 w-5 flex-none']"
                    aria-hidden="true"
                  />
                </DisclosureButton>
                <DisclosurePanel class="mt-2 space-y-2">
                  <DisclosureButton
                    @click="mobileMenuOpen = false"
                    v-for="item in [...products]"
                    :key="item.name"
                    as="a"
                    :href="item.href"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >{{ item.name }}</DisclosureButton
                  >
                </DisclosurePanel>
              </Disclosure>
            </div>
            <div v-if="$store.state.user === null" class="py-6">
              <router-link to="/login">
                <p
                  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </p>
              </router-link>
            </div>
            <div v-else class="py-6">
              <span
                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >{{ $store.state.user.email }}</span
              >
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { googleOneTap } from "vue3-google-login";
import { onMounted } from "vue";

const router = useRouter();

import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";

import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import {
  Bars3Icon,
  XMarkIcon,
  TruckIcon,
  UserIcon,
  GiftIcon,
} from "@heroicons/vue/24/outline";

import { BoltIcon, ChevronDownIcon, PhoneIcon } from "@heroicons/vue/20/solid";
import DataRepository from "@/infrastructure/services/DataRepository";

const products = [
  {
    name: "Logistics",
    description: "Get fast and secure delivery of your items",
    href: "",
    icon: TruckIcon,
  },
  {
    name: "EBikes",
    description: "Navi E-Bikes launch details",
    href: "/ebikes",
    icon: BoltIcon,
  },
  {
    name: "Truck Leasing",
    description:
      "Opt for flexible truck leasing solutions tailored to your needs.",
    href: "",
    icon: GiftIcon,
  },
];
const callsToAction = [
  { name: "+254799996660", href: "Tel: 0799996660", icon: PhoneIcon },
  { name: "LogIn", href: "/login", icon: UserIcon },
];

const logoutIsOpen = ref(false);

function setIsOpen(value) {
  logoutIsOpen.value = value;
}

function logOutUser() {
  DataRepository.signOutUser();
  logoutIsOpen.value = false;
  router.push("/");
}

const mobileMenuOpen = ref(false);

//google one tap implementation
onMounted(() => {
  googleOneTap()
    .then(async (response) => {
      await DataRepository.signInWithGoogle(response.credential)
    });
});
</script>

