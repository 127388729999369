<template>
  <div id="cta" class="relative rounded-lg overflow-hidden" :class="BgImageHeight">
    <div>
      <img
        :src="getImageFilePath(BgImage)"
        alt="bg-image"
        class="w-full object-cover"
        :class="BgImageHeight"
      />
    </div>
    <div class="absolute inset-0 bg-black opacity-50" />
    <div class="absolute inset-0 flex items-center justify-center">
      <div class="text-center">
        <p class="font-roboto font-bold md:text-5xl text-3xl text-white">
          {{ title }}
        </p>
        <p class="font-roboto font-thin text-base sm:text-2xl text-white my-6">
          {{ subTitle }}
        </p>
        <Button :filled="true" :label="ButtonText" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
export default {
  name: "CTASectionCard",
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    ButtonText: {
      type: String,
      required: true,
    },
    BgImage: {
      type: String,
      required: true,
    },
    BgImageHeight: {
      type: String,
      required: true,
      default: "h-96",
    },
  },
  methods: {
    getImageFilePath(filename) {
      return require("../assets/" + filename);
    },
  },
};
</script>

<style scoped>
#cta {
  border-radius: 1rem; /* Adjust the border radius for curved corners */
}
</style>
