import Home from './views/TelematicsHome.vue'

const vehicleTelematicsRoutes = {
    path: '/telematics',
    component: Home,
    name: 'VehicleTelematics'
}

export default router => {
    router.addRoute(vehicleTelematicsRoutes)
}