import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import 'aos/dist/aos.css'
import VueCarousel from 'vue3-carousel';
import vue3GoogleLogin from 'vue3-google-login'
// don't delete
import { registerModules } from './register-modules'

//import modules
import SellVehicleModule from '@/modules/sell-vehicle'
import NaviFleetModule from '@/modules/navi-fleet'
import VehicleTelematicsModule from '@/modules/vehicle-telematics'
import AuthenticationModule from '@/modules/authentication'
import FindVehicleModule from '@/modules/find-vehicle'
import UserProfileModule from '@/modules/user-profile'
import ShoppingCartModule from '@/modules/cart'
import AdminModule from "@/modules/admin"


// add this
import './index.css'

registerModules({
    sellVehicle: SellVehicleModule,
    naviFleet: NaviFleetModule,
    vehicleTelematics: VehicleTelematicsModule,
    auth: AuthenticationModule,
    findVehicle: FindVehicleModule,
    userProfile: UserProfileModule,
    ShoppingCart: ShoppingCartModule,
    admin: AdminModule
})


library.add(fas)

createApp(App)
    .use(store)
    .use(router)
    .use(VueCarousel)
    .use(vue3GoogleLogin, {
        clientId: process.env.VUE_APP_GOOGLE_SIGN_IN_CLIENT_ID
      })
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
