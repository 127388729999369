<template>
  <div>
    <div v-if="isLoading">isLoading</div>
    <div v-else>
      <div v-if="VehicleResponse" class="container max-w-7xl mx-auto px-4 py-6">
        <!-- Section 1: Image Carousel and Trade-In Component -->
        <div class="flex flex-col lg:flex-row gap-14">
          <!-- Image Carousel -->
          <div class="basis-1/2">
            <PreviewImageComponent
              class="w-full"
              :images="VehicleResponse.images"
            />
            <div class="flex w-full justify-between border-b pb-2">
              <div
                class="flex items-center space-x-1 font-semibold"
                @click="generateVehicleDetailsPdf"
              >
                <ion-icon name="download-outline"></ion-icon>
                <h1 class="font-[sarabun] cursor-pointer text-lg font-semibold">
                  View PDF
                </h1>
              </div>
              <div class="flex items-center space-x-1 font-semibold">
                <ion-icon name="share-social-outline"></ion-icon>
                <h1 class="font-[sarabun] cursor-pointer text-lg">Share</h1>
              </div>
            </div>
            <!-- Vehicle Details PDF content component -->
            <VehicleDetailsPdf
              id="vehicle-details-pdf"
              class="hidden"
              :VehicleModel="VehicleResponse"
            />
            <div class="flex">
              <p
                class="flex-1 text-4xl font-semibold font-[oxanium] text-orange-900 mt-8 mr-3 uppercase"
              >
                {{ VehicleResponse.brand_model.brand.name }}
              </p>

              <p
                class="content-right text-3xl font-semibold font-[oxanium] text-orange-900 mt-8 uppercase"
              >
                Ksh. {{ formatNumber(VehicleResponse.price) }}
              </p>
            </div>

            <p class="text-2xl font-[sarabun] text-gray-500">
              {{ VehicleResponse.brand_model.model }}
            </p>

            <div class="mt-16">
              <div class="py-6">
                <h2 class="text-2xl font-semibold mb-4">Specs</h2>
                <hr />
                <div
                  class="flex overflow-auto justify-between space-x-8 mt-4 w-full"
                >
                  <div class="grid justify-items-center">
                    <font-awesome-icon
                      :icon="['fas', 'calendar-days']"
                      class="w-6 h-6 text-gray-400"
                    />
                    <p class="font-sans mt-1.5 text-gray-400">
                      {{ VehicleResponse.registration_year.split("-")[0] }}
                    </p>
                  </div>
                  <div class="grid justify-items-center">
                    <font-awesome-icon
                      :icon="['fas', 'gauge']"
                      class="w-6 h-6 text-gray-400"
                    />
                    <p class="font-sans mt-1.5 text-gray-400">
                      {{ VehicleResponse.mileage }} km
                    </p>
                  </div>
                  <div class="grid justify-items-center">
                    <font-awesome-icon
                      :icon="['fas', 'gas-pump']"
                      class="w-6 h-6 text-gray-400"
                    />
                    <p class="font-sans mt-1.5 text-gray-400">
                      {{ VehicleResponse.drive }}
                    </p>
                  </div>
                  <div class="grid justify-items-center">
                    <font-awesome-icon
                      :icon="['fas', 'gears']"
                      class="w-6 h-6 text-gray-400"
                    />
                    <p class="font-sans mt-1.5 text-gray-400">
                      {{ VehicleResponse.transmission }}
                    </p>
                  </div>
                </div>
                <!-- Other specs -->
                <div>
                  <div class="border-t my-5"></div>
                  <Listbox>
                    <ListboxButton
                      class="w-full flex justify-between items-center"
                    >
                      <p class="font-[sarabun]">OTHER SPECS</p>
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </ListboxButton>
                    <ListboxOptions class="py-3">
                      <ListboxOption>
                        <div
                          class="flex items-center mb-4 w-full justify-between"
                        >
                          <label class="font-sans font-semibold text-gray-400"
                            >MODEL</label
                          >
                          <label class="font-sans font-semibold">{{
                            VehicleResponse.brand_model.model
                          }}</label>
                        </div>
                      </ListboxOption>
                      <ListboxOption>
                        <div
                          class="flex items-center mb-4 w-full justify-between"
                        >
                          <label class="font-sans font-semibold text-gray-400"
                            >CHASIS</label
                          >
                          <label class="font-sans font-semibold">{{
                            VehicleResponse.chasis_no
                          }}</label>
                        </div>
                      </ListboxOption>
                      <ListboxOption>
                        <div
                          class="flex items-center mb-4 w-full justify-between"
                        >
                          <label class="font-sans font-semibold text-gray-400"
                            >REGISTRATION</label
                          >
                          <label class="font-sans font-semibold">{{
                            VehicleResponse.number_plate
                          }}</label>
                        </div>
                      </ListboxOption>
                      <ListboxOption>
                        <div
                          class="flex items-center mb-4 w-full justify-between"
                        >
                          <label class="font-sans font-semibold text-gray-400"
                            >COLOR</label
                          >
                          <label class="font-sans font-semibold">{{
                            VehicleResponse.color ? VehicleResponse.color : "-"
                          }}</label>
                        </div>
                      </ListboxOption>
                      <ListboxOption>
                        <div
                          class="flex items-center mb-4 w-full justify-between"
                        >
                          <label class="font-sans font-semibold text-gray-400"
                            >ENGINE SIZE</label
                          >
                          <label class="font-sans font-semibold">{{
                            VehicleResponse.engine_size
                              ? VehicleResponse.engine_size + " CC"
                              : "-"
                          }}</label>
                        </div>
                      </ListboxOption>
                      <ListboxOption>
                        <div
                          class="flex items-center mb-4 w-full justify-between"
                        >
                          <label class="font-sans font-semibold text-gray-400"
                            >SEATS</label
                          >
                          <label class="font-sans font-semibold">{{
                            VehicleResponse.seats_no
                              ? VehicleResponse.seats_no
                              : "-"
                          }}</label>
                        </div>
                      </ListboxOption>
                      <ListboxOption>
                        <div
                          class="flex items-center mb-4 w-full justify-between"
                        >
                          <label class="font-sans font-semibold text-gray-400"
                            >BODY TYPE</label
                          >
                          <label class="font-sans font-semibold">{{
                            VehicleResponse.body_type
                              ? VehicleResponse.body_type
                              : "-"
                          }}</label>
                        </div>
                      </ListboxOption>
                      <ListboxOption>
                        <div
                          class="flex items-center mb-4 w-full justify-between"
                        >
                          <label class="font-sans font-semibold text-gray-400"
                            >DIMENSIONS</label
                          >
                          <label class="font-sans font-semibold">{{
                            VehicleResponse.dimensions
                              ? VehicleResponse.dimensions
                              : "-"
                          }}</label>
                        </div>
                      </ListboxOption>
                      <ListboxOption>
                        <div
                          class="flex items-center mb-4 w-full justify-between"
                        >
                          <label class="font-sans font-semibold text-gray-400"
                            >MAXIMUM SPEED</label
                          >
                          <label class="font-sans font-semibold">{{
                            VehicleResponse.max_speed
                              ? VehicleResponse.max_speed
                              : "-"
                          }}</label>
                        </div>
                      </ListboxOption>
                    </ListboxOptions>
                  </Listbox>
                </div>
                <div class="border-t mt-5"></div>
              </div>

              <div>
                <Features :featuresList="VehicleResponse.features" />
              </div>
            </div>
          </div>

          <!-- Trade-In Component -->
          <div class="basis-1/2">
            <div class="mb-8">
              <!-- Payment methods -->
              <div class="px-4">
                <h2
                  class="text-2xl font-[oxanium] text-orange-950 font-bold mb-2"
                >
                  Payment methods
                </h2>
                <hr class="mb-4" />
                <div>
                  <TabGroup>
                    <TabList class="flex space-x-5 mb-4">
                      <Tab v-slot="{ selected }" class="basis-1/2 outline-none">
                        <button
                          :class="[
                            'w-full px-4 py-4 font-medium text-left font-[oxanium]',
                            'ring-white/60 focus:outline-none',
                            selected
                              ? 'bg-orange-400 text-white'
                              : 'border text-black',
                          ]"
                        >
                          <p>Cash</p>
                          <p class="mt-3 font-[sarabun] text-sm">
                            Make a full payment or partial payment with a
                            vehicle to trade-in
                          </p>
                        </button>
                      </Tab>
                      <Tab
                        v-slot="{ selected }"
                        class="basis-1/2 outline-none"
                        @click="calculateMonthlyPayment()"
                      >
                        <button
                          :class="[
                            'w-full px-4 py-4 font-medium text-left font-[oxanium]',
                            'ring-white/60 focus:outline-none',
                            selected
                              ? 'bg-orange-400 text-white'
                              : 'border text-black',
                          ]"
                        >
                          <p>Asset Finance</p>
                          <p class="mt-3 font-[sarabun] text-sm">
                            Make a deposit and repay the balance in regular
                            installments.
                          </p>
                        </button>
                      </Tab>
                    </TabList>
                    <TabPanels class="border border-gray-200">
                      <!-- Cash panel -->
                      <TabPanel
                        :class="[
                          'rounded-xl bg-white p-3',
                          'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        ]"
                      >
                        <div class="font-[sarabun] text-gray-600">
                          <span class="text-gray-400 font-[oxanium]"
                            >Car Price:
                          </span>
                          <span
                            class="font-[oxanium] text-orange-900 font-semibold text-xl ml-3"
                            >Ksh {{ formatNumber(VehicleResponse.price) }}
                          </span>
                        </div>

                        <div class="mt-3 font-[oxanium] text-gray-600">
                          <span class="text-gray-400">Amount Due: </span>
                          <span
                            class="font-[oxanium] text-orange-900 font-semibold text-xl ml-3"
                            >Ksh {{ formatNumber(VehicleResponse.price) }}</span
                          >
                        </div>

                        <div
                          v-if="showCashConfirmationPanel"
                          class="inline-block mt-8"
                        >
                          <Popover class="relative" v-slot="{ close }">
                            <PopoverButton
                              @click="checkUserIsLoggedIn()"
                              class="w-full bg-orange-400 font-[sarabun] text-lg text-white px-4 py-2"
                              >Checkout</PopoverButton
                            >

                            <PopoverPanel
                              static
                              class="fixed z-10 bg-black/40 top-0 left-0 right-0 bottom-0 flex justify-end"
                            >
                              <div
                                class="bg-white w-3/4 md:w-3/5 lg:w-2/5 py-4 lg:py-10 px-4 lg:px-8 overflow-auto"
                              >
                                <!-- Image -->
                                <div
                                  class="h-[20vh] w-full lg:h-[50vh] rounded"
                                >
                                  <img
                                    :src="VehicleResponse.images[0]"
                                    alt="Preview Image"
                                    class="w-full h-full object-cover rounded"
                                  />
                                </div>
                                <!-- Car details -->
                                <div class="mt-3 lg:mt-8">
                                  <p
                                    class="font-[oxanium] font-medium text-orange-900 text-lg"
                                  >
                                    Vehicle Details
                                  </p>
                                  <div class="border-t my-3"></div>
                                  <div
                                    class="flex w-full justify-between content-start font-[sarabun] mb-2"
                                  >
                                    <label class="text-orange-900"
                                      >Vehicle Brand</label
                                    >
                                    <p class="font-semibold font-[oxanium]">
                                      {{
                                        VehicleResponse.brand_model.brand.name
                                      }}
                                    </p>
                                  </div>
                                  <div
                                    class="flex w-full justify-between font-[sarabun] mb-2"
                                  >
                                    <p class="text-orange-900">Cash Price</p>

                                    <p class="font-semibold font-[oxanium]">
                                      Ksh
                                      {{ formatNumber(VehicleResponse.price) }}
                                    </p>
                                  </div>
                                  <div
                                    class="flex w-full justify-between font-[sarabun] mb-2"
                                  >
                                    <label class="text-orange-900"
                                      >Payment Method</label
                                    >
                                    <p class="font-semibold font-[oxanium]">
                                      CASH
                                    </p>
                                  </div>
                                  <div class="border-t mt-3"></div>
                                </div>
                                <!-- Trade In -->
                                <div class="mt-5">
                                  <p
                                    class="font-[oxanium] font-medium text-gray-600 text-lg"
                                  >
                                    Trade In
                                  </p>
                                  <div
                                    v-if="$store.state.tradeInVehicleData"
                                    class="flex justify-between font-[sarabun] mt-2 text-orange-900 items-center"
                                  >
                                    <div>
                                      <p>
                                        {{
                                          $store.state.tradeInVehicleData.brand
                                        }}
                                      </p>
                                      <p>
                                        {{
                                          $store.state.tradeInVehicleData.model
                                        }}
                                      </p>
                                    </div>
                                    <p class="text-green-600">TBC</p>
                                  </div>
                                  <p
                                    v-else
                                    class="flex justify-between font-[sarabun] mt-2 text-orange-900"
                                  >
                                    N/A
                                  </p>
                                  <div class="border-t mt-3"></div>
                                </div>
                                <!-- Add Ons -->
                                <div class="mt-5">
                                  <p
                                    class="font-[oxanium] font-medium text-gray-600 text-lg"
                                  >
                                    Add On's
                                  </p>
                                  <p
                                    v-if="$store.state.includeGps"
                                    class="flex justify-between font-[sarabun] mt-2 text-orange-900"
                                  >
                                    GPS Tracker
                                  </p>
                                  <p
                                    v-else
                                    class="flex justify-between font-[sarabun] mt-2 text-orange-900"
                                  >
                                    N/A
                                  </p>
                                  <div class="border-t mt-3"></div>
                                </div>
                                <div
                                  class="mt-5 flex justify-center flex-wrap-reverse"
                                >
                                  <Button
                                    v-if="!isCashDetailsLoading"
                                    @click="closeCashConfirmationPanel(close)"
                                    label="Cancel"
                                    :filled="false"
                                    class="mt-3 md:mt-0 w-full md:w-fit"
                                  />
                                  <Button
                                    v-if="!isCashDetailsLoading"
                                    @click="uploadCashDetails()"
                                    label="Confirm Checkout"
                                    :filled="true"
                                    class="w-full md:w-fit"
                                  />
                                  <button
                                    v-else-if="isCashDetailsLoading"
                                    disabled
                                    type="button"
                                    class="bg-gray-400 font-medium font-[sarabun] px-5 py-2.5 text-center me-4 w-full md:w-fit"
                                  >
                                    <div class="flex content-center">
                                      <svg
                                        aria-hidden="true"
                                        role="status"
                                        class="inline w-4 h-4 me-3 text-gray-500 animate-spin"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                          fill="#fb923c"
                                        />
                                      </svg>
                                      <p class="text-white -mt-1">
                                        Uploading...
                                      </p>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </PopoverPanel>
                          </Popover>
                        </div>
                      </TabPanel>
                      <!-- Asset finance panel -->
                      <TabPanel
                        :class="[
                          'rounded-xl bg-white p-3',
                          'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        ]"
                      >
                        <p
                          class="text-3xl font-bold my-6 font-[oxanium] text-orange-800"
                        >
                          KSH {{ formatNumber(monthlyPayment) }}/month
                        </p>
                        <div class="flex items-center justify-between">
                          <p class="text-lg font-semibold text-gray-600">
                            Deposit
                          </p>
                        </div>
                        <div class="flex items-center justify-between mb-8">
                          <input
                            class="w-8/12 accent-orange-400 focus:bg-orange-50 rounded-md px-4 py-2 mr-4"
                            type="range"
                            :min="calculateMinDeposit()"
                            :max="calculateMaxDeposit()"
                            @input="calculateMonthlyPayment()"
                            v-model="deposit"
                            step="1000"
                          />
                          <span
                            v-if="deposit > 0"
                            class="text-xl font-semibold font-[sarabun] text-orange-800"
                            >Ksh {{ formatNumber(Number(deposit)) }}</span
                          >
                          <span
                            v-else
                            class="text-xl font-semibold font-[sarabun] text-orange-800"
                            >Ksh {{ formatNumber(calculateMinDeposit()) }}</span
                          >
                        </div>
                        <div class="flex items-center justify-between">
                          <p class="text-lg font-semibold text-gray-600">
                            Months
                          </p>
                        </div>
                        <div class="flex items-center justify-between mb-4">
                          <input
                            class="w-8/12 accent-orange-400 rounded-md px-4 py-2 mr-4"
                            type="range"
                            min="3"
                            max="12"
                            value="6"
                            step="1"
                            @input="calculateMonthlyPayment()"
                            v-model="months"
                          />
                          <span
                            class="text-xl font-semibold font-[sarabun] text-orange-800"
                            >{{ months }} Months</span
                          >
                        </div>
                        <!-- <div class="inline-block mt-8">
                          <Button
                            @click="financeCheckout"
                            :filled="true"
                            label="Checkout"
                            class="w-full bg-[#F99416] font-[sarabun] text-xl text-bold"
                          />
                        </div> -->
                      </TabPanel>
                    </TabPanels>
                  </TabGroup>
                </div>
              </div>
            </div>
            <TradeIn :vehicleId="VehicleId" />
            <button
              @click="checkout()"
              class="rounded-full bg-orange-400 w-full mt-4 py-2 font-[sarabun] text-white"
            >
              GET MY QUOTE
            </button>
          </div>
        </div>
        <!-- Condition Assessment Component -->
        <label
          v-if="VehicleResponse.condition === 'US'"
          class="font-[oxanium] flex justify-center text-3xl font-semibold mb-8 mt-16"
          >Condition Assessment</label
        >
        <div
          v-if="VehicleResponse.condition === 'US'"
          class="w-full container mx-auto max-w-7xl border py-10 px-10"
        >
          <TabGroup>
            <div class="">
              <TabList
                class="w-full flex overflow-auto justify-center space-x-10"
              >
                <Tab
                  v-for="(section, index) in uniqueSections"
                  :key="index"
                  v-slot="{ selected }"
                  class="outline-none"
                >
                  <div>
                    <label
                      class="font-[sarabun]"
                      :class="{
                        ' text-orange-400': selected,
                        'text-gray-400': !selected,
                      }"
                    >
                      {{ section }}
                    </label>
                    <div
                      :class="{
                        'border-t-2 mt-4 border-gray-400': !selected,
                        'border-t-2 mt-4 border-orange-300': selected,
                      }"
                    ></div>
                  </div>
                </Tab>
              </TabList>
            </div>
            <TabPanels>
              <TabPanel
                v-for="(section, index) in uniqueSections"
                :key="index"
                class="mt-14"
              >
                <div
                  v-for="condition in sectionConditions(section)"
                  :key="condition.id"
                  class="rounded-lg max-w-7xl mx-auto"
                  id="engine"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >{{ condition.condition }}</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                </div>
              </TabPanel>
              <TabPanel class="mt-14 flex-none">
                <div
                  class="rounded-lg max-w-7xl mx-auto"
                  id="engine"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Start the engine</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Start the engine</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Engine Misfiring</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Oil Leakage</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                </div>
              </TabPanel>
              <TabPanel class="mt-14">
                <div
                  class="rounded-lg max-w-7xl mx-auto"
                  id="engine"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Start the engine</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Start the engine</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Engine Misfiring</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Oil Leakage</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                </div>
              </TabPanel>
              <TabPanel class="mt-14">
                <div
                  class="rounded-lg max-w-7xl mx-auto"
                  id="engine"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Start the engine</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Start the engine</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Engine Misfiring</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                  <div class="flex justify-between mb-4">
                    <label
                      class="basis-3/4 font-sans font-semibold text-gray-400"
                      >Oil Leakage</label
                    >
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="1/4 text-green-700"
                    />
                  </div>
                  <div class="border-t mb-4"></div>
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
      <div v-else>
        <div
          class="container max-w-7xl mx-auto h-screen grid place-content-center"
        >
          Vehicle not found
        </div>
      </div>
    </div>
    <!-- success modal -->
    <TransitionRoot appear :show="isSuccessModalOpen" as="template">
      <Dialog as="div" @close="closeSuccessModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle as="h3">
                  <div class="grid justify-items-center mb-5">
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="h-14 w-14 text-green-600 mb-3"
                    />
                    <p class="text-lg font-medium text-gray-900 font-[oxanium]">
                      ORDER SUCCESSFUL
                    </p>
                  </div>
                </DialogTitle>
                <div class="mt-2">
                  <p
                    class="font-[sarabun] grid place-content-center text-center"
                  >
                    Someone from our team will reach out to in less than 24
                    hours.
                  </p>
                  <p class="font-[sarabun] grid place-content-center">
                    <br />Thank you for choosing Navi
                  </p>
                </div>

                <div class="mt-6 flex justify-center">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-orange-400 px-4 py-2 text-sm font-medium text-white"
                    @click="closeSuccessModal"
                  >
                    FINISH
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
  
  <script setup>
import {
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Popover,
  PopoverButton,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
</script>
  
  <script>
import TradeIn from "@/components/TradeIn.vue";
import Specs from "@/components/Specs.vue";
import Features from "@/components/Features.vue";
import ConditionAssessment from "@/components/ConditionAssessment.vue";
import PreviewImageComponent from "@/components/PreviewImage.vue";
import Button from "@/components/Button.vue";
import DataRepository from "@/infrastructure/services/DataRepository";
import VehicleDetailsPdf from "../components/VehicleDetailsPdf.vue";
//PDF library
import html2pdf from "html2pdf.js";

export default {
  name: "VehicleDetailPage",
  components: {
    TradeIn,
    Specs,
    Features,
    ConditionAssessment,
    PreviewImageComponent,
    Button,
    VehicleDetailsPdf,
  },
  data() {
    return {
      VehicleId: "",
      VehicleResponse: null,
      VehicleImages: [],
      vehicleSpecsDataArray: [],
      vehicleFeaturesArrayChips: [],
      monthlyPayment: 0,
      months: 6,
      deposit: 0,
      carPrice: 0,
      isCashDetailsLoading: false,
      isLoading: false,
      isCash: true,
      showCashConfirmationPanel: false,
      isSuccessModalOpen: false,
    };
  },
  async created() {
    this.VehicleId = this.$route.params.id;
    if (this.$store.state.vehiclesList.length > 0) {
      this.fetchVehicle(this.VehicleId);
    } else {
      await this.$store.dispatch("fetchVehicles").then(async () => {
        await this.fetchVehicle(this.VehicleId);
        this.calculateMinDeposit();
      });
    }
    this.fetchAssetFinanceInterestRates();
  },
  methods: {
    financeCheckout() {
      if (this.$store.state.loggedIn) {
        this.$router.push("/quote");
      } else {
        this.$store.commit(
          "updateRedirection",
          `/find-vehicles/${this.$route.params.id}`
        );
        this.$router.push("/login");
      }
    },

    async fetchVehicle(id) {
      this.isLoading = true;
      this.VehicleResponse = await this.$store.getters.getVehicleById(id);
      this.$store.commit("updateSelectedVehicle", this.VehicleResponse);
      this.isLoading = false;
    },
    async fetchAssetFinanceInterestRates() {
      await this.$store.dispatch("fetchAssetFinanceInterestRate");
    },
    loadVehicleImages(images) {
      this.VehicleImages = images.map((image) => image.image);
    },
    loadVehicleFeatures(data) {
      this.vehicleSpecsDataArray = [
        ["Brand", data.vehicle_make || "-"],
        ["Model", data.make_model || "-"],
        ["Chasis No", data.chassis_no || "-"],
        ["Engine Size", data.engine_size || "-"],
        ["Drive", data.drive || "-"],
        ["Transmission", data.transmission || "-"],
        ["Registration Year", data.registration_year || "-"],
        ["Registration Number", data.registration_no || "-"],
        ["Mileage", data.mileage || "-"],
        ["Fuel", data.fuel || "-"],
        ["Weight", data.weight || "-"],
        ["Max Capacity", data.max_capacity || "-"],
        ["Exterior Color", data.ext_color || "-"],
        ["Interior Color", data.int_color || "-"],
        ["Seats", data.seats || "-"],
        ["Dimension", data.dimensions || "-"],
      ];
      this.vehicleFeaturesArrayChips = data.features.map(
        (feature) => feature.name
      );
    },
    formatNumber(number) {
      if (number) {
        return number.toLocaleString();
      } else {
        return 0;
      }
    },
    calculateMinDeposit() {
      if (this.VehicleResponse) {
        this.minDeposit = 0.3 * this.VehicleResponse.price;
        return this.minDeposit;
      }
    },
    calculateMaxDeposit() {
      this.maxDeposit = 0.7 * this.VehicleResponse.price;
      return this.maxDeposit;
    },
    calculateMonthlyPayment() {
      if (this.VehicleResponse) {
        //Calculate interest (Simple interest)
        const deposit =
          this.deposit === 0 ? this.calculateMinDeposit() : this.deposit;
        const principalAmount =
          Number(this.VehicleResponse.price) - Number(deposit);

        const interestRate = this.$store.getters.getAssetFinanceInterestRate(
          Number(this.months)
        );
        const yearsTermLength = this.months / 12;
        const totalInterest =
          principalAmount * (interestRate / 100) * yearsTermLength;

        // get monthly payments
        const totalAmountPayable = principalAmount + totalInterest;
        this.monthlyPayment = Math.ceil(
          totalAmountPayable / Number(this.months)
        );

        //Save data to store
        const assetFinancingData = {
          deposit:
            this.deposit === 0 ? this.calculateMinDeposit() : this.deposit,
          monthsTermLength: this.months,
          monthlyPayment: this.monthlyPayment,
          financeAmount: principalAmount,
          totalInterest: totalInterest,
          totalRepayableAmount: totalAmountPayable,
          interestRate: interestRate,
        };
        this.$store.commit("updateAssetFinancingData", assetFinancingData);
        this.isCash = false;
      }
    },
    closeCashConfirmationPanel(close) {
      close();
      this.showCashConfirmationPanel = false;
    },
    async uploadCashDetails() {
      const cashOrderDetailsArray = {
        total_order_items: 1,
        total_price: this.VehicleResponse.price,
        user_id: this.$store.state.user.id,
        order_items: [
          {
            quantity: 1,
            cash: true,
            total: this.VehicleResponse.price,
            orderStatus: "Processing",
            vehicleId: this.VehicleResponse.id,
            tradeIn: this.$store.state.tradeInVehicleData
              ? this.$store.state.tradeInVehicleData.id
              : null,
          },
        ],
      };
      this.isCashDetailsLoading = true;
      const uploadCashResponse = await DataRepository.postVehicleOrder(
        "create_vehicle_order_procedure",
        cashOrderDetailsArray
      );
      if (uploadCashResponse.success && !uploadCashResponse.error) {
        this.cashDetailsUploadResponse = uploadCashResponse.success;
        this.isSuccessModalOpen = true
      } else {
        this.cashDetailsUploadError = uploadCashResponse.error
          ? uploadCashResponse.error.message
          : "An error occurred while uploading. Please try again";
      }
      this.isCashDetailsLoading = false;
    },
    sectionConditions(section) {
      return this.VehicleResponse.assessment.filter(
        (item) => item.section === section
      );
    },
    checkUserIsLoggedIn() {
      if (!this.$store.state.loggedIn) {
        this.$store.commit(
          "updateRedirection",
          `/find-vehicles/${this.VehicleResponse.id}`
        );
        this.$router.push("/login");
      }
    },
    checkout() {
      this.checkUserIsLoggedIn();
      if (this.isCash) {
        this.showCashConfirmationPanel = true;
      } else {
        this.financeCheckout();
      }
    },
    closeSuccessModal() {
      this.isSuccessModalOpen = false;
      this.$router.push("/");
    },
    generateVehicleDetailsPdf() {
      html2pdf(document.getElementById("vehicle-details-pdf").innerHTML, {
        margin: 5,
        filename: this.VehicleResponse.brand_model.brand.name + ".pdf",
        image: { type: "jpeg", quality: 0.2 },
        html2canvas: { scale: 2,  allowTaint: false, useCORS: true }
      });
    },
  },
  computed: {
    uniqueSections() {
      // Extracting unique sections from the assessment array
      return [
        ...new Set(this.VehicleResponse.assessment.map((item) => item.section)),
      ];
    },
  },
};
</script>
  