<template>
  <div class="container mx-auto">
    <img class="h-12 w-auto mb-6" src="@/assets/navi-logo.png" />
    <div class="border">
      <div class="flex justify-between items-center bg-orange-50 p-4">
        <div>
          <p class="font-[sarabun] uppercase text-2xl font-semibold">
            Sino Howo
          </p>
          <p class="font-[oxanium] text-lg mt-2">Tipper truck</p>
        </div>
        <p class="font-[oxanium] text-2xl font-bold">
          Ksh {{ VehicleModel.price.toLocaleString() }}
        </p>
      </div>
      <div class="flex border-b">
        <!-- Image 1 -->
        <img
          v-if="VehicleModel.images.length >= 1"
          :src="VehicleModel.images[0]"
          class="object-cover w-2/3 h-[40vh]"
        />
        <!-- No image placeholder -->
        <div
          v-else
          class="flex items-center justify-center w-2/3 h-[40vh] bg-slate-400"
        >
          <svg
            class="w-10 h-10 text-slate-300"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 20"
          >
            <path
              d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"
            />
            <path
              d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"
            />
          </svg>
        </div>
        <!-- Image 2 -->
        <div class="w-1/3 border-l divide-y">
          <img
            v-if="VehicleModel.images.length >= 2"
            :src="VehicleModel.images[1]"
            class="object-cover w-full max-h-[20vh]"
          />
          <!-- no image place holder -->
          <div
            v-else
            class="flex items-center justify-center w-full h-[20vh] bg-slate-400"
          >
            <svg
              class="w-10 h-10 text-slate-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 20"
            >
              <path
                d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"
              />
              <path
                d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"
              />
            </svg>
          </div>
          <!-- Image 3 -->
          <img
            v-if="VehicleModel.images.length >= 3"
            :src="VehicleModel.images[2]"
            class="object-cover w-full max-h-[20vh]"
          />
          <!-- No image placeholder -->
          <div
            v-else
            class="flex items-center justify-center w-full h-[20vh] bg-slate-400"
          >
            <svg
              class="w-10 h-10 text-slate-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 20"
            >
              <path
                d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"
              />
              <path
                d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"
              />
            </svg>
          </div>
        </div>
      </div>
      <!-- Vehicle specs -->
      <div class="flex justify-around space-x-8 py-4 w-full border-b">
        <div class="grid justify-items-center">
          <font-awesome-icon
            :icon="['fas', 'calendar-days']"
            class="w-6 h-6 text-gray-500"
          />
          <p class="font-[sarabun] mt-1.5 text-black">
            {{ VehicleModel.registration_year.split("-")[0] }}
          </p>
        </div>
        <div class="grid justify-items-center">
          <font-awesome-icon
            :icon="['fas', 'gauge']"
            class="w-6 h-6 text-gray-500"
          />
          <p class="font-[sarabun] mt-1.5 text-black">
            {{ VehicleModel.mileage.toLocaleString() }} km
          </p>
        </div>
        <div class="grid justify-items-center">
          <font-awesome-icon
            :icon="['fas', 'gas-pump']"
            class="w-6 h-6 text-gray-500"
          />
          <p class="font-[sarabun] mt-1.5 text-black">
            {{ VehicleModel.drive }}
          </p>
        </div>
        <div class="grid justify-items-center">
          <font-awesome-icon
            :icon="['fas', 'gears']"
            class="w-6 h-6 text-gray-500"
          />
          <p class="font-[sarabun] mt-1.5 text-black">
            {{ VehicleModel.transmission }}
          </p>
        </div>
      </div>
      <!-- Third section -->
      <div class="grid grid-cols-2 border-b py-6 px-4">
        <div class="flex items-center space-x-2">
          <!-- <font-awesome-icon
            :icon="['fas', 'check']"
            class="w-8 h-12 p-2 bg-black text-white"
          /> -->
          <p class="font-[oxanium] text-3xl font-semibold">Navi Verified</p>
        </div>

        <div>
          <ul>
            <li>- No restrictions on Kms</li>
            <li>- Stringent Quality Checks</li>
            <li>- Extended Warranty available on selected models</li>
          </ul>
        </div>
      </div>
      <!-- Features section -->
      <div class="space-y-3 p-4 border-b">
        <p class="font-[oxanium] font-semibold">Features</p>
        <ul>
          <li>
            - Send us a message to find out more about this vehicles features.
          </li>
        </ul>
      </div>
      <!-- Bottom section -->
      <div class="grid grid-cols-2 divide-x justify-between p-4">
        <div class="leading-loose">
          <p class="font-[oxanium] underline font-semibold">
            Navi Info Limited
          </p>
          <p class="text-sm">Sameer Business Park, Block E3</p>
          <p class="font-semibold mb-2">(+254) 712 345-678</p>
          <qrcode-vue
            :value="qrCodeLink"
            :size="qrCodeSize"
            level="L"
            render-as="svg"
          />
        </div>
        <div class="pl-4 font-[sarabun] leading-loose">
          <p class="font-[oxanium] font-semibold mb-2">
            Payment Methods Available
          </p>
          <ul>
            <li>- Cash</li>
            <li>- Asset Finance</li>
            <li>- Trade In</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
export default {
  props: {
    VehicleModel: {
      type: Object,
      required: true,
    },
  },
  components: {
    QrcodeVue,
  },
  data() {
    return {
      qrCodeLink: `https://navi.co.ke/find-vehicles/${this.VehicleModel.id}`,
      qrCodeSize: 50,
    };
  },
};
</script>
