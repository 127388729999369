<template>
    <div>
        This is the users page
    </div>
</template>

<script>
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';

import DataRepository from "@/infrastructure/services/DataRepository";
import ApiState from "@/model/ApiState";
 
DataTable.use(DataTablesCore);

export default {
    data() {
        apiState: new ApiState()
    },
    methods: {
        async fetchUsers() {
            this.apiState = await DataRepository.fetchAllUsers()
        }
    },
    mounted() {
        this.fetchUsers()
    },
}

</script>