import Module from './Module.vue'
import Cart from "./views/Cart.vue";

const shoppingCartRoutes = {
    path: '/cart',
    component: Module,
    name: 'ShoppingCart',
    children: [
        {
            path: "",
            component: Cart,
        }
    ]
}

export default router => {
    router.addRoute(shoppingCartRoutes)
}