<template>
  <div class="mb-16">
    <div
      class="bg-[url(@/assets/img/truck3.jpeg)] bg-cover py-24 bg-gray-600 bg-blend-multiply"
    >
      <div class="mx-auto flex items-center max-w-7xl">
        <div
          class="flex flex-row flex-wrap justify-center w-10/12 lg:w-full pt-6 mx-auto lg:px-6"
        >
          <!-- Left Section -->
          <div class="lg:basis-5/12 w-full mb-10">
            <div
              class="border border-orange-50 backdrop-blur-md bg-black/10 py-8 basis-full h-full flex flex-col justify-center"
            >
              <div
                class="md:w-10/12 text-white font-[oxanium] text-3xl font-bold self-center mb-6"
              >
                <p>Find Your Vehicle</p>
                <p
                  v-if="filterError"
                  class="text-red-600 flex justify-center text-base mt-2"
                >
                  {{ filterError }}
                </p>
              </div>
              <!-- Brand Input -->
              <div class="w-10/12 self-center mb-6">
                <p class="text-white font-[sarabun] mb-1">Brand</p>
                <Listbox v-model="selectedBrand">
                  <div class="relative">
                    <ListboxButton
                      class="relative w-full cursor-default bg-white py-3 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300"
                    >
                      <span v-if="selectedBrand" class="block truncate">{{
                        selectedBrand.name
                      }}</span>
                      <span v-else class="block truncate text-gray-400"
                        >Select Brand</span
                      >
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition duration-100 ease-in"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute mt-1 max-h-60 w-full overflow-auto bg-white py-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none z-10"
                      >
                        <div
                          v-if="isBrandLoading"
                          class="flex justify-center py-3"
                        >
                          <svg
                            aria-hidden="true"
                            class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                        <div
                          v-else-if="brandErrorMessage"
                          class="flex justify-center py-3"
                        >
                          <p class="font-[sarabun]">{{ brandErrorMessage }}</p>
                        </div>
                        <ListboxOption
                          v-else
                          v-slot="{ active, selected }"
                          v-for="(brand, index) in brandsList"
                          :key="index"
                          :value="brand"
                          as="template"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-orange-100 text-amber-900'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-10 pr-4',
                            ]"
                          >
                            <span
                              :class="[
                                selected ? 'font-medium' : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ brand.name }}</span
                            >
                            <span
                              v-if="selected"
                              class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>

              <!-- Vehicle Type -->
              <div class="w-10/12 self-center mb-8">
                <p class="text-white font-[sarabun] mb-1">Truck Type</p>
                <Listbox v-model="selectedVehicleType">
                  <div class="relative">
                    <ListboxButton
                      class="relative w-full cursor-default bg-white py-3 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300"
                    >
                      <span v-if="selectedVehicleType" class="block truncate">{{
                        selectedVehicleType.type
                      }}</span>
                      <span v-else class="block truncate text-gray-400"
                        >Select Truck Body Type</span
                      >
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition duration-100 ease-in"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute mt-1 max-h-60 w-full overflow-auto bg-white py-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none"
                      >
                        <div
                          v-if="isVehicleBodyTypeLoading"
                          class="flex justify-center py-3"
                        >
                          <svg
                            aria-hidden="true"
                            class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                        <div
                          v-else-if="vehicleBodyTypeFetchErrorMessage"
                          class="flex justify-center py-3"
                        >
                          <p class="font-[sarabun]">{{ brandErrorMessage }}</p>
                        </div>
                        <ListboxOption
                          v-else
                          v-slot="{ active, selected }"
                          v-for="(vehicleType, index) in vehicleTypeList"
                          :key="index"
                          :value="vehicleType"
                          as="template"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-orange-100 text-amber-900'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-10 pr-4',
                            ]"
                          >
                            <span
                              :class="[
                                selected ? 'font-medium' : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ vehicleType.type }}</span
                            >
                            <span
                              v-if="selected"
                              class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>

              <div class="w-10/12 self-center mb-4">
                <Button
                  @click="filterVehicles()"
                  :filled="true"
                  label="Search"
                  class="w-full bg-[#F99416] font-[sarabun] text-bold"
                />
              </div>
            </div>
          </div>

          <!-- Spacing -->
          <div class="hidden lg:block lg:basis-1/12"></div>

          <!-- Right Section -->
          <div class="lg:basis-6/12 justify-self-center">
            <div class="mb-6 lg:flex hidden">
              <div
                class="w-3 h-3 bg-orange-400 rounded-full flex self-center mr-2"
              ></div>
              <div class="text-white font-[sarabun] text-md">
                Industry Leading Trucks
              </div>
            </div>

            <p
              class="font-[oxanium] text-white font-bold text-2xl md:text-5xl lg:text-6xl mb-4 text-center lg:text-left"
            >
              Buy, Sell, Trade In
            </p>

            <div class="mt-5">
              <Carousel
                :autoplay="1500"
                :wrap-around="true"
                pause-autoplay-on-hover
              >
                <Slide v-for="slide in truckSlides" :key="slide">
                  <img
                    :src="require(`@/assets/${slide}`)"
                    class="rounded-xl w-96"
                  />
                </Slide>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Our trucks section -->
    <div class="bg-white py-16">
      <div class="">
        <div class="container max-w-7xl mx-auto mb-12 px-4">
          <div class="mb-3 flex">
            <div
              class="w-2 h-2 bg-orange-400 rounded-full flex self-center mr-2"
            ></div>
            <div class="text-gray-400 font-[sarabun] font-light text-md">
              Trucks
            </div>
          </div>
          <hr class="bg-gray-600" />
        </div>

        <div class="md:flex container max-w-7xl mx-auto px-4">
          <h2
            class="md:basis-1/2 lg:basis-2/3 text-5xl font-bold text-orange-950 mb-8 font-[oxanium]"
          >
            Our Trucks
          </h2>
          <div class="md:basis-1/2 lg:basis-1/3 font-[sarabun] mb-5">
            <p class="text-gray-600 mb-8 text-lg">
              At Navi, we offer a diverse selection of trucks for all your
              transportation needs. Whether you're looking for heavy-duty
              vehicles for construction or reliable options for long-haul
              deliveries, we have you covered.
            </p>
          </div>
        </div>

        <div class="container mx-auto px-4 max-w-7xl">
          <carousel
            :wrap-around="true"
            v-bind="settings"
            :breakpoints="breakpoints"
            :transition="300"
            :autoplay="3000"
          >
            <slide v-for="slide in truckList" :key="slide" class="mx-2">
              <div class="bg-white w-auto">
                <img
                  :src="require(`@/assets/${slide.image}`)"
                  :alt="slide.name"
                  class="lg:w-80 object-cover rounded-xl h-80 md:h-[32rem]"
                />
                <div class="p-4">
                  <h3
                    class="text-xl font-semigbold text-gray-800 mb-2 font-[sarabun]"
                  >
                    {{ slide.name }}
                  </h3>
                </div>
              </div>
            </slide>
            <template #addons>
              <navigation class="bg-orange-400 rounded text-white" />
              <pagination />
            </template>
          </carousel>
        </div>
      </div>
    </div>

    <!-- service section -->
    <div class="bg-[#FFF7ED] py-16">
      <div class="container max-w-7xl mx-auto mb-12 px-4">
        <div class="mb-3 flex">
          <div
            class="w-2 h-2 bg-orange-400 rounded-full flex self-center mr-2"
          ></div>
          <div class="text-orange-900 font-[sarabun] font-light text-md">
            Services
          </div>
        </div>
        <hr class="bg-gray-600" />
      </div>
      <div class="md:container md:max-w-7xl mx-auto px-2 md:px-6">
        <h2 class="text-5xl font-bold text-orange-950 mb-6 font-[oxanium]">
          Our Service
        </h2>
        <p class="max-w-xl font-[sarabun] mb-12">
          At Navi, we offer a comprehensive range of services for your trucks,
          whether you're looking to upgrade your fleet or simply want to get the
          best value for your current vehicle, our team is here to assist you.
        </p>

        <div
          class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 md:gap-6 lg:gap-10 gap-2"
        >
          <!-- Find a Vehicle Card -->
          <router-link to="/find-vehicles">
            <div
              class="h-80 lg:h-72 bg-[url(@/assets/redTruck.jpg)] bg-cover text-white flex flex-col lg:flex-row justify-between bg-gray-300 bg-blend-multiply transform hover:scale-105"
            >
              <div class="md:basis-1/2"></div>
              <div
                class="bg-red-500/50 lg:bg-red-700/60 md:basis-4/12 lg:basis-1/2 backdrop-blur-md p-4 lg:flex"
              >
                <div class="lg:self-end lg:text-right lg:h-24">
                  <h2 class="text-2xl font-bold font-[oxanium]">
                    Find a vehicle
                  </h2>
                  <p class="font-[sarabun] text-md mb-4 md:mb-0">
                    Discover our wide range of trucks for sale
                  </p>
                </div>
              </div>
            </div>
          </router-link>

          <!-- Trade In/Sell Card -->
          <router-link to="/sell-vehicle">
            <div
              class="h-80 lg:h-72 bg-[url(@/assets/map.jpg)] bg-cover text-white flex flex-col lg:flex-row-reverse justify-between bg-gray-300 bg-blend-multiply transform hover:scale-105"
            >
              <div class="md:basis-1/2"></div>
              <div
                class="bg-teal-500/60 lg:bg-teal-700/60 md:basis-4/12 lg:basis-1/2 backdrop-blur-md p-4 lg:flex"
              >
                <div class="lg:self-end lg:text-left lg:h-24">
                  <h2 class="text-2xl font-bold font-[oxanium]">
                    Trade In/ Sell
                  </h2>
                  <p class="font-[sarabun] text-md mb-4 md:mb-0">
                    Upgrade your vehicle or fleet through our Trade In service
                  </p>
                </div>
              </div>
            </div>
          </router-link>

          <router-link to="/telematics">
            <!-- Vehicle Telematics -->
            <div
              class="h-80 lg:h-72 bg-[url(@/assets/truckLine.jpg)] bg-cover text-white flex flex-col lg:flex-row justify-between bg-gray-300 bg-blend-multiply transform hover:scale-105"
            >
              <div class="md:basis-1/2"></div>
              <div
                class="bg-orange-500/60 lg:basis-1/2 md:basis-4/12 backdrop-blur-md p-4 lg:flex"
              >
                <div class="lg:self-start lg:text-right lg:pt-4">
                  <h2 class="text-2xl font-bold font-[oxanium]">
                    Vehicle Telematics
                  </h2>
                  <p class="font-[sarabun] text-md mb-4 md:mb-0">
                    Realtime GPS Tracking and Fuel Monitoring
                  </p>
                </div>
              </div>
            </div>
          </router-link>

          <router-link to="/navi-fleet">
            <!-- Navi Fleet -->
            <div
              class="h-80 lg:h-72 bg-[url(@/assets/fleetImage.jpeg)] bg-cover text-white flex flex-col lg:flex-row-reverse justify-between bg-gray-300 bg-blend-multiply transform hover:scale-105"
            >
              <div class="md:basis-1/2"></div>
              <div
                class="bg-yellow-500/60 md:basis-4/12 lg:basis-1/2 backdrop-blur-md p-4"
              >
                <div class="lg:self-start lg:text-left lg:pt-4">
                  <h2 class="text-2xl font-bold font-[oxanium]">
                    Fleet Management
                  </h2>
                  <p class="font-[sarabun] text-md mb-4 md:mb-0">
                    Comprehensive solution to optimize your fleet operations
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- Testimonials section -->
    <div class="mt-10">
      <div class="container max-w-7xl mx-auto mb-20 md:mb-24 px-4">
        <div class="mb-3 flex">
          <div
            class="w-2 h-2 bg-orange-400 rounded-full flex self-center mr-2"
          ></div>
          <div class="text-orange-900 font-[sarabun] font-light text-md">
            Testimonials
          </div>
        </div>
        <hr class="bg-gray-600" />
      </div>
      <div>
        <div class="container max-w-7xl mx-auto mb-10 px-4">
          <h2
            class="md:text-5xl text-3xl text-orange-950 mb-3 lg:mb-6 font-[oxanium] text-center"
          >
            What our customers say?
          </h2>
          <p
            class="max-w-xl font-[sarabun] mb-12 text-center mx-auto text-gray-700"
          >
            Read what our customers have to say about us.
          </p>

          <div class="max-w-5xl mx-auto">
            <carousel :items-to-show="1">
              <slide v-for="slide in 6" :key="slide" class="">
                <div
                  class="bg-white w-3/4 lg:w-2/3 border border-gray-300 rounded-xl p-8"
                >
                  <div class="flex justify-center mb-4">
                    <StarIcon class="h-5 text-[#FFA033]" />
                    <StarIcon class="h-5 text-[#FFA033]" />
                    <StarIcon class="h-5 text-[#FFA033]" />
                    <StarIcon class="h-5 text-[#FFA033]" />
                    <StarIcon class="h-5 text-[#FFA033]" />
                  </div>
                  <div class="px-6">
                    <h3
                      class="text-lg md:text-3xl mx-auto text-orange-950 mb-8 font-[sarabun]"
                    >
                      "Navi's fleet management software has revolutionized the
                      way we handled our trucks. It has greatly improved our
                      efficiency and productivity."
                    </h3>

                    <h5
                      class="text-orange-950 font-[sarabun] mb-1 md:text-md text-sm"
                    >
                      John Doe
                    </h5>
                    <h5 class="text-gray-500 md:text-sm text-xs">
                      CEO, Farasi Company
                    </h5>
                  </div>
                </div>
              </slide>

              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>

<script setup>
import { ref } from "vue";

import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon, StarIcon } from "@heroicons/vue/20/solid";

const selectedPerson = ref("");
</script>

<script>
import Button from "@/components/Button.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import axios from "axios";
import DataRepository from "@/infrastructure/services/DataRepository";

export default {
  components: {
    Button,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: "start",
      },
      breakpoints: {
        // 700px and up
        500: {
          itemsToShow: 2,
          snapAlign: "center",
        },

        700: {
          itemsToShow: 2.5,
          snapAlign: "center",
        },
        900: {
          itemsToShow: 2.9,
          snapAlign: "center",
        },

        // 1024 and up
        1024: {
          itemsToShow: 3.5,
          snapAlign: "start",
        },
        1500: {
          itemsToShow: 5.8,
          snapAlign: "start",
        },
      },
      truckSlides: ["img/slide1.png", "img/slide2.png"],
      truckList: [
        {
          image: "img/truck1.jpeg",
          name: "Heavy Truck",
        },
        {
          image: "img/truck2.jpeg",
          name: "Cargo Truck",
        },
        {
          image: "img/truck3.jpeg",
          name: "Tanker Truck",
        },
        {
          image: "img/truck4.jpeg",
          name: "Mining Truck",
        },
        {
          image: "redTruck.jpg",
          name: "Light Truck",
        },
      ],
      brandsList: [],
      vehicleTypeList: [],
      isBrandLoading: false,
      brandErrorMessage: "",
      selectedBrand: null,
      selectedVehicleType: "",
      filterError: "",
      filters: {},
      isVehicleBodyTypeLoading: false,
      vehicleBodyTypeFetchErrorMessage: "",
    };
  },
  methods: {
    async fetchBrands() {
      this.isBrandLoading = true;
      const brandsResult = await DataRepository.fetchVehicleBrands();
      if (brandsResult.success && !brandsResult.error) {
        this.brandsList = brandsResult.success;
      } else {
        this.brandErrorMessage = brandsResult.error.message;
      }
      this.isBrandLoading = false;
    },
    async fetchVehicleBodyTypes() {
      this.isVehicleBodyTypeLoading = true;
      const vehicleBodyTypeResult = await DataRepository.fetchVehicleBodyType();
      if (vehicleBodyTypeResult.success && !vehicleBodyTypeResult.error) {
        this.vehicleTypeList = vehicleBodyTypeResult.success;
      } else {
        this.vehicleBodyTypeFetchErrorMessage =
          vehicleBodyTypeResult.error.message;
      }
      this.isVehicleBodyTypeLoading = false;
    },
    filterVehicles() {
      if (!this.selectedBrand && !this.selectedVehicleType) {
        this.filterError = "Nothing selected.";
        setTimeout(() => {
          this.filterError = ""; // Hide error after 3 seconds
        }, 3000);
      } else {
        this.filters.brand = this.selectedBrand ? this.selectedBrand.id : "";
        this.filters.type = this.selectedVehicleType
          ? this.selectedVehicleType.type
          : "";
        this.$router.push({
          path: "/find-vehicles",
          query: this.filters,
        });
      }
    },
  },
  mounted() {
    this.fetchBrands();
    this.fetchVehicleBodyTypes();
  },
};
</script>