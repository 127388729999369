<template>
  <div v-if="showContactForm" class="contact-form-container bg-black bg-opacity-50 h-screen w-screen flex items-center justify-center">
    <div class="relative bg-white h-auto w-auto py-24 px-24">
      <button @click="closePopUp" class="absolute top-0 right-0 h-8 w-8 mr-6 mt-6 focus:outline-none">
        <img src="@/assets/close-icon.svg" alt="Close" class="h-full w-full" />
      </button>
      <h1 class="font-roboto text-4xl text-black font-bold">Get in Touch with Navi</h1>
      <p class="font-roboto text-zinc-500 py-3">Fill the form and we will reach out within 24 hours.</p>
      <form class="container mx-auto pt-5" @submit.prevent="submitForm">
        <div class="flex flex-col pb-5">
          <div class="flex flex-col">
            <label for="name" class="block text-gray-700 font-bold mb-2">Name:</label>
            <input type="text" id="name" v-model="formData.name" required placeholder="Enter your name" class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-orange-500">
          </div>
        </div>
        <div class="flex flex-col pb-5">
          <label for="email" class="block text-gray-700 font-bold mb-2">Email:</label>
          <input type="email" id="email" v-model="formData.email" required placeholder="Enter your email" class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-orange-500">
        </div>
        <div class="flex flex-col pb-5">
          <label for="message" class="block text-gray-700 font-bold mb-2">Message:</label>
          <textarea id="message" v-model="formData.message" required placeholder="Enter your message" class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-orange-500" rows="6"></textarea>
        </div>
        <div class="flex justify-center mt-8">
          <button type="submit" class="px-6 py-3 bg-orange-500 text-white rounded-md hover:bg-orange-600 focus:outline-none">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import Button from "@/components/Button.vue";

export default {
  emits: ["close-pop-up"],
  name: "ContactForm",
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: ''
      },
      showContactForm: true
    };
  },
  components: {
    Button,
  },
  methods: {
    closePopUp() {
      this.showContactForm = false;
    },
    submitForm() {
      emailjs.send('service_mxqmq7s', 'template_c7kveb9', this.formData, 'Qz3LZtT6B5oC4MVSc')
        .then((response) => {
          console.log('Email sent successfully!', response);
          this.formData = {}; // Clear the form after submission
          this.closePopUp(); // Close the form after submission
        }, (error) => {
          console.error('Error sending email:', error);
        });
    }
  }
};
</script>

<style scoped>
/* Your existing styles */
</style>
