import store from "@/store"
import UserNotLoggedInError from "../errors/UserNotLoggedInError";


function logger(target, key, descriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
        console.log(`[LOG] Calling method ${key} with arguments:`, args);
        const result = await originalMethod.apply(this, args);
        console.log(`[LOG] Method ${key} returned:`, result);
        return result;
    };

    return descriptor;
}

function authenticated(target, key, descriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args) {
        const isUserAuthenticated = store.state.loggedIn
        if(!isUserAuthenticated) {
            throw new UserNotLoggedInError("You are not authorized to perform this action")
        } else {
            const result = await originalMethod.apply(this, args);
            return result;
        }
    }
    return descriptor;
}

export { logger, authenticated }