import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/ebikes',
    name: 'ebikes',
    component: () => import('../views/EbikesView.vue'),
  },
  {
    path: '/leasing',
    name: 'Truckleasing',
    component: () => import('../views/LeasingView.vue'),
  },
  {
    path: '/logistics',
    name: 'logistics',
    component: () => import('../views/LogisticsView.vue'),
  },
  {
    path: '/faq',
    name: 'faqs',
    component: () => import('../views/FAQView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactUsView.vue'),
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
