<template>
  <div :class="getBackground">
    <div class="flex items-center space-x-12 container mx-auto">
      <!-- Left Image -->
      <transition name="zoomout">
        <div class="w-full md:w-1/2 p-4 pt-0" :class="imageClass">
          <CurvedCornersImage
            :src="imageSrc"
            type="left"
            class="object-cover"
          />
        </div>
      </transition>

      <!-- Right Content -->
      <div class="w-full md:w-1/2 p-4 pt-0">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<style scoped>
.zoomout-enter-from {
  opacity: 0;
  transform: scale(0.5);
}
.zoomout-enter-active {
  transition: all 2s ease-in;
}
.zoomout-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
.zoomout-leave-active {
  transition: all 2s ease-out;
}
</style>
<script>
import CurvedCornersImage from "@/components/CurvedCornersImage.vue";
export default {
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    background: {
      type: String,
      required: false,
      default: "white",
    },
    imageClass: {
      type: String,
      required: false,
      default: "h-auto",
    },
  },
  components: {
    CurvedCornersImage,
  },

  computed: {
    getBackground() {
      return {
        "bg-white": this.background == "white",
        "bg-orange-50": this.background == "orange",
      };
    },
  },
};
</script>
