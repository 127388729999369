<template>
  <div class="flex flex-col overflow-auto">
    <!-- First Section -->
    <section>
      <div class="flex">
        <div class="basis-1/4">
          <p class="font-[oxanium]">Personal Information</p>
          <p class="font-[sarabun] font-light text-sm text-gray-400 mt-2">
            Update your information to receive updates and latest deals
          </p>
        </div>
        <div class="basis-3/4">
          <form class="w-full font-[sarabun]">
            <div class="flex mb-4 space-x-6">
              <div class="basis-1/2">
                <label class="block mb-2"
                  >First Name</label
                >
                <div class="relative">
                  <div
                    class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 text-gray-400"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    class="bg-gray-50 border border-gray-400 text-black rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full ps-10 p-2.5"
                    placeholder="e.g. John"
                  />
                </div>
              </div>

              <div class="basis-1/2">
                <label class="block mb-2"
                  >Last Name</label
                >
                <div class="relative">
                  <div
                    class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 text-gray-400"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    class="bg-gray-50 border border-gray-400 text-black rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full ps-10 p-2.5"
                    placeholder="e.g. Doe"
                  />
                </div>
              </div>
            </div>
            <label class="block mb-2"
              >Email Address</label
            >
            <div class="relative">
              <div
                class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>
              </div>
              <input
                type="text"
                class="bg-gray-50 border border-gray-400 text-black rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full ps-10 p-2.5"
                placeholder="johndoe@email.com"
                disabled
                :value="User.email"
              />
            </div>
            <label class="block mb-2 mt-4"
              >Phone Number</label
            >
            <div class="relative">
              <div
                class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                class="bg-gray-50 border border-gray-400 text-black rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full ps-10 p-2.5"
                placeholder="e.g. 0712345678"
                :value="User.phone"
              />
            </div>
            <div class="w-full grid justify-items-center mt-6">
              <button
                type="submit"
                class="font-[oxanium] text-white bg-orange-400 hover:bg-orange-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                SAVE
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
    <div class="border-t my-12"></div>
    <!-- Change password Section -->
    <section>
      <div class="flex">
        <div class="basis-1/4">
          <p class="font-[oxanium]">Change Password</p>
          <p class="font-[sarabun] text-gray-400 text-sm font-light mt-2">
            Update the password to login to your account.
          </p>
        </div>
        <div class="basis-3/4">
          <form
            class="w-full font-[oxanium]"
            @submit.prevent="validateChangePasswordForm"
          >
            <label class="block mb-2"
              >New Password</label
            >
            <p
              v-if="!isPasswordStrong"
              class="font-[sarabun] text-sm font-light flex justify-end mb-2 text-red-600"
            >
              Password should be 6 characters or more
            </p>
            <div class="relative">
              <div
                class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
                  />
                </svg>
              </div>
              <input
                type="password"
                :class="
                  isPasswordStrong
                    ? ['focus:ring-green-600 focus:border-green-600']
                    : ['focus:ring-red-600 focus:border-red-600']
                "
                class="bg-gray-50 border border-gray-400 text-black rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full ps-10 p-2.5"
                placeholder=""
                required
                v-model="newPassword"
              />
            </div>
            <label class="block mt-4"
              >Confirm Password</label
            >
            <p
              v-if="passwordMatchError"
              class="font-[sarabun] text-sm font-light flex justify-end mb-2 text-red-600"
            >
              Password does not match
            </p>
            <div class="relative">
              <div
                class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
                  />
                </svg>
              </div>
              <input
                type="password"
                :class="
                  passwordMatchError
                    ? ['focus:ring-red-600 focus:border-red-600']
                    : ['focus:ring-green-600 focus:border-green-600']
                "
                class="bg-gray-50 border border-gray-400 text-black rounded-lg block w-full ps-10 p-2.5"
                placeholder=""
                required
                v-model="confirmPassword"
              />
            </div>
            <div class="w-full grid justify-items-center mt-6">
              <button
                v-if="isPasswordResetLoading"
                class="bg-orange-400 px-10 py-2.5 rounded flex justify-center text-white"
              >
                <svg
                  aria-hidden="true"
                  class="w-4 h-4 text-gray-200 animate-spin fill-white mr-3"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                Saving
              </button>
              <button
                v-if="!isPasswordResetLoading"
                type="submit"
                @click="validateChangePasswordForm"
                class="font-[oxanium] text-white bg-orange-400 hover:bg-orange-900 font-medium rounded-lg text-sm w-full sm:w-auto px-10 py-2.5 text-center"
              >
                SAVE
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
    <div class="border-t my-12"></div>
    <!-- Delete account section -->
    <section class="mb-24">
      <div class="flex">
        <div class="basis-1/4">
          <p class="font-[oxanium]">Delete Account</p>
          <p class="font-[sarabun] text-gray-400 text-sm font-light mt-2">
            No longer want our services? Delete your account here. This action
            is not reversible. All information related to this account will be
            deleted permanently.
          </p>
        </div>
        <div class="basis-3/4 pl-14">
          <button
            @click="isOpen = true"
            type="submit"
            class="font-[oxanium] text-white bg-red-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            DELETE MY ACCOUNT
          </button>
        </div>
      </div>
    </section>

    <!-- Alert Dialog -->
    <div
      v-show="showAlertDialog"
      id="alert-3"
      :class="
        isSuccessDialog
          ? ['text-green-800 bg-green-50 border-green-500']
          : ['text-red-800 bg-red-50 border-red-500']
      "
      class="absolute right-0 w-72 flex items-center p-4 mb-4 rounded-lg border border-r-0"
      role="alert"
    >
      <svg
        class="flex-shrink-0 w-4 h-4"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>
      <span class="sr-only">Info</span>
      <div class="ms-3 text-sm font-medium">
        {{ dialogMessage }}
      </div>
      <button
        type="button"
        @click="showAlertDialog = false"
        :class="
          isSuccessDialog
            ? ['bg-green-50 text-green-500 hover:bg-green-200']
            : ['bg-red-50 text-red-500 hover:bg-red-200']
        "
        class="ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8"
        data-dismiss-target="#alert-3"
        aria-label="Close"
      >
        <span class="sr-only">Close</span>
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>

    <!-- Delete account dialog -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="isOpen = false" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium font-[oxanium] text-center leading-6 text-red-600"
                >
                  DELETE ACCOUNT
                </DialogTitle>
                <div class="mt-2">
                  <p class="font-[sarabun]">
                    Delete your account? This action will result in all information related to <label class="text-orange-400">{{ $store.state.user.email }}</label> being deleted permanently!
                  </p>
                </div>

                <div class="mt-4 flex justify-around">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    @click="isOpen = false"
                  >
                    CANCEL
                  </button>
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-500 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                    @click="deleteUserAccount"
                  >
                    DELETE ACCOUNT
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
</script>


<script>
import DataRepository from "@/infrastructure/services/DataRepository";
import ApiState from "@/model/ApiState";

export default {
  props: {
    User: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      passwordChangeError: null,
      passwordMatchError: false,
      isPasswordResetLoading: false,
      apiState: new ApiState(),
      deleteAccountApiState: new ApiState(),
      showAlertDialog: false,
      dialogMessage: "",
      isSuccessDialog: false,
      isPasswordStrong: true,
      isOpen: false
    };
  },
  watch: {
    confirmPassword(newVal, oldVal) {
      if (newVal !== this.newPassword) {
        this.passwordMatchError = true;
      } else {
        this.passwordMatchError = false;
      }
    },
    newPassword(newVal, oldVal) {
      if (newVal !== this.confirmPassword) {
        this.passwordMatchError = true;
        this.isPasswordStrong = newVal.length > 5;
      } else {
        this.passwordMatchError = false;
      }
    },
  },
  methods: {
    validateChangePasswordForm() {
      if (
        this.newPassword == this.confirmPassword &&
        this.newPassword != "" &&
        this.confirmPassword != "" &&
        this.newPassword.length > 5
      ) {
        this.resetPassword({ password: this.newPassword });
      }
    },
    async resetPassword(passwordJson) {
      this.isPasswordResetLoading = true;
      this.apiState = await DataRepository.updateUserDetails(passwordJson);
      this.isPasswordResetLoading = false;

      if (this.apiState.success && this.apiState.error == null) {
        this.showAlertDialog = true;
        this.dialogMessage = "Password changed successfully";
        this.isSuccessDialog = true;
        this.newPassword = "";
        this.confirmPassword = "";
      } else {
        this.showAlertDialog = true;
        this.dialogMessage = this.apiState.error;
        this.isSuccessDialog = false;
      }
      setTimeout((this.showAlertDialog = false), 10);
    },
    async deleteUserAccount(){
      this.deleteAccountApiState = await DataRepository.deleteUserAccount(this.$store.state.user.id)
    }
  },
};
</script>
