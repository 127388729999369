<template>
  <div>
    <!-- Login Fail Alert -->
    <div
      v-show="$store.state.apiState.error !== null"
      id="alert-2"
      class="fixed right-0 w-72 flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark:text-red-400"
      role="alert"
    >
      <svg
        class="flex-shrink-0 w-4 h-4"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>
      <span class="sr-only">Info</span>
      <div class="ms-3 text-sm font-medium">
        {{ $store.state.apiState.error }}
      </div>
      <button
        type="button"
        class="ms-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:text-red-400"
        data-dismiss-target="#alert-2"
        aria-label="Close"
      >
        <span class="sr-only">Close</span>
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>

    <!-- Login Success Alert -->
    <div
      v-show="$store.state.apiState.success"
      id="alert-3"
      class="fixed right-0 w-72 flex items-center p-4 mb-4 text-green-800 rounded-lg bg-green-50 border border-r-0 border-green-500 dark:text-green-400"
      role="alert"
    >
      <svg
        class="flex-shrink-0 w-4 h-4"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>
      <span class="sr-only">Info</span>
      <div class="ms-3 text-sm font-medium">
        The details have been submitted successfully. Please await contact.
      </div>
      <button
        type="button"
        class="ms-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8 dark:text-green-400"
        data-dismiss-target="#alert-3"
        aria-label="Close"
      >
        <span class="sr-only">Close</span>
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>

    <div
      class="bg-[url(@/assets/tradeInTruck.jpeg)] bg-cover py-24 bg-black/40 bg-blend-multiply"
    >
      <div class="container max-w-7xl mx-auto flex items-center">
        <div
          class="flex flex-row flex-wrap justify-center lg:justify-start w-10/12 lg:w-full pt-6 mx-auto lg:px-6"
        >
          <!-- Information Card -->
          <div class="lg:basis-6/12 w-full mb-10">
            <div
              class="border border-[#FFA033] backdrop-blur-md rounded-xl bg-[#C2710C]/80 py-8 basis-full h-full flex flex-col justify-center p-6"
            >
              <p class="md:w-10/12 text-white font-[oxanium] text-3xl mb-6">
                Get the best value for your vehicle by selling to Navi.
              </p>
              <p class="text-white md:w-10/12 font-[sarabun]">
                At Navi, we understand the importance of getting a fair
                valuation and professional service when it comes to selling your
                truck. With our extensive network of buyers, we ensure that you
                get the best deal possible.
              </p>
              <div class="md:grid md:grid-cols-2 gap-x-4 mt-5 hidden">
                <div class="pr-8 font-[sarabun]">
                  <CreditCardIcon class="w-8 text-white" />
                  <p class="text-lg text-white py-4">Fair Valuation</p>
                  <p class="text-white">
                    Our team of experts will provide you with a fair valuation
                    for your truck based on its condition and market value.
                  </p>
                </div>
                <div class="font-[sarabun]">
                  <BriefcaseIcon class="w-8 text-white" />
                  <p class="text-lg text-white py-4">Professional Service</p>
                  <p class="text-white">
                    We offer professional service throughout the sale process,
                    ensuring a hassle free experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Trade In Sequence -->
    <div class="py-12">
      <div class="container max-w-7xl mx-auto px-4">
        <h2
          class="text-3xl md:text-5xl font-[oxanium] text-orange-950 mb-8 text-center max-w-lg mx-auto"
        >
          How to Trade-In or Sell your Truck to Navi.
        </h2>
        <div
          class="flex flex-col sm:flex-row justify-between items-center mb-8 mt-24"
        >
          <!-- First Part of the sequence -->
          <div class="relative mb-6 basis-1/3">
            <div class="bg-[#FFF7ED] rounded-xl p-6 sm:mb-0 mr-0 sm:mr-4">
              <div class="flex justify-center mb-4">
                <div
                  class="absolute top-0 left-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-t from-[#C2710C] to-[#9A5E12] text-white rounded-full h-12 w-12 p-1 text-center justify-items-center border border-orange-200 border-4"
                >
                  <span class="text-3xl font-[oxanium] font-bold"> 1 </span>
                </div>
                <h3
                  class="text-2xl text-center font-semibold bg-gradient-to-t from-[#C2710C] to-[#9A5E12] text-transparent bg-clip-text font-[oxanium] mt-4"
                >
                  Provide Truck Details
                </h3>
              </div>
              <p
                class="text-gray-600 font-[sarabun] w-9/12 text-center mx-auto text-lg"
              >
                Enter your truck's details including make, model, year, mileage
                etc.
              </p>
            </div>
          </div>

          <!-- Second Part of the sequence -->
          <div class="relative mb-6 basis-1/3">
            <div class="bg-[#FFF7ED] rounded-xl p-6 sm:mb-0 mr-0 sm:mr-4">
              <div class="flex justify-center mb-4">
                <div
                  class="absolute top-0 left-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-t from-[#C2710C] to-[#9A5E12] text-white rounded-full h-12 w-12 p-1 text-center justify-items-center border border-orange-200 border-4"
                >
                  <span class="text-3xl font-[oxanium] font-bold"> 2 </span>
                </div>
                <h3
                  class="text-2xl text-center font-semibold bg-gradient-to-t from-[#C2710C] to-[#9A5E12] text-transparent bg-clip-text font-[oxanium] mt-4"
                >
                  Get a Trade-In/Sale Offer
                </h3>
              </div>
              <p
                class="text-gray-600 font-[sarabun] w-9/12 text-center mx-auto text-lg"
              >
                Get a competitive offer for your truck based on its condition
                and market data.
              </p>
            </div>
          </div>

          <!-- Third Part of the sequence -->
          <div class="relative mb-6 basis-1/3">
            <div class="bg-[#FFF7ED] rounded-xl p-6 sm:mb-0 mr-0 sm:mr-4">
              <div class="flex justify-center mb-4">
                <div
                  class="absolute top-0 left-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-t from-[#C2710C] to-[#9A5E12] text-white rounded-full h-12 w-12 p-1 text-center justify-items-center border border-orange-200 border-4"
                >
                  <span class="text-3xl font-[oxanium] font-bold"> 3 </span>
                </div>
                <h3
                  class="text-2xl text-center font-semibold bg-gradient-to-t from-[#C2710C] to-[#9A5E12] text-transparent bg-clip-text font-[oxanium] mt-4"
                >
                  Schedule Inspection
                </h3>
              </div>
              <p
                class="text-gray-600 font-[sarabun] w-9/12 text-center mx-auto text-lg"
              >
                Provide a convenient time for our team to do a physical
                inspection of the truck.
              </p>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center">
          <div>
            <button
              type="button"
              @click="showTradeInFormView"
              class="font-medium font-[oxanium] text-xl px-5 py-2.5 text-center me-4 text-white bg-[#F99416] mr-16"
            >
              Sell Vehicle
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- FAQ section -->
    <div class="bg-[#FFF7ED] py-16">
      <div class="container max-w-7xl mx-auto mb-12 px-8">
        <div class="mb-3 flex">
          <div
            class="w-2 h-2 bg-orange-400 rounded-full flex self-center mr-2"
          ></div>
          <div class="text-gray-400 font-[sarabun] font-light text-md">FAQ</div>
        </div>
        <hr class="bg-gray-600" />
      </div>

      <div class="py-8 px-6 max-w-7xl mx-auto flex flex-col md:flex-row gap-12">
        <div class="flex flex-col text-left basis-1/2 font-[oxanium]">
          <p
            class="lg:text-5xl text-3xl text-orange-950 text-base-content basis-2/3"
          >
            Frequently Asked Questions
          </p>

          <div class="basis-1/2 justify-end hidden md:block">
            <div class="p-2 bg-orange-300 inline-block rounded-xl">
              <QuestionMarkCircleIcon class="h-7" />
            </div>
            <p class="text-orange-950 text-xl my-4">Still have questions</p>

            <p class="text-gray-500 text-md font-semibold font-[sarabun]">
              Contact us today for more information
            </p>
          </div>
        </div>

        <div class="basis-1/2">
          <FAQ :faqs="faqlist" />
        </div>
      </div>
    </div>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-3 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="px-6 text-lg font-medium leading-6 text-gray-900 mb-4 font-[oxanium]"
                >
                  Sell A Vehicle
                </DialogTitle>
                <form class="py-6 px-6" @submit.prevent="sellVehicle">
                  <div class="flex flex-col md:flex-row mb-5 md:mb-0 gap-5">
                    <div class="md:mb-5 flex-1">
                      <label
                        for="brand"
                        class="block mb-2 text-sm font-medium text-gray-900"
                        >Vehicle Brand</label
                      >
                      <input
                        v-model="sellDetails.brand"
                        type="text"
                        id="brand"
                        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                        placeholder="e.g. Toyota"
                        required
                      />
                    </div>
                    <div class="md:md-5 flex-1">
                      <label
                        for="model"
                        class="block mb-2 text-sm font-medium text-gray-90"
                        >Vehicle Model</label
                      >
                      <input
                        v-model="sellDetails.model"
                        type="text"
                        id="model"
                        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:border-orange-400 focus:ring-orange-400 block w-full p-2.5"
                        placeholder="e.g. Toyota Stout"
                        required
                      />
                    </div>
                  </div>
                  <div class="flex flex-col md:flex-row mb-5 md:mb-0 gap-5">
                    <div class="md:mb-5 flex-1">
                      <label
                        for="year"
                        class="block mb-2 text-sm font-medium text-gray-900"
                        >Registration Year</label
                      >
                      <input
                        v-model="sellDetails.registration_year"
                        type="number"
                        id="year"
                        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                        placeholder="e.g. 2005"
                        required
                      />
                    </div>
                    <div class="md:mb-2 flex-1">
                      <label
                        for="service"
                        class="block mb-2 text-sm font-medium text-gray-90"
                        >Service Book</label
                      >
                      <select
                        v-model="sellDetails.service_history"
                        type="number"
                        id="service"
                        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:border-orange-400 focus:ring-orange-400 block w-full p-2.5"
                        required
                      >
                        <option>Full</option>
                        <option>Partial</option>
                        <option>None</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="flex flex-col md:flex-row mb-5 md:mb-0 gap-5">
                    <div class="md:mb-5 flex-1">
                      <label
                        for="location"
                        class="block mb-2 text-sm font-medium text-gray-900"
                        >Location</label
                      >
                      <input
                        v-model="sellDetails.location"
                        type="text"
                        id="location"
                        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                        placeholder="e.g. Nairobi, Sameer Park"
                        required
                      />
                    </div>
                    <div class="md:mb-5 flex-1">
                      <label
                        for="inspection"
                        class="block mb-2 text-sm font-medium text-gray-900"
                        >Date For Inspection</label
                      >
                      <input
                        v-model="sellDetails.inspection_date"
                        type="date"
                        id="inspection"
                        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                        placeholder="e.g. White"
                        required
                      />
                    </div>
                  </div>
                  
                  <div class="flex flex-col md:flex-row mb-5 md:mb-0 gap-5">
                    <div class="md:mb-5 flex-1">
                      <label
                        for="location"
                        class="block mb-2 text-sm font-medium text-gray-900"
                        >Does the vehicle have any pending loans?</label
                      >
                      <input
                        v-model="sellDetails.pending_loans"
                        type="checkbox"
                        id="location"
                        class="shadow-sm bg-gray-50 border border-gray-300 text-orange-500 text-sm focus:ring-orange-400 focus:border-orange-400 block  p-2.5"
                        placeholder="e.g. Nairobi, Sameer Park"
                      />
                    </div>
                  </div>
                  <div class="flex flex-col md:flex-row mb-5 md:mb-0 gap-5">
                    <div class="md:mb-5 flex-1">
                      <label
                        for="location"
                        class="block mb-2 text-sm font-medium text-gray-900"
                        >Has the vehicle ever been in an accident?</label
                      >
                      <input
                        v-model="sellDetails.accident"
                        type="checkbox"
                        id="location"
                        class="shadow-sm bg-gray-50 border border-gray-300 text-orange-500  text-sm focus:ring-orange-400 focus:border-orange-400 block  p-2.5"
                        placeholder="e.g. Nairobi, Sameer Park"
                      />
                    </div>
                  </div>
                  <div class="flex gap-4 mt-12 mb-5">
                    <button
                      type="submit"
                      class="w-1/2 md:w-1/3 text-white bg-orange-400 font-medium text-md font-[oxanium] px-5 py-2.5 text-center w-full"
                    >
                      <span class="">Submit</span>
                      <div
                        v-show="loading"
                        class="ml-5 inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      >
                        <span
                          class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                          >Loading...</span
                        >
                      </div>
                    </button>

                    <button
                      @click="closeModal"
                      type="button"
                      class="w-1/2 md:w-1/3 text-white bg-orange-400 font-medium font-[oxanium] text-md px-5 py-2.5 text-center w-full"
                    >
                      <span class="">Cancel</span>
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
  
  <script setup>
import {
  CreditCardIcon,
  BriefcaseIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/20/solid";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
</script>
  
  <script>
import Button from "@/components/Button.vue";
import FAQ from "@/components/FAQ.vue";
import FAQModel from "@/model/FAQModel";
import TradeInFormView from "@/components/TradeInFormView.vue";
import { mapState } from "vuex";
import DataRepository from "@/infrastructure/services/DataRepository";
import ApiState from "@/model/ApiState";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      faqlist: [
        new FAQModel(
          "How does Navi handle trade-ins",
          "Navi offers a seamless trade-in process, ensuring fair evaluations and competitive offers for your vehicles."
        ),
        new FAQModel(
          "What is fleet management",
          "Navi's fleet management software helps you efficiently manage your truck fleet, optimizing routes, tracking fuel consumption and monitoring vehicle performance"
        ),
        new FAQModel(
          "How does vehicle telematics work",
          "Navi's vehicle telematics system utilizes GPS and fuel tracking technology to provide real-time location data and monitor fuel usage, enabling better fleet management and cost control"
        ),
        new FAQModel(
          "What logistics services does Navi offer?",
          "Navi provides comprehensive logistics solutions, including route planning, load optimization and real-time tracking, ensuring efficient and reliable transportation of goods."
        ),
        new FAQModel(
          "Does Navi offer truck leasing?",
          "Yes, Navi offers flexible truck leasing options tailored to your business needs, providing access to a modern and well-maintained fleet without the burden of ownership."
        ),
      ],
      isOpen: false,
      sellDetails: {
        brand: "",
        model: "",
        registration_year: null,
        service_history: null,
        pending_loans: false,
        accident: false,
        trade_in: false,
        inspection_date: null,
        location: null,
        posted_by: null,
      },
      apiState: new ApiState(),
      loading: false
    };
  },
  methods: {
    closeModal() {
      this.isOpen = false;
    },

    openModal() {
      this.resetForm();
      this.isOpen = true;
    },
    // toggleColorNo() {
    //   this.clickedNo = true;
    //   setTimeout(() => {
    //     this.clickedNo = false;
    //   }, 1000);
    // },
    // toggleColorYes() {
    //   this.clickedYes = true;
    //   setTimeout(() => {
    //     this.clickedYes = false;
    //   }, 1000);
    // },
    showTradeInFormView() {
      if (this.$store.state.loggedIn) {
        this.openModal();
        this.sellDetails.posted_by = this.$store.state.user.id
      } else {
        this.$store.commit("updateRedirection", "/sell-vehicle");
        this.$router.push("/login");
      }
    },
    async sellVehicle() {
      this.loading = true;
      let _inspect_date = this.sellDetails.inspection_date
      this.sellDetails.inspection_date = new Date(_inspect_date)
      this.sellDetails.status = "Awaiting Inspection"

      this.apiState = await DataRepository.sellVehicle(this.sellDetails)
      this.loading = false
      if(this.apiState.error == null && this.apiState.success) {
        this.closeModal()
      } 
    },
    resetForm() {
      this.sellDetails = {
        brand: "",
        model: "",
        registration_year: null,
        service_history: null,
        pending_loans: false,
        accident: false,
        trade_in: false,
        inspection_date: null,
        location: null,
        posted_by: null , 
      };
    },
  },
  components: {
    TradeInFormView,
    FAQ,
    Button,
  },
  computed: mapState(["apiState"]),
  watch: {
    async apiState(newValue, oldValue) {
      if (newValue.success) {
        this.isOpen = false;
        await sleep(3000);
        this.$store.commit("resetAPIState");
      }
    },
  },
};
</script>