<template>
  <div>
    <Disclosure>
      <DisclosureButton class="py-2 flex justify-between w-full">
        <span class="font-[sarabun]">{{ title }}</span>
        <ChevronDownIcon class="h-5 w-5 text-gray-400" />
      </DisclosureButton>
      <DisclosurePanel><slot></slot></DisclosurePanel>
    </Disclosure>
  </div>
</template>
  
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
</script>

<script>
export default {
  name: "DropDown",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>


  