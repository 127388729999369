import { authenticated} from "../decorators/decorator";



export default class DataProxy {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async login(...args) {
        return await this.adapter.login(...args)
    } 


    async register(...args) {
        return await this.adapter.register(...args)
    }

    async forgotPassword(...args) {
        return await this.adapter.forgotPassword(...args)
    }

    async updateUserDetails(...args) {
        return await this.adapter.updateUserDetails(...args)
    }

    async deleteUser(...args) {
        return await this.adapter.deleteUser(...args)
    }

    @authenticated
    async signOut() {
        return await this.adapter.signOut()
    }

    async getUser(...args) {
        return await this.adapter.getUser(...args)
    }

    async getUserSession() {
        return await this.adapter.getUserSession()
    }


    async postData(...args) {
        return await this.adapter.postData(...args)
    }


    async fetchData(...args) {
        return await this.adapter.fetchData(...args)
    }

    @authenticated
    async patchData(...args) {
        return await this.adapter.patchData(...args)
    }

    @authenticated
    async deleteData(...args) {
        return await this.adapter.deleteData(...args)
    }

    // @authenticated
    async rpc(...args){
        return await this.adapter.rpc(...args)
    }

    async signInWithGoogle(...args){
        return await this.adapter.signInWithGoogle(...args)
    }
}