<template>
  <div class="section-component hero-section bg-gray-100 py-12">
    <div class="hero-container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="hero-content flex flex-wrap items-center">
        <div class="text-column w-full md:w-1/2 md:pr-8">
          <h2 class="text-3xl font-bold text-gray-800 mb-4">{{ tagline }}</h2>
          <ul class="list-disc pl-6 mb-4">
            <li v-for="(bullet, index) in bulletPoints" :key="index" class="text-base text-gray-600">{{ bullet }}</li>
          </ul>
          <div class="contact-input flex items-center">
            <input type="text" placeholder="Enter your email" v-model="email" class="py-2 px-4 border border-gray-300 rounded-l-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 flex-1">
            <button class="focus:outline-none bg-orange-500 hover:bg-orange-600 text-white font-medium py-2 px-4 rounded-r-md text-sm" @click="contactButtonClicked">Contact</button>
          </div>
        </div>
        <div class="image-column w-full md:w-1/2 mt-6 md:mt-0">
           <Image src="../assets/navi-logo.png" altText="logo" customClasses="custom-classes" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurvedCornersImage from "@/components/CurvedCornersImage.vue";

export default {
  components: {
    CurvedCornersImage,
  },
  props: {
    tagline: String,
    bulletPoints: Array,
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    contactButtonClicked() {
      // Handle contact button click if needed
      console.log("Contact button clicked");
    },
  },
};
</script>
