<template>
    <div>
      <div class="telematics font-[sarabun]">
        <div class="bg-[url(@/assets/img/map.gif)] bg-cover">
          <div class="bg-white/75">
            <div class="container mx-auto max-w-7xl py-24 flex">
              <div class="md:w-2/3 lg:w-2/3 p-8">
                <h1 class="text-3xl lg:text-7xl font-bold mb-4 font-[oxanium]">
                  Stay on Track & Save on Fuel with our
                  <span class="text-[#F99416]">GPS & Monitoring</span> Solutions.
                </h1>
  
                <div class="lg:max-w-3xl mt-3">
                  <p class="lg:text-lg font-[sarabun] text-semibold">
                    Embark on a journey of efficiency with our cutting-edge GPS
                    tracking and fuel monitoring solutions. Stay confidently on
                    course while maximizing savings every mile of the way. Drive
                    smarter, save smarter – it's all within your reach.
                  </p>
  
                  <div class="contact-input flex mt-12">
                    <button
                      @click="contact"
                      type="button"
                      class="font-medium font-[oxanium] text-lg px-5 py-2.5 text-center me-4 text-white bg-[#F99416] mr-16"
                    >
                      Book a Demo
                    </button>
                  </div>
                </div>
              </div>
              <div class="hidden md:block md:w-1/3 lg:w-1/3 pt-8 px-8">
                <img :src="require('@/assets/img/gps.png')" alt="" class="" />
              </div>
            </div>
          </div>
        </div>
  
  
      </div>

      <!-- Second section -->
      <div class="container mx-auto lg:max-w-7xl">
        <div class="my-16 md:mx-3">
          <div class="flex gap-6 flex-wrap-reverse lg:flex-nowrap">
            <!-- Left Part -->
            <div class="lg:w-2/5 rounded-3xl bg-gray-100 p-6 md:p-12 shadow-xl mx-3 md:mx-0">
              <p class="text-3xl lg:text-5xl font-[oxanium]">Reach out for A Demo</p>
              <p class="mt-8 text-gray-500 font-[sarabun]">
                We are confident that our platform will perform to your
                expectations and requirement. Reach out and book a demo session.
              </p>
              <div class="flex md:flex-col lg:flex lg:mt-8 items-center">
                <div class="hidden md:block lg:w-1/2 px-8">
                  <img :src="require('@/assets/img/gps2.png')" alt="" class="object-contain max-h-64" />
                </div>
                <button
                  @click="contact"
                  class="h-fit mt-8 lg:mt-16 lg:w-1/2 text-white bg-orange-400 font-medium text-md font-[oxanium] px-5 py-2.5 text-center w-full"
                >
                  <span class="">Book a Demo</span>
                </button>
              </div>
            </div>
  
            <!-- Right Part -->
            <div class="lg:w-3/5 rounded-3xl bg-orange-100 px-6 md:px-12 pt-12 shadow-xl mx-3 md:mx-0">
              <p class="text-3xl lg:text-5xl font-[oxanium] text-gray-600">
                GPS and Fuel Monitoring Platform
              </p>
              <p class="mt-8 text-gray-500 font-[sarabun]">
                The GPS and Fuel Monitoring platform is the second half needed to
                complete the tracking system. A platform ties up the functions of
                connection the GPS and fuel monitoring device to the satellite
              </p>
              <div
                class="mt-8 overflow-hidden rounded-t-2xl bg-slate-50 sm:w-auto lg:mt-5"
              >
                <img
                  alt=""
                  fetchpriority="high"
                  width="2174"
                  height="1464"
                  decoding="async"
                  data-nimg="1"
                  class="w-full h-72 object-top object-cover"
                  style="color: transparent"
                  sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                  :src="require(`@/assets/img/gpstelematics.png`)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Third Section -->
      <div class="container max-w-7xl mx-auto">
        <div class="w-full max-w-7xl px-2 py-16 sm:px-0">
          <TabGroup>
            <TabList
              class="flex max-w-md mx-auto space-x-1 rounded-xl bg-orange-500/20 p-2"
            >
              <Tab
                v-for="category in Object.keys(categories)"
                as="template"
                :key="category"
                v-slot="{ selected }"
              >
                <button
                  :class="[
                    'w-full rounded-lg py-3 text-lg md:text-xl font-medium leading-5 font-[oxanium]',
                    'ring-white/60 focus:outline-none text-orange-900',
                    selected
                      ? 'bg-white shadow-lg text-orange-700'
                      : 'hover:bg-white/[0.12] ',
                  ]"
                >
                  {{ category }}
                </button>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel
                v-for="(posts, idx) in Object.values(categories)"
                :key="idx"
                :class="[
                  'rounded-xl bg-white ',
                  'ring-white/60  focus:outline-none focus:ring-2',
                ]"
              >
                <div class="bg-white">
                  <div class="mx-auto max-w-7xl md:px-3">
                    
                    <div
                      class="mx-auto mt-16 max-w-3xl rounded-3xl ring-2 ring-orange-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none "
                    >
                      <div class="p-8 sm:p-10 lg:flex-auto">
                        <h3
                          class="text-3xl font-bold tracking-tight text-orange-900 font-[oxanium]"
                        >
                          {{ posts.title }}
                        </h3>
                        <p class="mt-2 text-base leading-7 text-gray-600 font-[sarabun]">
                          Deposit 50% and pay remaining amounts in 3 monthly installemnts or a one time payment
                        </p>
                        <div class="mt-2 flex items-center gap-x-4">
                          <h4
                            class="flex-none text-sm font-semibold leading-6 text-orange-600"
                          >
                            Features
                          </h4>
                          <div class="h-px flex-auto bg-gray-100" />
                        </div>
                        <ul
                          role="list"
                          class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                        >
                          <li
                            v-for="feature in posts.features"
                            :key="feature"
                            class="flex gap-x-3"
                          >
                            <CheckIcon
                              class="h-6 w-5 flex-none text-orange-600 font-[sarabun]"
                              aria-hidden="true"
                            />
                            {{ feature }}
                          </li>
                        </ul>
                        <button class="text-white px-5 py-1 bg-orange-600 rounded-3xl mt-8">
                          More
                        </button>
                      </div>
                      <div
                        class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0"
                      >
                        <div
                          class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16"
                        >
                          <div class="mx-auto max-w-xs px-8">
                            <p class="text-base font-semibold text-gray-600">
                              Pay once, own it forever
                            </p>
                            <p
                              class="mt-6 flex items-baseline justify-center gap-x-2"
                            >
                              <span
                                class="text-3xl font-bold tracking-tight text-gray-900"
                                >{{ formatNumber(posts.price) }}</span
                              >
                              <span
                                class="text-sm font-semibold leading-6 tracking-wide text-gray-600"
                                >Ksh</span
                              >
                            </p>
                            <button
                              class="mt-10 block w-full font-[oxanium]  bg-orange-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                              >Request Purchase</button
                            >
                            <p class="mt-6 text-xs leading-5 text-gray-600">
                              Invoices and receipts available 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
      
    </div>
  </template>
  
  <script setup>
  import { ref } from "vue";
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
  import { CheckIcon } from '@heroicons/vue/20/solid'
  
  const categories = ref({
    Silver: {
      features: [
        'Real-time Tracking',
        'Speed Monitoring',
        'Engine on and off alert',
        'Playback history',
        
      ],
      price: 15000,
      title: "Silver Tier"
    },
    Gold: {
      features: [
        'Real-time Tracking',
        'Speed Monitoring',
        'Engine on and off alert',
        'Playback history',
        
      ],
      price: 45000,
      title: "Gold Tier"
    },
    Platinum:  {
      features: [
        'Wireless Backup',
        'Terminal offline reports',
        'Low-voltage reports',
        'Mileage Reports',
        
      ],
      price: 20000,
      title: "Platinum Tier"
    },
  });
  </script>
  
  <script>
  import Button from "@/components/Button.vue";
  import FAQ from "@/components/FAQ.vue";
  import FAQModel from "@/model/FAQModel";
  import LeftSection from "@/components/LeftSection.vue";
  import RightSection from "@/components/RightSection.vue";
  import PricingTable from "@/components/PricingTable.vue";
  import ContactForm from "@/components/ContactForm.vue";
  
  export default {
    name: "TelematicView",
    components: {
      Button,
      FAQ,
      LeftSection,
      RightSection,
      PricingTable,
      ContactForm,
    },
    data() {
      return {
        header: "Track and Optimize",
        showContactForm: false,
        faqlist: [
          new FAQModel(
            "How does it work?",
            "Truck leasing, fleet managment, vehicle telematics"
          ),
          new FAQModel(
            "What are the benefits?",
            "Truck leasing, fleet managment, vehicle telematics"
          ),
          new FAQModel(
            "Is it customizable?",
            "Truck leasing, fleet managment, vehicle telematics"
          ),
          new FAQModel(
            "How can I get started?",
            "Truck leasing, fleet managment, vehicle telematics"
          ),
          new FAQModel(
            "Is training provided?",
            "Truck leasing, fleet managment, vehicle telematics"
          ),
        ],
      };
    },
    methods: {
      formatNumber(number) {
        if (number) {
          return number.toLocaleString();
        } else {
          return 0;
        }
      },
      toggleContactForm() {
        this.showContactForm = !this.showContactForm;
      },
  
      contact() {
        this.$router.push("/contact");
      },
    },
  };
  </script>
  