<template>
    <div class="bg-orange-100 w-96 h-auto p-8 border-2 border-black">
      <div class="items-center">
        <div class="mb-4">
            <img src="../assets/icon.svg" class="h-8 w-8 mr-3" />
        </div>
        <div>
          <h2 class="text-3xl font-semibold text-gray-800 mb-2">{{ title }}</h2>
          <p class="text-gray-800">{{ description }}</p>
        </div>
        <div class="mt-4">
          <p class="text-orange-600">Get Started ></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      icon: {
        type: String,
        required: false
      },
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  /* You can add Tailwind CSS classes here if needed */
  </style>
  