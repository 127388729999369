import Login from './views/Login.vue'
import Register from './views/Register.vue'
import ForgotPassword from './views/ForgotPassword.vue'

const authRoutes = [
    {
        path: '/login',
        component: Login,
        name: 'Login',
        meta: {showFooter:false}
    },
    {
        path: '/register',
        component: Register,
        name: 'Register',
        meta: {showFooter:false}
    },
    {   
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'Forgot',
        meta: {showFooter:false}
    }
]

export default router => {
    authRoutes.forEach(route => {
        router.addRoute(route);
    });
}