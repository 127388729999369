<template>
  <section>
    <div class="bg-white py-6 rounded-lg px-4">
      <div class="flex">
        <div class="h-full basis-1/6">
          <img src="@/assets/truck2.jpeg" alt="" class="object-cover" />
        </div>
        <div class="font-[sarabun] ml-2 grid content-center basis-5/6">
          <div class="flex font-[oxanium] font-semibold mb-3">
            <p class="basis-2/5">{{ vehicleSellOrderObject.brand }}</p>
            <p class="basis-3/5 text-right">{{ vehicleSellOrderObject.status }}</p>
          </div>
          <label class="font-[sarabun] text-gray-400"
            >Quantity: <label class="text-black ml-3">3</label>
          </label>
          <label class="font-[sarabun] text-gray-400"
            >Order No: <label class="text-black ml-3">#NV{{ vehicleSellOrderObject.serial_no.split('-')[0] }}</label>
          </label>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    vehicleSellOrderObject: {
      type: Object,
      required: true,
    },
  },
};
</script>
