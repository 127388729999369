<script setup>
import { ref } from 'vue';
import PricingTab from './PricingTab.vue';

const isAnnual = ref(true);
</script>

<template>
  <div>

    <!-- Pricing toggle -->
    <div class="flex justify-center max-w-[14rem] m-auto mb-8 lg:mb-16">
      <div class="relative flex w-full p-1 bg-white dark:bg-slate-900 rounded-full">
        <span class="absolute inset-0 m-1 pointer-events-none" aria-hidden="true">
          <span class="absolute inset-0 w-1/2 bg-orange-400 rounded-full shadow-sm shadow-indigo-950/10 transform transition-transform duration-150 ease-in-out" :class="isAnnual ? 'translate-x-0' : 'translate-x-full'"></span>
        </span>
        <button
          class="relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out"
          :class="isAnnual ? 'text-white' : 'text-slate-500 dark:text-slate-400'"
          @click="isAnnual = true"
          :aria-pressed="isAnnual"
        >Yearly <span :class="isAnnual ? 'text-indigo-200' : 'text-slate-400 dark:text-slate-500'">-20%</span></button>
        <button
          class="relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out"
          :class="isAnnual ? 'text-slate-500 dark:text-slate-400' : 'text-white'"
          @click="isAnnual = false"
          :aria-pressed="isAnnual"
        >Monthly</button>
      </div>
    </div>

    <div class="max-w-sm mx-auto grid gap-6 lg:grid-cols-3 items-start lg:max-w-none">

      <!-- Pricing tab 1 -->
      <PricingTab
        :yearly="isAnnual"
        planName="Silver"
        :price="{ yearly: 4700, monthly: 5000 }"
        planDescription="Deposit 50% and pay remaining amounts in 3 monthly installemnts or a one time payment of Ksh 15,000 for the device"
        :features="[
          'Real-time tracking',
          'Speed monitoring',
          'Engine on and off alert',
          'Playback history',
          'SOS button',
          'Mileage reports',
          'Geo-fence (optional)',
          'SMS tracking',
          'Parking history',
          'Driving behaviour alerts',
        ]" />

      <!-- Pricing tab 2 -->
      <PricingTab
        :yearly="isAnnual"
        :popular="true"
        planName="Gold"
        :price="{ yearly: 14700, monthly: 15000 }"
        planDescription="Deposit 50% and pay remaining amounts in 3 monthly installments or a one time payment of Ksh 45,000 for the device"
          :features="[
          'Fuel monitoring',
          'Fueling reports,event location, amount and time',
          'Siphoning report ',
          'Fuel consumption per trip report',
          'Next vehicle service alerts',
          'Wireless back up',
          'Terminal offline reports',
          'Low voltage reports',
          'Real-time tracking',
          'Speed monitoring',
          'Engine on and off alert',
          'Playback history',
          'SOS button',
          'Mileage reports',
          'Geo-fence (optional)',
          'SMS tracking',
          'Parking history',
          'Driving behaviour alerts',
        ]" />

      <!-- Pricing tab 3 -->
      <PricingTab
        :yearly="isAnnual"
        planName="Platinum" :price="{ yearly: 6350, monthly: 6700 }"
        planDescription="Deposit 50% and pay remaining amounts in 3 monthly installments or a one time payment of Ksh 20,000 for the device"
        :features="[
          'Wireless back up',
          'Terminal offline reports',
          'Low voltage reports',
          'Real-time tracking',
          'Speed monitoring',
          'Engine on and off alert',
          'Playback history',
          'SOS button',
          'Mileage reports',
          'Geo-fence (optional)',
          'SMS tracking',
          'Parking history',
          'Driving behaviour alerts',
        ]" />

    </div>

  </div>
</template>