<template>
  <button
    type="button"
    class = "font-medium font-[sarabun] text-sm px-5 py-2.5 text-center me-4"
    :class="getButtonClasses"
  >{{ label }}</button>
</template> 

<script>
export default {
  name: "Button",
  props: {
    filled: {
      type: Boolean,
      default: true,
    },

    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    getButtonClasses() {
      return {
        'text-white bg-orange-400': this.filled,
        'text-orange-400 border border-orange-400': !this.filled,
      };
    },
  },
};
</script>
