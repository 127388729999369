<template>
  <table class="w-full bg-white border border-slate-200 mt-5">
    <thead>
      <tr class="bg-gray-200"></tr>
    </thead>
    <tbody>
      <tr
        class="font-[sarabun]"
        v-for="(row, rowIndex) in tableData"
        :key="rowIndex"
        :class="{
          'bg-slate-200': rowIndex % 2 === 0,
          'bg-white': rowIndex % 2 !== 0,
        }"
      >
        <td
          v-for="(cell, cellIndex) in row"
          :key="cellIndex"
          class="py-2 px-4 border-b font-[sarabun]"
        >
          {{ cell }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
    
    <script>
import { list } from "postcss";

export default {
  data() {},
  props: {
    tableData: {
      required: true,
      default: [
        ["Brand", "Row 1, Col 2"],
        ["Model", "Row 2, Col 2"],
        ["Chasis No", "Row 1, Col 2"],
        ["Engine Size", "Row 2, Col 2"],
      ],
    },
  },
  methods: {
    formatNumber(number) {
      if (number) {
        return number.toLocaleString();
      } else {
        return 0;
      }
    },
  }
};
</script>