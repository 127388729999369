<template>
    <div :class="getBackground">
        <div class="flex items-center space-x-12 container mx-auto" >
    <!-- Left content -->
    <div class="w-full md:w-1/2 p-4 pt-0">
      <slot></slot>
    </div>

    <!-- Right image -->
    <div class="w-full md:w-1/2 p-4 pt-0" :class="imageClass">
      <CurvedCornersImage :src="imageSrc" type="right" class="object-cover"/>
    </div>
  </div>
    </div>
  
</template>

<script>
import CurvedCornersImage from "@/components/CurvedCornersImage.vue";
export default {
    
    props: {
        imageSrc: {
            type: String,
            required: true,
        },
        background: {
            type: String,
            required: false,
            default: "white"
        },
        imageClass: {
            type: String, 
            required: false,
            default: "h-auto"
        }
    },
    components: {
        CurvedCornersImage,
    },

    computed: {
        getBackground()  {
            return {
                "bg-white": this.background == "white",
                "bg-orange-50": this.background == "orange"
            }
        }
    }
};
</script>
