<template>
  <div class="truck-listing-card bg-white pb-8 rounded shadow">
    <div>
      <div class="relative h-52">
        <img
          v-if="VehicleModel.images[0]"
          :src="VehicleModel.images[0]"
          class="object-cover h-full w-full rounded-t"
        />
        <div
          v-else
          class="grid place-content-center h-48 bg-gray-50 border border-gray-50 rounded-lg"
        >
          <ion-icon
            class="h-10 w-10 text-gray-200"
            name="image-outline"
          ></ion-icon>
        </div>

        <button
          v-if="VehicleModel.condition === 'New'"
          class="absolute bottom-0 left-0 ml-1.5 -mb-4 px-3 py-1 bg-green-100 rounded text-sm text-green-900"
        >
          {{VehicleModel.condition}}
        </button>
        <button
          v-else
          class="absolute bottom-0 left-0 ml-1.5 -mb-4 px-3 py-1 bg-orange-50 rounded text-sm text-orange-400"
        >{{VehicleModel.condition}}
        </button>
      </div>
      <div class="px-3 pr-3 mt-6">
        <p
          class="font-[sarabun] font-bold text-xl text-orange-950 text-ellipsis overflow-hidden cursor-pointer group-hover:overflow-visible truncate"
        >
        {{ VehicleModel.brand_model.brand.name }}
        </p>

        <p
          class="font-[sarabun] text-gray-500 font-light text-ellipsis overflow-hidden truncate"
        >
          {{ VehicleModel.brand_model.model }}
        </p>
        <p
          class="font-[oxanium] font-bold text-orange-800 text-2xl pt-2 text-ellipsis overflow-hidden my-2.5 truncate"
        >
          {{ "Ksh " + formatNumber(VehicleModel.price) }}
        </p>
        <div class="grid grid-cols-2 gap-1.5 pt-3">
          <div class="flex">
            <ion-icon
              name="calendar-outline"
              size="small"
              class="text-orange-300"
            ></ion-icon>
            <p class="font-[sarabun] text-gray-500 font-light text-sm ml-2 truncate">
              {{ VehicleModel.registration_year }}
            </p>
          </div>
          <div class="flex">
            <ion-icon
              name="cog-outline"
              size="small"
              class="text-orange-300"
            ></ion-icon>
            <p class="font-[sarabun] text-gray-500 font-light text-sm ml-2 truncate">
              {{ VehicleModel.transmission }}
            </p>
          </div>
          <div class="flex">
            <ion-icon
              name="speedometer-outline"
              size="small"
              class="text-orange-300"
            ></ion-icon>
            <p class="font-[sarabun] text-gray-500 font-light text-sm ml-2 truncate">
              {{ formatNumber(VehicleModel.mileage) }} km
            </p>
          </div>
          <div class="flex">
            <font-awesome-icon
              :icon="['fas', 'gas-pump']"
              class="text-orange-300"
            />
            <p
              class="font-[sarabun] text-gray-500 font-light text-sm ml-2 truncate"
            >
              {{VehicleModel.drive}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TruckListingCard",
  props: {
    VehicleModel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatNumber(number) {
      if(number != null){
        return number.toLocaleString();
      }
      return 0
    },
  },
};
</script>
