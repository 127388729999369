<template>
    <div class="fixed inset-0 flex items-center justify-center z-50" v-if="showTradeInFormViewComponent">
      <div class="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" @click="closeTradeInForm"></div>
  
      <div class="relative max-w-full w-full h-full bg-white rounded-lg shadow-lg overflow-y-auto transition-transform duration-300 transform mx-4 my-8 sm:mx-8 sm:my-16 md:mx-16 md:my-24 lg:mx-20 lg:my-32 xl:mx-32 xl:my-40" :class="{'translate-x-full': !showTradeInFormViewComponent, 'translate-x-0': showTradeInFormViewComponent}">
        <button @click="closeTradeInForm" class="absolute top-0 right-0 m-4 text-gray-600">
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
  
        <div class="p-8">
          <h2 class="text-2xl font-semibold mb-6">Book Inspection</h2>
          <p class="text-lg mb-4">Our representatives will be in contact with you shortly.</p>
          <form @submit.prevent="submitForm">
              <!-- Vehicle Details -->
              <h3 class="text-lg font-semibold mb-4">Enter Vehicle Details</h3>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label for="make" class="block text-gray-700 font-bold mb-2">Brand *</label>
                  <input
                    v-model="formData.brand"
                    type="text"
                    id="make"
                    name="make"
                    class="form-input w-full focus:outline-none focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                    required
                  />
                </div>
                <div>
                  <label for="model" class="block text-gray-700 font-bold mb-2">Model *</label>
                  <input
                    v-model="formData.model"
                    type="text"
                    id="model"
                    name="model"
                    class="form-input w-full focus:outline-none focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                    required
                  />
                </div>
                <div>
                  <label for="registration" class="block text-gray-700 font-bold mb-2">Registration *</label>
                  <input
                    v-model="formData.registration"
                    type="text"
                    id="registration"
                    name="registration"
                    class="form-input w-full focus:outline-none focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                    required
                  />
                </div>
                <div>
                  <label for="year" class="block text-gray-700 font-bold mb-2">Year *</label>
                  <input
                    v-model="formData.year"
                    type="text"
                    id="year"
                    name="year"
                    class="form-input w-full focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                    required
                  />
                </div>
                <div>
                  <label for="mileage" class="block text-gray-700 font-bold mb-2">Mileage *</label>
                  <input
                    v-model="formData.mileage"
                    type="text"
                    id="mileage"
                    name="mileage"
                    class="form-input w-full focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                    required
                  />
                </div>
                <div>
                  <label for="color" class="block text-gray-700 font-bold mb-2">Color *</label>
                  <input
                    v-model="formData.color"
                    type="text"
                    id="color"
                    name="color"
                    class="form-input w-full focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                    required
                  />
                </div>
              </div>
              <div class="mb-4">
                <label class="block text-gray-700 font-bold mb-2">Additional Details</label>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div class="flex items-center mb-4">
                    <input
                      type="checkbox"
                      v-model="formData.accident"
                      class="form-checkbox focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                      style="color: orange"
                    />
                    <span class="ml-2 text-gray-700">Has the vehicle been in an accident?</span>
                  </div>
                  <div class="flex items-center mb-2">
                    <input
                      type="checkbox"
                      v-model="formData.pendingLoans"
                      class="form-checkbox focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                      style="color: orange"
                    />
                    <span class="ml-2 text-gray-700">Does the Vehicle have any pending loans?</span>
                  </div>
                  <div class="mt-2">
                    <label for="serviceHistory" class="block text-gray-700 font-bold mb-2">Service History </label>
                    <select
                      v-model="formData.serviceHistory"
                      id="serviceHistory"
                      name="serviceHistory"
                      class="form-select w-full focus:outline-none focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                      required
                    >
                      <option value="">Select</option>
                      <option value="Fair">None</option>
                      <option value="Excellent">Full</option>
                      <option value="Good">Partial</option>
                    </select>
                  </div>
                  <div class="mt-2">
                    <label for="vehicleCondition" class="block text-gray-700 font-bold mb-2">Vehicle Condition </label>
                    <select
                      v-model="formData.vehicleCondition"
                      id="vehicleCondition"
                      name="vehicleCondition"
                      class="form-select w-full focus:outline-none focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                      required
                    >
                      <option value="">Select</option>
                      <option value="Excellent">Excellent</option>
                      <option value="Good">Good</option>
                      <option value="Satisfactory">Satisfactory</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Terms and Conditions Checkbox -->
              <div class="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="terms"
                  v-model="formData.terms"
                  class="form-checkbox focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                  style="color: orange"
                  required
                />
                <label for="terms" class="ml-2 text-gray-700"
                  >* Terms and conditions apply. By submitting this form I agree to the terms and conditions and privacy
                  policies.</label
                >
              </div>
              <hr />
              <!-- cancel form input-->
              <div class="flex justify-between mt-8">
                <a href="/trade-in" class="text-gray-700 hover:text-gray-900">Cancel</a>
                <button type="submit" class="bg-orange-400 text-white py-2 px-4 rounded hover:bg-orange-600">
                  Confirm and Submit
                </button>
              </div>
            </form>
            <p v-if="isSubmitted" class="text-lg text-green-600 mt-4">Message sent successfully. Please wait for approval.</p>
          </div>
        </div>
    </div>
</template>

  <script>
  import emailjs from 'emailjs-com';

  export default {
    emits: ["close-pop-up"],
  name: "TradeInFormView",
    props: {
    showTradeInFormViewComponent: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    isSubmitted: false,
  closePopUp() {
      this.showTradeInFormView = false;
    },
    closeTradeInForm() {
      this.$emit('close-pop-up');
    },
    submitForm() {
  // Use emailjs to send the form data via email
  emailjs.send('service_mxqmq7s', 'template_c7kveb9', this.formData, 'Qz3LZtT6B5oC4MVSc')
    .then((response) => {
      console.log('Email sent successfully!', response);
      // Set isSubmitted to true upon successful submission
      this.isSubmitted = true;
      // Clear the form data
      this.formData = {};
      // Emit an event to close the form
      this.closeTradeInForm();
    })
    .catch((error) => {
      console.error('Error sending email:', error);
      // Handle error if email sending fails
      // You can display an error message to the user or take other appropriate actions
    });
},
  },
  data() {
    return {
      formData: {
        make: '',
        model: '',
        year: '',
        mileage: '',
        color: '',
        registration: '',
        serviceHistory: '',
        vehicleCondition: '',
        tradeIn: false,
        accident: false,
        pendingLoans: false,
        logBook: null,
        vehiclePictures: null,
        terms: false,
      },
    };
  },
};
</script>
  
  <style scoped>
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s ease;
  }
  
  .slide-enter,
  .slide-leave-to {
    transform: translateX(100%);
  }
  .option-hover-orange:hover {
    background-color: orange;
  }
  
  /* Additional CSS for responsiveness */
  @media (min-width: 640px) {
    .max-w-md {
      max-width: 28rem;
    }
  }
  @media (min-width: 768px) {
    .max-w-md {
      max-width: 32rem;
    }
  }
  @media (min-width: 1024px) {
    .max-w-md {
      max-width: 36rem;
    }
  }
  @media (min-width: 1280px) {
    .max-w-md {
      max-width: 40rem;
    }
  }
  @media (max-width: 768px) {
  .max-w-full {
    max-width: 100%;
  }
}
  
  /* Additional CSS for checkbox and select styling */
  .form-checkbox:focus-visible {
    outline: 2px solid orange;
  }
  .form-select:focus-visible {
    outline: 2px solid orange;
  }
  </style>