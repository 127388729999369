<template>
    <div class="">
      <div v-for="(faq, index) in faqs" :key="index" class="rounded-xl shadow-md my-4 p-2 bg-white">
        <button @click="toggleAccordion(index)" class="flex justify-between w-full p-4 text-left focus:outline-none">
          <span class="text-lg font-[oxanium] text-orange-800">{{ faq.question }}</span>
          <svg v-if="faq.open" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
          </svg>
          
          <svg v-else fill="currentColor" class="w-6 h-6" viewBox="-182 -182 819.00 819.00" xml:space="preserve" stroke="#000000" stroke-width="21.84" transform="matrix(1, 0, 0, 1, 0, 0)">
            <g id="SVGRepo_iconCarrier"> <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5 455,242.5 "></polygon> 
            </g>
          </svg>
        </button>
        <div v-show="faq.open" class="px-5 py-2">
          <p class="text-gray-700 font-[sarabun]">{{ faq.answer }}</p>
        </div> 
      </div>
    </div>
  </template>
  
  <script>
  import FAQModel from '@/model/FAQModel';
  export default {
    props: {
      faqs: {
        type: [FAQModel],
        required: true
      }
    },
    data() {
      return {
        
      };
    },
    methods: {
      toggleAccordion(index) {
        this.faqs[index].open = !this.faqs[index].open;
      }
    }
  };
  </script>
  
  <style scoped>
  </style>