<template>
  <img
    :src="getImageFilePath(src)"
    :alt="altText"
    :class="getImageClass"
    loading="lazy"
  />
</template>

<script>
export default {
  name: "Image",
  props: {
    src: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "left",
    },
  },
  methods: {
    getImageFilePath(filename) {
      return require("../assets/" + filename);
    },
  },
  computed: {
    getImageClass() {
      return {
        "rounded-t-[20px] rounded-br-[20px]": this.type == "left",
        "rounded-t-[20px] rounded-bl-[20px]": this.type == "right"
      }
    }
  }
};
</script>
