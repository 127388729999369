import { createClient } from "@supabase/supabase-js";
import IDataService from "../interfaces/IDataService";
import ApiState from "@/model/ApiState";
import { TabList } from "@headlessui/vue";

const supabaseUrl = process.env.VUE_APP_SUPABASE_URL;
const supabaseKey = process.env.VUE_APP_SUPABASE_KEY;

let supabaseInstance = createClient(supabaseUrl, supabaseKey)


export default class SupabaseAdapter extends IDataService {
    constructor() {
        super()
        if (supabaseInstance) {
            this.client = supabaseInstance
        } else {
            this.client = createClient(supabaseUrl, supabaseKey)
        }
    }

    async login({ email, password }) {
        try {
            const authResponse = await this.client.auth.signInWithPassword({
                email: email,
                password: password
            });
            return new ApiState(authResponse);
        } catch (error) {
            return new ApiState({ error: error });
        }
    }

    async register({ email, password, phoneNumber }) {
        try {
            const authResponse = await this.client.auth.signUp({
                email: email,
                password: password,
                phone: phoneNumber
            });
            return new ApiState(authResponse);
        } catch (error) {
            return new ApiState({ error: error });
        }
    }

    async signOut() {
        try {
            const authResponse = await this.client.auth.signOut()
            return new ApiState(authResponse)
        } catch (error) {
            return new ApiState({ error: error })
        }
    }

    async getUser(token = null) {
        try {
            const authResponse = await this.client.auth.getUser(token)
            return new ApiState(authResponse)
        } catch (error) {
            return new ApiState({ error: error })
        }
    }

    async getUserSession() {
        try {
            const authResponse = await this.client.auth.getSession()
            return new ApiState(authResponse)
        } catch (error) {
            return new ApiState({ error: error })
        }
    }

    async forgotPassword({ email }) {
        try {
            const authResponse = await this.client.auth.resetPasswordForEmail(email, { redirectTo: "/" })
            return new ApiState(authResponse)
        } catch (error) {
            return new ApiState({ error: error })
        }
    }

    async updateUserDetails({ newUserDetails }) {
        try {
            const changeUserDetailsResponse = await this.client.auth.updateUser(newUserDetails)
            return new ApiState(changeUserDetailsResponse)
        } catch (error) {
            return new ApiState({ error: error })
        }
    }

    async deleteUser({ userId }) {
        try {
            const deleteUserResponse = await this.client.auth.admin.deleteUser(userId)
            return new ApiState(deleteUserResponse)
        } catch (error) {
            return new ApiState({ error: error })
        }
    }

    async fetchData({ schema, table, column, filters, orderBy } = { schema: null, column: null, filters: null, orderBy: null }) {
        let tableSchema = ""
        if (schema == null) {
            tableSchema = "public"
        } else {
            tableSchema = schema
        }

        if (column === null) {
            const response = await this.client
                .schema(tableSchema)
                .from(table)
                .select();
            return new ApiState(response);
        }

        let request = this.client.schema(tableSchema).from(table).select(column)

        if (filters) {
            for (const filter of filters) {
                request = request.filter(filter.column, filter.operator, filter.value);
            }
        }

        if (orderBy) {
            const { column, ascending } = orderBy;
            request = request.order(column, { ascending });
        }

        const response = await request
        return new ApiState(response);
    }

    async postData({ table, data }) {
        const response = await this.client.from(table)
            .insert(data)
            .select();
        return new ApiState(response);
    }

    async patchData({ table, data, primaryKey, id }) {
        const response = await this.client.from(table)
            .update(data)
            .eq(primaryKey, id)
            .select();
        return new ApiState(response);
    }

    async deleteData({ table, primaryKey, id }) {
        const response = await this.client.from(table)
            .delete()
            .eq(primaryKey, id);
        if (response.status === 204) {
            return new ApiState({ success: true });
        }
    }

    async rpc({ functionName, params } = {functionName:null, params:null}) {
        const response = await this.client.rpc(functionName, params)
        return new ApiState(response)
    }
    
    async signInWithGoogle({token} = {token:null}){
        const response = await this.client.auth.signInWithIdToken({
            provider: 'google',
            token: token
          })

        return new ApiState(response)
    }

}