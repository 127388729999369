<template>
  <div class="mb-16 lg:w-10/12">
    <TabGroup>
      <TabList class="flex justify-between w-full space-x-5">
        <Tab v-slot="{ selected }" class="basis-1/2 outline-none">
          <div
            class="w-full border px-4 py-4 font-[oxanium] text-left"
            :class="{
              ' bg-orange-400 text-white': selected,
              'bg-white text-black': !selected,
            }"
          >
            <span class="font-semibold">NO</span>
            <p class="font-[sarabun] text-sm">
              I don't have a vehicle to trade In
            </p>
          </div>
        </Tab>
        <Tab v-slot="{ selected }" class="basis-1/2 outline-none">
          <div
            @click="openTradeInModal"
            class="w-full border px-4 py-4 font-[oxanium] text-left"
            :class="{
              ' bg-orange-400 text-white border-orange-400': selected,
              'bg-white text-black': !selected,
            }"
          >
            <span class="font-semibold">YES</span>
            <div v-if="$store.state.tradeInVehicleData" class="text-sm font-[sarabun]">
              <p>{{$store.state.tradeInVehicleData.brand}}</p>
              <p class="italic">Awaiting confirmation</p>
            </div>
            <p v-else class="font-[sarabun] text-sm">
              Get a complimentary <br />Valuation
            </p>
          </div>
        </Tab>
      </TabList>
    </TabGroup>

    <!-- Trade in form modal -->
    <TransitionRoot as="template" :show="isOpen">
      <Dialog as="div" class="relative z-10" @close="closeTradeInModal">
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black/30" />
            </TransitionChild>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
              >
                <div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle
                      as="h3"
                      class="text-lg font-medium leading-6 text-gray-900"
                    >
                      Book Inspection
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Our representatives will be in contact with you shortly.
                      </p>
                    </div>
                  </div>
                </div>
                <form @submit.prevent="submitForm">
                  <!-- Vehicle Details -->
                  <div class="mt-5">
                    <h3 class="text-lg font-semibold mb-4">
                      Enter Vehicle Details
                    </h3>
                    <div
                      class="grid text-start grid-cols-1 md:grid-cols-2 gap-4 mb-4"
                    >
                      <div class="mb-5">
                        <label
                          for="brand"
                          class="block mb-2 text-sm font-medium text-gray-900"
                          >Brand</label
                        >
                        <input
                          v-model="formData.brand"
                          type="text"
                          id="brand"
                          name="brand"
                          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                          required
                        />
                      </div>
                      <div class="mb-5">
                        <label
                          for="model"
                          class="block mb-2 text-sm font-medium text-gray-900"
                          >Model</label
                        >
                        <input
                          v-model="formData.model"
                          type="text"
                          id="model"
                          name="model"
                          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                          required
                        />
                      </div>
                      <!-- Add more input fields here -->
                      <div class="mb-5">
                        <label
                          for="registration"
                          class="block mb-2 text-sm font-medium text-gray-900"
                          >Registration</label
                        >
                        <input
                          v-model="formData.registration_year"
                          type="text"
                          id="registration"
                          name="registration"
                          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                          required
                        />
                      </div>
                      <div class="mb-5">
                        <label
                          for="year"
                          class="block mb-2 text-sm font-medium text-gray-900"
                          >Vehicle Location</label
                        >
                        <input
                          v-model="formData.location"
                          type="text"
                          id="year"
                          name="year"
                          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                          required
                        />
                      </div>
                      <div class="mb-5">
                        <label
                          for="mileage"
                          class="block mb-2 text-sm font-medium text-gray-900"
                          >Mileage</label
                        >
                        <input
                          v-model="formData.vehicle_mileage"
                          type="number"
                          id="mileage"
                          name="mileage"
                          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                          required
                        />
                      </div>
                      <div class="mb-5">
                        <label
                          for="color"
                          class="block mb-2 text-sm font-medium text-gray-900"
                          >Color</label
                        >
                        <input
                          type="text"
                          id="color"
                          name="color"
                          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                          required
                        />
                      </div>
                    </div>
                    <div class="mb-5">
                      <label class="block text-gray-700 font-bold mb-2"
                        >Additional Details</label
                      >
                      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div class="mt-2">
                          <label
                            for="hasAccident"
                            class="block text-gray-700 font-bold mb-2"
                            >Has the vehicle been in an accident?</label
                          >
                          <div class="flex items-center">
                            <input
                              v-model="formData.accident"
                              type="radio"
                              id="hasAccidentYes"
                              name="hasAccident"
                              value="TRUE"
                              class="form-radio focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                              required
                            />
                            <label
                              for="hasAccidentYes"
                              class="ml-2 text-gray-700"
                              >Yes</label
                            >
                          </div>
                          <div class="flex items-center">
                            <input
                              v-model="formData.accident"
                              type="radio"
                              id="hasAccidentNo"
                              name="hasAccident"
                              value="FALSE"
                              class="form-radio focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                              required
                            />
                            <label
                              for="hasAccidentNo"
                              class="ml-2 text-gray-700"
                              >No</label
                            >
                          </div>
                        </div>
                        <div class="mt-2">
                          <label
                            for="hasPendingLoans"
                            class="block text-gray-700 font-bold mb-2"
                            >Does the Vehicle have any pending loans?</label
                          >
                          <div class="flex items-center">
                            <input
                              v-model="formData.pending_loans"
                              type="radio"
                              id="hasPendingLoansYes"
                              name="hasPendingLoans"
                              value="TRUE"
                              class="form-radio focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                              required
                            />
                            <label
                              for="hasPendingLoansYes"
                              class="ml-2 text-gray-700"
                              >Yes</label
                            >
                          </div>
                          <div class="flex items-center">
                            <input
                              v-model="formData.pending_loans"
                              type="radio"
                              id="hasPendingLoansNo"
                              name="hasPendingLoans"
                              value="FALSE"
                              class="form-radio focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                              required
                            />
                            <label
                              for="hasPendingLoansNo"
                              class="ml-2 text-gray-700"
                              >No</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-5">
                      <div class="mt-2">
                        <label
                          for="serviceHistory"
                          class="block text-gray-700 font-bold mb-2"
                          >Service History</label
                        >
                        <select
                          v-model="formData.service_history"
                          id="serviceHistory"
                          name="serviceHistory"
                          class="form-select w-full focus:outline-none focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                          required
                        >
                          <option value="">Select</option>
                          <option value="None">None</option>
                          <option value="Full">Full</option>
                          <option value="Partial">Partial</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- Terms and Conditions Checkbox -->
                  <div class="flex items-center mb-4">
                    <input
                      type="checkbox"
                      id="terms"
                      v-model="formData.terms"
                      class="form-checkbox focus:ring-2 focus:ring-orange-400 focus:border-transparent"
                      style="color: orange"
                      required
                    />
                    <label for="terms" class="ml-2 text-gray-700"
                      >Terms and conditions apply. By submitting this form I
                      agree to the terms and conditions and privacy
                      policies.</label
                    >
                  </div>
                  <hr />
                  <!-- Cancel and Submit Buttons -->
                  <div
                    class="mt-5 flex justify-center flex-wrap-reverse space-x-4"
                  >
                    <button
                      v-if="!isSubmitting"
                      type="button"
                      class="mt-3 md:mt-0 w-full md:w-fit px-4 py-2 bg-white text-gray-800 font-semibold rounded-md border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2"
                      @click="isOpen = false"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="w-full md:w-fit px-4 py-2 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2"
                      :disabled="isSubmitting"
                    >
                      <span v-if="!isSubmitting">CONFIRM AND SUBMIT</span>
                      <span v-else>Submitting...</span>
                    </button>
                  </div>
                </form>
                <p v-if="isSubmitted" class="text-lg text-green-600 mt-4">
                  Message sent successfully. Please wait for approval.
                </p>
                <p v-if="errorMessage" class="text-lg text-red-600 mt-4">
                  {{ errorMessage }}
                </p>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!--Success pop up -->
    <TransitionRoot appear :show="isSuccessModalOpen" as="template">
      <Dialog as="div" @close="closeSuccessModal()" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle as="h3">
                  <div class="grid justify-items-center mb-5">
                    <font-awesome-icon
                      :icon="['fas', 'circle-check']"
                      class="h-14 w-14 text-green-600 mb-3"
                    />
                    <p class="text-lg font-medium text-gray-900 font-[oxanium]">
                      TRADE IN REQUEST SUCCESSFUL
                    </p>
                  </div>
                </DialogTitle>
                <div class="mt-2">
                  <p
                    class="font-[sarabun] grid place-content-center text-center"
                  >
                    Someone from our team will reach out to organize an
                    inspection in less than 24 hours.
                  </p>
                  <p class="font-[sarabun] grid place-content-center">
                    <br />Thank you for choosing Navi
                  </p>
                </div>

                <div class="mt-6 flex justify-center">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-orange-400 px-4 py-2 text-sm font-medium text-white"
                    @click="closeSuccessModal"
                  >
                    View Vehicles
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import {
  TransitionChild,
  TransitionRoot,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { TabGroup, TabList, Tab } from "@headlessui/vue";
</script>

<script>
import DataRepository from "@/infrastructure/services/DataRepository";
export default {
  data() {
    return {
      isOpen: false,
      isSuccessModalOpen: false,
      formData: {
        brand: "",
        model: "",
        registration_year: "",
        location: "",
        vehicle_mileage: "",
        accident: null,
        pending_loans: null,
        service_history: "",
        trade_in: true,
        posted_by: this.$store.state.user ? this.$store.state.user.id : null,
        terms: false,
        status: "Awaiting Inspection",
      },
      isSubmitting: false,
      isSubmitted: false,
      errorMessage: "",
    };
  },
  methods: {
    openTradeInModal() {
      if (this.$store.state.loggedIn) {
        this.isOpen = true;
      } else {
        this.$store.commit(
          "updateRedirection",
          `/find-vehicles/${this.$route.params.id}`
        );
        this.$router.push("/login");
      }
    },
    closeTradeInModal() {
      this.isOpen = false;
    },
    async submitForm() {
      this.isSubmitting = true;
      this.errorMessage = "";
      // Validate form data
      if (!this.formData.terms) {
        this.errorMessage = "Please accept the terms and conditions.";
        this.isSubmitting = false;
        return;
      }
      delete this.formData.terms
      // Submit form data to the backend
      const uploadFormResp = await DataRepository.sellVehicle(this.formData);

      if (uploadFormResp.success && !uploadFormResp.error) {
        this.isSubmitted = true;
        this.clearForm();
        this.$store.commit('setTradeInVehicleDetails', uploadFormResp.success[0])
        this.closeTradeInModal();
        //this.openSuccessModal();
      }else{
        this.errorMessage = `Failed to submit. ${uploadFormResp.error}`;
      }
      this.isSubmitting = false;
      
    },
    clearForm() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
      this.formData.hasAccident = null;
      this.formData.hasPendingLoans = null;
      this.formData.terms = false;
      this.isSubmitted = false;
      this.errorMessage = "";
    },
    openSuccessModal() {
      this.isSuccessModalOpen = true;
    },
    closeSuccessModal() {
      this.isSuccessModalOpen = false;
    },
  },
};
</script>

