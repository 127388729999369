<template>
  <div class="bg-[#222222]">
    <!-- Footer logo, tagline, contact and terms -->
    <div class="container max-w-7xl mx-auto py-10 flex flex-col md:flex-row md:justify-between px-6">
      <div class="w-full md:w-3/6 mx-auto md:pr-12">
        <div class="flex flex-col">
          <img src="../assets/navi-logo.png" alt="Footer Logo" class="w-24 h-auto mb-4" />
          <p class="text-left text-sm text-white mb-4">Stay up to date on the latest features <br> and releases by joining our newsletter.</p>
        </div>
        <div class="flex flex-col md:flex-row justify-start mb-4">
          <input type="text" placeholder="Contact Sales" class="border hidden border-orange-400 px-4 py-2 mr-2 text-sm text-orange-400 mb-3 md:mb-0" />
          <Button :filled="true" label="Contact Us" @click="toggleContactForm" />
          <ContactForm v-if="showContactForm" class="fixed top-0 bottom-0 right-0 left-0 z-99" />
        </div>
        <p class="text-left text-sm text-white">By subscribing, you agree to our Privacy Policy <br> & consent to receive updates from our company.</p>
      </div>

      <!-- Services, More and Follow-us  -->
      <div class="flex flex-col md:flex-row justify-between mt-4 w-full md:w-2/3">
        <div class="w-full md:w-1/6 mb-4 md:mb-0">
          <h6 class="font-medium mb-2 text-white">Services</h6>
          <div class="text-left">
            <router-link to="/sell-vehicle" class="text-white hover:text-orange-400 text-sm block mb-1">Sell A Vehicle</router-link>
            <router-link to="/find-vehicles" class="text-white hover:text-orange-400 text-sm block mb-1">Find A Vehicle</router-link>
            <router-link to="/telematics" class="text-white hover:text-orange-400 text-sm block mb-1">Vehicle Telematics</router-link>
            <router-link to="/logistics" class="text-white hover:text-orange-400 text-sm block mb-1">Logistics</router-link>
            <router-link to="/navi-fleet" class="text-white hover:text-orange-400 text-sm block mb-1">Navi Fleet</router-link>
          </div>
        </div>

        <div class="w-full md:w-1/6 mb-4 md:mb-0">
          <h6 class="font-medium mb-2 text-white">More</h6>
          <div class="text-left">
            <router-link to="/ebikes" class="text-white hover:text-orange-400 text-sm block mb-1">Ebikes</router-link>
            <router-link to="/leasing" class="text-white hover:text-orange-400 text-sm block mb-1">Leasing</router-link>
          </div>
        </div>

        <div class="w-full md:w-1/6 mb-4 md:mb-0">
          <h6 class="font-medium mb-2 text-white">Follow-us</h6>
          <div class="text-left">
            <router-link to="/" class="text-white hover:text-orange-400 text-sm block mb-1">Facebook</router-link>
            <router-link to="/" class="text-white hover:text-orange-400 text-sm block mb-1">Instagram</router-link>
            <router-link to="/" class="text-white hover:text-orange-400 text-sm block mb-1">x</router-link>
            <router-link to="/" class="text-white hover:text-orange-400 text-sm block mb-1">LinkedIn</router-link>
            <router-link to="/" class="text-white hover:text-orange-400 text-sm block mb-1">Youtube</router-link>
          </div>
        </div>


      </div>
    </div>
  
    <!-- Horizontal Line and Additional Information -->
    <hr class="my-2 border-t border-gray-300">

    <div class="container mx-auto flex justify-between items-center">
      <div class="">
        <p class="text-white">&copy; 2024 Navi. All rights reserved.</p>
      </div>
      <div class="flex gap-4">
        <router-link to="/" class="text-white hover:text-orange-400 text-sm">Privacy Policy</router-link>
        <router-link to="/" class="text-white hover:text-orange-400 text-sm">Terms of Service</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import ContactForm from '@/components/ContactForm.vue';
export default {
  components: {
    Button,
    ContactForm,
  },
  data() {
    return {
      showContactForm: false,
    };
  },
  methods: {
    toggleContactForm() {
      this.$router.push('/contact')
    },
  },
}

</script>
