import Module from './Module.vue';
import Home from "./views/Home.vue";
import Orders from './views/Orders.vue';
import Users from "./views/Users.vue";
import Contact from "./views/Contact.vue";
import Vehicle from "./views/Vehicles.vue";
import Sell from './views/Sell.vue';
import TradeIn from './views/TradeIn.vue';



const adminRoutes = [
    {
        path: "/admin",
        component: Module,
        name: "AdminHome",
        meta: { requiresAuth: true },
        children: [
            {
                path: "",
                component: Home
            },
            {
                path: "users",
                component: Users
            },
            {
                path: "orders",
                component: Orders
            },
            {
                path: "vehicles",
                component: Vehicle
            },
            {
                path: "sell",  
                component: Sell
            },
            {
                path: "contacts",
                component: Contact
            },
            {
                path: "trade-ins",
                component: TradeIn
            }
        ]
    }
]

export default router => {
    adminRoutes.forEach(route => {
        let meta = {
            showFooter: false,
            showNavBar: false
        }
        route.meta = meta
        router.addRoute(route);
    });
}