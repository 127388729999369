import Home from './views/FleetHomeView.vue'

const fleetRoutes = {
    path: '/navi-fleet',
    component: Home,
    name: 'NaviFleet'
}

export default router => {
    router.addRoute(fleetRoutes)
}