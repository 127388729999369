import Module from './Module.vue'
import Home from './views/FindVehicle.vue'
import VehicleDetails from './views/VehicleDetails.vue'
import VehicleQuote from './views/VehicleQuote.vue'

const findVehicleRoutes = [{
    path: '/find-vehicles',
    component: Home,
    name: 'FindVehicle',
    meta: {showFooter: false}
},
{
    path: '/find-vehicles/:id',
    component: VehicleDetails,
},
{
    path: '/quote',
    component: VehicleQuote,
},]

export default router => {
    findVehicleRoutes.forEach(route => {
        router.addRoute(route);
    });
}