import Home from './views/SellHomeView.vue'

const sellVehicleRoutes = {
    path: '/sell-vehicle',
    component: Home,
    name: 'SellVehicle'
}

export default router => {
    router.addRoute(sellVehicleRoutes)
}