import Module from './Module.vue'
import ProfileHome from "./views/ProfileHome.vue";

const userProfileRoutes = {
    path: '/profile',
    component: Module,
    name: 'UserProfile',
    meta: { requiresAuth: true },
    children: [
        {
            path: "",
            component: ProfileHome,
        }
    ]
}

export default router => {
    router.addRoute(userProfileRoutes)
}