<template>
    <div class="py-6">
      <h2 class="text-2xl font-semibold mb-4">Features</h2>
      <hr>
      <div>
        <div class="flex flex-wrap justify-left mt-2 space-x-2">
        <div v-for="(feature, index) in featuresList" :key="index" class="w-fit h-fit bg-orange-200 rounded-full mt-2">
          <div class="w-fit h-fit bg-orange-200 rounded-full px-3 py-1.5 font-sm"><p>{{ feature }}</p></div>
        </div>
      </div>
      
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Features',
    props: {
      featuresList:{
        type:Object,
        required:true,
        default:[]
      }
    }
  }
  </script>