<template>
  <div class="">
    <div v-if="showNavBar" id="navbar" class="sticky w-full top-0 z-10 bg-white">
      <Navbar />
    </div>
    <router-view />
    <Footer v-if="showFooter" class="relative" />
  </div>
</template>



<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Section from "@/components/Section.vue";
import DataRepository from "./infrastructure/services/DataRepository";

export default {
  data() {
    return {
      sessionRecoveryTrials: 0,
      showFooter: true,
      showNavBar: true
    };
  },
  methods: {
    async getUserSession() {
      if (this.sessionRecoveryTrials < 1) {
        await DataRepository.getUserSession();
      }
      this.sessionRecoveryTrials += 1;
    },
  },
  components: {
    Navbar,
    Footer,
    Section,
  },
  mounted() {
    this.$router.beforeEach(async (to, from, next) => {
      // Ensure user session is fetched before each route navigation
      await this.getUserSession();

      // If the route requires authentication
      if (to.meta.requiresAuth) {
        if (!this.$store.state.session) {
          // If user is not authenticated, redirect to login
          this.$store.state.redirectionUrl = to.path;
          next("/login");
          return;
        }
      }
      
      // Update showFooter based on route meta information
      this.showFooter =
        to.meta.showFooter !== undefined ? to.meta.showFooter : true;
      // show or hide navigation bar
      if(to.meta.showNavBar !== undefined) {
        this.showNavBar = to.meta.showNavBar
      } else {
        this.showNavBar = true;
      }
      next();
    });
  },
  created() {
    import("flowbite");
  },
};
</script>

<style>
@font-face {
  font-family: "oxanium";
  src: url("~@/assets/fonts/Oxanium-VariableFont_wght.ttf");
}

@font-face {
  font-family: "sarabun";
  src: url("~@/assets/fonts/Sarabun-Regular.ttf");
}

.nav-bg {
  transition: background-color 0.3s ease;
}
</style>