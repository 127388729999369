export default class RegistrationError{
    constructor({password1 = null,  username = null}) {
        this.password1 = password1
        this.username = username
    }

    getErrorMsg() {
        if(this.password1 != null) {
            return this.password1.join(" ")
        } else if(this.username != null) {
            return "A user with that Email already exists"
        } else {
            return "Unknown error occurred. Please Contact Administrator."
        }
    }
}