<template>
    <div class="about fleetmanagement ">
      <!-- First Section -->
      <div
        class="container max-w-7xl px-6 mx-auto py-8 grid justify-items-center"
      >
        <div class="text-center">
          <h4
            class="text-sm md:text-base uppercase font-[oxanium] mb-8 font-semibold text-orange-700"
          >
            # Fleet Maintenance Management Software
          </h4>
          <h1 class="text-3xl md:text-7xl font-bold mb-4 font-[oxanium]">
            Seamless Solutions,
            <span class="text-[#F99416]">Fleet Excellence</span> Unleashed.
          </h1>
        </div>
  
        <div class="text-center lg:max-w-3xl">
          <p class="md:text-lg font-[sarabun]">
            Experience the road to unparalleled efficiency with Seamless Solutions
            – where Fleet Excellence is Unleashed. Our cutting-edge technology
            seamlessly integrates every aspect of fleet management, empowering you
            to drive success with precision.
          </p>
  
          <div class="contact-input items-center mt-12 justify-center">
            <div class="mb-8">
              <div class="flex justify-center mb-1 ">
                <StarIcon class="h-4 text-[#FFA033]" />
                <StarIcon class="h-4 text-[#FFA033]" />
                <StarIcon class="h-4 text-[#FFA033]" />
                <StarIcon class="h-4 text-[#FFA033]" />
                <StarIcon class="h-4 text-[#FFA033]" />
              </div>
              <div class="font-[sarabun] text-sm">100's of reviews</div>
            </div>
            <button
              @click="BookADemo"
              type="button"
              class="font-medium font-roboto text-md px-5 py-2.5 text-center text-white bg-[#F99416] rounded-3xl"
            >
              Book a Demo
            </button>
          </div>
        </div>
      </div>

      <!-- Second Section -->
      <div class="inset-0 h-full w-full bg-gray-100/10 bg-[linear-gradient(to_right,#80808012_1px,transparent_2px),linear-gradient(to_bottom,#80808012_1px,transparent_2px)] bg-[size:96px_96px]">
        <div class="py-12 my-8">
          <section class="lg:flex container max-w-7xl mx-auto mb-16 mt-24 ">
          <TabGroup>
            <TabList class="flex lg:flex-col space-x-1 mt-16 mx-4 md:mr-2 lg:w-1/3 justify-center lg:justify-start" aria-orientation="horizontal">
              <Tab
                v-for="category in categories"
                as="template"
                :key="category"
                v-slot="{ selected }"
              >
                <div
                  :class="[
                    'md:rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6',
                    'focus:outline-none font-[sarabun] font-semibold text-gray-800 text-center',
                    selected
                      ? 'border border-gray-200 shadow-lg text-orange-600 backdrop-blur-sm '
                      : 'hover:bg-white/[0.12] lg:bg-white/50 ',
                  ]"
                >
                  {{ category.title }}
                  <p class="mt-2 hidden text-sm lg:block text-gray-500 font-normal" >
                    {{ category.description }}
                  </p>
                  </div>
                  
                
              </Tab>
            </TabList>
            <TabPanels class="mx-4 lg:mr-2 lg:w-2/3">
              <TabPanel :key=1 :class="[
                  'rounded-xl bg-white',
                  'ring-white/60 ring-offset-2 ring-offset-orange-400 focus:outline-none focus:ring-2',
                ]">
                
                <div
                    class="mt-10 overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 "
                  >
                    <img
                      alt=""
                      fetchpriority="high"
                      width="2174"
                      height="1464"
                      decoding="async"
                      data-nimg="1"
                      class="w-full"
                      style="color: transparent"
                      sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      
                      :src="require(`@/assets/dashboard/dashboard10.png`)"
                    />
                  </div>
              </TabPanel>
              <TabPanel :key=2 :class="[
                  'rounded-xl bg-white',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                ]">
                <div
                    class="mt-10 overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0"
                  >
                    <img
                      alt=""
                      fetchpriority="high"
                      width="2174"
                      height="1464"
                      decoding="async"
                      data-nimg="1"
                      class="w-full"
                      style="color: transparent"
                      sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      
                      :src="require(`@/assets/dashboard/dashboard16.png`)"
                    />
                  </div>
              </TabPanel>
              <TabPanel :key=3 :class="[
                  'rounded-xl bg-white',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                ]">
                <div
                    class="mt-10 overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0"
                  >
                    <img
                      alt=""
                      fetchpriority="high"
                      width="2174"
                      height="1464"
                      decoding="async"
                      data-nimg="1"
                      class="w-full"
                      style="color: transparent"
                      sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      
                      :src="require(`@/assets/dashboard/dashboard18.png`)"
                    />
                  </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
          </section>
        </div>
      </div>

      <!-- Trusted by Section -->
      <div class="bg-white py-8">
        <div class="container max-w-7xl mx-auto">
          <h4 class="text-lg uppercase font-[oxanium] mb-8 font-semibold text-center">
            Trusted by
          </h4>
          <div class="flex justify-center overflow-auto mx-4 gap-x-6">
            <img :src="require(`@/assets/runda-logo.png`)" alt="Runda Logo" class="object-cover h-10 md:h-14">
            <img :src="require(`@/assets/navi-logo.png`)" alt="Runda Logo" class="object-cover h-10 md:h-14">
            <img :src="require(`@/assets/farasi-logo.png`)" alt="Farasi Logo" class="object-cover h-10 md:h-14">
          </div>
        </div>
      </div>

      <!-- Testimonials section -->
      <div class="mt-10">
        <div class="container max-w-7xl mx-auto mb-20 md:mb-24 px-4">
          <div class="mb-3 flex">
            <div class="w-2 h-2 bg-orange-400 rounded-full flex self-center mr-2"></div>
            <div class="text-orange-900 font-[sarabun] font-light text-md">
              Testimonials
            </div>
          </div>
          <hr class="bg-gray-600"/>
        </div>
        <div>
          <div class="container max-w-7xl mx-auto mb-10 px-4">
            <h2 class="md:text-5xl text-3xl text-orange-950 mb-3 lg:mb-6 font-[oxanium] text-center">What our customers say</h2>
            <p class="max-w-xl font-[sarabun] mb-12 text-center mx-auto text-gray-700">
            Read what our customers have to say about us.
            </p>
  
            <div class="max-w-5xl mx-auto">
              <carousel :items-to-show="1">
                <slide v-for="slide in 6" :key="slide" class="">
                  <div class="bg-white md:w-3/4 lg:w-2/3 border border-gray-300 rounded-xl p-8">
                    <div class="flex justify-center mb-4">
                      <StarIcon class="h-5 text-[#FFA033]" />
                      <StarIcon class="h-5 text-[#FFA033]" />
                      <StarIcon class="h-5 text-[#FFA033]" />
                      <StarIcon class="h-5 text-[#FFA033]" />
                      <StarIcon class="h-5 text-[#FFA033]" />
                    </div>
                    <div class="px-6">
                      <h3 class="text-lg md:text-3xl mx-auto text-orange-950 mb-8 font-[sarabun]">
                        "Navi's fleet management software has revolutionized the way we handled our trucks. It 
                        has greatly improved our efficiency and productivity."
                      </h3>
  
                      <h5 class="text-orange-950 font-[sarabun] mb-1 md:text-md text-sm">
                        John Doe
                      </h5>
                      <h5 class="text-gray-500 md:text-sm text-xs">
                        CEO, Farasi Company
                      </h5>
                    </div>
                  </div>
                </slide>
  
                <template #addons>
                  <navigation />
                  <pagination />
                </template>
              </carousel>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script setup>
  import { StarIcon } from "@heroicons/vue/20/solid";
  import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
  import { ref } from 'vue'
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
  
  const categories = ref([
    {
      title: "Fleet Management",
      description: "Automate manual precesses and streamline your fleet management tasks for increased productivity"
    },
    {
      title: "Fuel Management",
      description: "Monitor your precise fuel consumption for each vehicle"
    },
    {
      title: "Driver Management",
      description: "Efficiently manage all your driver in one terminal."
    }
  ])
  
  </script>
  
  <script>
  import Button from "@/components/Button.vue";
  import FAQ from "@/components/FAQ.vue";
  import FAQModel from "@/model/FAQModel";
  import LeftSection from "@/components/LeftSection.vue";
  import RightSection from "@/components/RightSection.vue";
  import PricingTable from "@/components/PricingTable.vue";
  import CTASectionCard from "@/components/CTASectionCard.vue";
  import ContactForm from "@/components/ContactForm.vue";
  import Card from "@/components/Card.vue";
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
  
  export default {
    data() {
      return {
        header: "Streamline Your Fleet Management with Navi",
        showContactForm: false,
        faqlist: [
          new FAQModel(
            "How does Navi handle trade-ins",
            "Navi offers a seamless trade-in process, ensuring fair evaluations and competitive offers for your vehicles."
          ),
          new FAQModel(
            "What is fleet management",
            "Navi's fleet management software helps you efficiently manage your truck fleet, optimizing routes, tracking fuel consumption and monitoring vehicle performance"
          ),
          new FAQModel(
            "How does vehicle telematics work",
            "Navi's vehicle telematics system utilizes GPS and fuel tracking technology to provide real-time location data and monitor fuel usage, enabling better fleet management and cost control"
          ),
          new FAQModel(
            "What logistics services does Navi offer?",
            "Navi provides comprehensive logistics solutions, including route planning, load optimization and real-time tracking, ensuring efficient and reliable transportation of goods."
          ),
          new FAQModel(
            "Does Navi offer truck leasing?",
            "Yes, Navi offers flexible truck leasing options tailored to your business needs, providing access to a modern and well-maintained fleet without the burden of ownership."
          ),
        ],
        searchvehicle: "",
      };
    },
    components: {
      Button,
      FAQ,
      LeftSection,
      RightSection,
      PricingTable,
      CTASectionCard,
      ContactForm,
      Card,
      Carousel,
      Slide,
      Pagination,
      Navigation,
    },
    methods: {
      toggleContactForm() {
        this.showContactForm = !this.showContactForm;
      },
      BookADemo() {
        this.$router.push("/contact")
      }
    },
  };
  </script>
  