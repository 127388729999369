<template>
  <div
    v-if="Object.keys(this.$store.state.selectedVehicle).length > 0"
    class="flex flex-wrap lg:flex-nowrap w-full container mx-auto max-w-7xl"
  >
    <!-- Vehicle Order Details -->
    <div class="basis-full flex-auto lg:basis-4/12 pr-8 py-10 border-r my-2">
      <div class="md:flex lg:block">
        <img
          :src="$store.state.selectedVehicle.images[0]"
          class="object-cover md:h-[20vh] lg:h-[30vh] md:basis-1/3"
        />
        <div class="md:basis-2/3">
          <p class="font-[oxanium] text-orange-900 text-xl mt-2">
            {{ $store.state.selectedVehicle.brand_model.brand.name }}
          </p>
          <p class="font-[sarabun]">
            {{ $store.state.selectedVehicle.brand_model.model }}
          </p>
          <p class="font-[oxanium] text-2xl text-orange-900 font-semibold mt-5">
            Ksh {{ formatNumber($store.state.selectedVehicle.price) }}
          </p>
          <div class="border-t my-4"></div>
          <!-- Trade In -->
          <p class="font-[oxanium] font-medium text-xl mb-3 text-gray-400">
            Trade In
          </p>
          <div
            v-if="$store.state.tradeInVehicleData"
            class="flex justify-between font-[sarabun] mt-2 text-orange-900 items-center"
          >
            <div>
              <p>{{ $store.state.tradeInVehicleData.brand }}</p>
              <p>{{ $store.state.tradeInVehicleData.model }}</p>
            </div>
            <p class="text-green-600">TBC</p>
          </div>
          <p v-else class="font-[sarabun] text-orange-900">N/A</p>
          <div class="border-t my-4"></div>
          <!-- Ad Ons -->
          <p class="font-[oxanium] font-medium text-xl mb-3 text-gray-400">
            Ad Ons
          </p>
          <p
            v-if="$store.state.includeGps"
            class="font-[sarabun] text-orange-900"
          >
            GPS Tracker
          </p>
          <p v-else class="font-[sarabun] text-orange-900">N/A</p>
          <div class="border-t my-4"></div>
          <p class="font-[oxanium] font-medium text-xl mb-3 text-gray-400">
            Asset Financing
          </p>
          <div class="flex flex-wrap">
            <div
              class="flex-auto basis-full md:basis-1/2 lg:basis-2/3 leading-loose font-[sarabun]"
            >
              <p>Cash Deposit</p>
              <p>Monthly Payments</p>
              <p>Term Length</p>
              <p>Vehicle Price</p>
            </div>
            <div
              class="flex-auto basis-full md:basis-1/2 lg:basis-1/3 lg:grid justify-items-end font-[oxanium] text-orange-900"
            >
              <p>Ksh {{ formatNumber(Number(assetFinancingData.deposit)) }}</p>
              <p>Ksh {{ formatNumber(assetFinancingData.monthlyPayment) }}</p>
              <p>{{ assetFinancingData.monthsTermLength }} Months</p>
              <p>Ksh {{ formatNumber(vehiclePrice) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Quote Section -->
    <div class="basis-full flex-auto lg:basis-8/12 px-12">
      <div class="container mx-auto my-10">
        <div class="flex justify-between mb-10">
          <p class="font-[oxanium] font-semibold">Final Quote</p>
          <div class="flex">
            <font-awesome-icon
              :icon="['fas', 'cloud-arrow-down']"
              class="text-slate-600 mr-2 hover:text-orange-300"
            />
            <p
              class="font-[Oxanium] font-light text-slate-600 text-sm hover:text-orange-300 cursor-pointer"
            >
              Download Quote
            </p>
          </div>
        </div>
        <div class="py-4 bg-orange-300">
          <p class="font-[oxanium] pl-3 font-semibold">ASSET FINANCING</p>
        </div>
        <!-- Asset Finance Section -->
        <div>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-8 mt-16">
            <!-- Right Section -->
            <div>
              <p class="font-[oxanium] text-3xl">
                Ksh {{ formatNumber(assetFinancingData.monthlyPayment) }}/Month
              </p>
              <div class="mb-24 mt-12">
                <div class="flex justify-between">
                  <label
                    for="default-range"
                    class="block mb-2 font-[sarabun] font-light text-gray-900 flex-auto"
                    >Cash Deposit</label
                  >
                  <p class="flex-auto font-[oxanium]">
                    Ksh
                    {{ formatNumber(Number(assetFinancingData.deposit)) }}/Month
                  </p>
                </div>
                <input
                  id="default-range"
                  type="range"
                  :value="assetFinancingData.deposit"
                  min="0"
                  :max="vehiclePrice"
                  disabled
                  class="w-11/12 h-2 rounded-lg accent-orange-400 cursor-pointer"
                />
              </div>
              <div class="mb-10 mt-16">
                <div class="flex justify-between">
                  <label
                    for="default-range"
                    class="block mb-2 font-[sarabun] font-light text-gray-900 flex-auto"
                    >Months</label
                  >
                  <p class="font-[oxanium] flex-auto">
                    {{ assetFinancingData.monthsTermLength }} Months
                  </p>
                </div>
                <input
                  id="default-range"
                  type="range"
                  :value="assetFinancingData.monthsTermLength"
                  min="0"
                  max="12"
                  disabled
                  class="w-11/12 h-2 bg-gray-200 accent-orange-400 rounded-lg cursor-pointer"
                />
              </div>
            </div>
            <!-- Left Section -->
            <div class="-mt-5">
              <DataTable class="" :tableData="vehicleFinancingArray()" />
            </div>
          </div>
          <div class="flex items-center my-6">
            <input
              required
              id="default-checkbox"
              type="checkbox"
              value=""
              class="w-6 h-6 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-0 accent-orange-400"
            />
            <span
              for="default-checkbox"
              class="font-[sarabun] ml-4 text-slate-600 w-full"
              >I agreed to the
              <label
                @click="openTermsModal"
                class="text-orange-400 hover:border-b border-orange-400 font-[oxanium]"
                >Terms & Conditions</label
              >
            </span>

            <!--Terms and Condition pop up -->
            <TransitionRoot appear :show="isTermsModalOpen" as="template">
              <Dialog as="div" @close="closeTermsModal()" class="relative z-10">
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="fixed inset-0 bg-black/25" />
                </TransitionChild>

                <div class="fixed inset-0 overflow-y-auto">
                  <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                  >
                    <TransitionChild
                      as="template"
                      enter="duration-300 ease-out"
                      enter-from="opacity-0 scale-95"
                      enter-to="opacity-100 scale-100"
                      leave="duration-200 ease-in"
                      leave-from="opacity-100 scale-100"
                      leave-to="opacity-0 scale-95"
                    >
                      <DialogPanel
                        class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                      >
                        <DialogTitle as="h3">
                          <div class="grid justify-items-center mb-5">
                            <p
                              class="text-lg font-medium text-gray-900 font-[oxanium]"
                            >
                              ASSET FINANCING TERMS & CONDITIONS
                            </p>
                          </div>
                        </DialogTitle>
                        <div class="mt-2">
                          <p
                            class="font-[sarabun] grid place-content-center overflow-auto"
                          >
                            Terms and Conditions for Asset Financing These terms
                            and conditions ("Terms") govern the use of asset
                            financing services provided by [Your Company Name]
                            ("Lender") to the borrower ("Borrower"). By availing
                            of the asset financing services, the Borrower agrees
                            to abide by these Terms. 1. Eligibility: To be
                            eligible for asset financing, the Borrower must meet
                            the following criteria: Must be a legal entity
                            registered and operating in accordance with the laws
                            of the jurisdiction. Must demonstrate
                            creditworthiness and financial stability. Must
                            provide necessary documentation as requested by the
                            Lender. 2. Asset Description: The Borrower shall
                            specify the asset(s) to be financed under this
                            agreement, including but not limited to: Description
                            of the asset(s) Quantity Manufacturer/model Purchase
                            price 3. Financing Amount: The Lender shall provide
                            financing for the purchase of the specified asset(s)
                            up to the agreed-upon financing amount. The
                            financing amount shall be determined based on the
                            purchase price of the asset(s) and other relevant
                            factors, subject to approval by the Lender. 4.
                            Interest Rate: The financing shall be subject to an
                            interest rate as agreed upon between the Lender and
                            the Borrower. The interest rate may vary based on
                            market conditions, creditworthiness of the Borrower,
                            and other factors determined by the Lender. 5.
                            Repayment Terms: The Borrower shall repay the
                            financed amount, including interest, in accordance
                            with the agreed-upon repayment schedule. Repayment
                            terms may include: Monthly installments
                            Interest-only payments Balloon payments Other
                            mutually agreed-upon terms 6. Security/Collateral:
                            The Borrower may be required to provide security or
                            collateral to secure the financing, as determined by
                            the Lender. Security or collateral may include:
                            Pledge of assets Personal/corporate guarantees
                            Mortgage on property Other forms of security
                            acceptable to the Lender 7. Fees and Charges: The
                            Borrower shall be responsible for paying any
                            applicable fees and charges associated with the
                            asset financing, including but not limited to: Loan
                            origination fees Processing fees Late payment fees
                            Prepayment penalties Other charges as specified by
                            the Lender 8. Default and Remedies: In the event of
                            default by the Borrower, the Lender may exercise its
                            rights and remedies as provided for in the financing
                            agreement, including but not limited to:
                            Acceleration of the loan Enforcement of
                            security/collateral Legal action to recover
                            outstanding amounts Other remedies available under
                            applicable law 9. Governing Law and Jurisdiction:
                            These Terms shall be governed by and construed in
                            accordance with the laws of [Jurisdiction]. Any
                            disputes arising under these Terms shall be subject
                            to the exclusive jurisdiction of the courts of
                            [Jurisdiction]. 10. Amendments: These Terms may be
                            amended or modified by mutual agreement between the
                            Lender and the Borrower. Any amendments shall be in
                            writing and duly signed by authorized
                            representatives of both parties. 11. Entire
                            Agreement: These Terms constitute the entire
                            agreement between the Lender and the Borrower with
                            respect to the subject matter herein and supersede
                            all prior agreements and understandings, whether
                            written or oral. By availing of the asset financing
                            services, the Borrower acknowledges that they have
                            read, understood, and agreed to abide by these
                            Terms.
                          </p>
                        </div>

                        <div class="mt-6 flex justify-center">
                          <button
                            type="button"
                            class="inline-flex justify-center rounded-md border border-transparent bg-orange-400 px-4 py-2 text-sm font-medium text-white"
                            @click="closeTermsModal"
                          >
                            Complete
                          </button>
                        </div>
                      </DialogPanel>
                    </TransitionChild>
                  </div>
                </div>
              </Dialog>
            </TransitionRoot>

            <!--Success pop up -->
            <TransitionRoot appear :show="isSuccessModalOpen" as="template">
              <Dialog as="div" @close="closeModal()" class="relative z-10">
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="fixed inset-0 bg-black/25" />
                </TransitionChild>

                <div class="fixed inset-0 overflow-y-auto">
                  <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                  >
                    <TransitionChild
                      as="template"
                      enter="duration-300 ease-out"
                      enter-from="opacity-0 scale-95"
                      enter-to="opacity-100 scale-100"
                      leave="duration-200 ease-in"
                      leave-from="opacity-100 scale-100"
                      leave-to="opacity-0 scale-95"
                    >
                      <DialogPanel
                        class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                      >
                        <DialogTitle as="h3">
                          <div class="grid justify-items-center mb-5">
                            <font-awesome-icon
                              :icon="['fas', 'circle-check']"
                              class="h-14 w-14 text-green-600 mb-3"
                            />
                            <p
                              class="text-lg font-medium text-gray-900 font-[oxanium]"
                            >
                              CHECK OUT SUCCESSFUL
                            </p>
                          </div>
                        </DialogTitle>
                        <div class="mt-2">
                          <p class="font-[sarabun] grid place-content-center">
                            Your order has been received successfully.
                          </p>
                          <p class="font-[sarabun] text-center">
                            Someone from our team will reach out in less than 24
                            hours.
                          </p>

                          <p class="font-[sarabun] grid place-content-center">
                            <br />Thank you for choosing Navi
                          </p>
                        </div>

                        <div class="mt-6 flex justify-center">
                          <button
                            type="button"
                            class="inline-flex justify-center rounded-md border border-transparent bg-orange-400 px-4 py-2 text-sm font-medium text-white"
                            @click="closeModal"
                          >
                            Complete
                          </button>
                        </div>
                      </DialogPanel>
                    </TransitionChild>
                  </div>
                </div>
              </Dialog>
            </TransitionRoot>
          </div>
          <div class="border-t"></div>
          <div class="grid justify-items-center">
            <Button
              v-if="!isFinanceUploadLoading"
              @click="uploadAssetFinancingDetails"
              label="Confirm Details"
              :filled="true"
              class="mt-6 font-[sarabun]"
            />
            <button
              v-else-if="isFinanceUploadLoading"
              disabled
              type="button"
              class="mt-6 bg-gray-400 font-medium font-[sarabun] px-5 py-2.5 text-center me-4 w-full md:w-fit"
            >
              <div class="flex content-center">
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 me-3 text-gray-500 animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#fb923c"
                  />
                </svg>
                <p class="text-white -mt-1">Uploading...</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
</script>
  <script>
import DataTable from "@/components/VehicleFinancingDataTable.vue";
import Button from "@/components/Button.vue";
import DataRepository from "@/infrastructure/services/DataRepository";

export default {
  components: {
    DataTable,
    Button,
  },
  data() {
    return {
      term: 0,
      cashDeposit: 0,
      interestRate: 0,
      vehiclePrice: this.$store.state.selectedVehicle.price,
      assetFinancingData: this.$store.state.assetFinancingData,
      isSuccessPopUpOpen: false,
      isSuccessModalOpen: false,
      isTermsModalOpen: false,
      financeUploadError: false,
      isFinanceUploadLoading: false,
    };
  },
  methods: {
    formatNumber(number) {
      if (number) {
        return number.toLocaleString();
      } else {
        return 0;
      }
    },
    vehicleFinancingArray() {
      return [
        ["Months", this.assetFinancingData.monthsTermLength],
        ["Vehicle Price", `Ksh ${this.formatNumber(this.vehiclePrice)}`],
        [
          "Deposit",
          `Ksh ${this.formatNumber(this.assetFinancingData.deposit)}`,
        ],
        [
          "Finance Amount",
          `Ksh ${this.formatNumber(this.assetFinancingData.financeAmount)}`,
        ],
        [
          "Total Interest",
          `Ksh ${this.formatNumber(this.assetFinancingData.totalInterest)}`,
        ],
        [
          "Total Repayable Amount",
          `Ksh ${this.formatNumber(
            this.assetFinancingData.totalRepayableAmount
          )}`,
        ],
        [
          "Monthly Payments",
          `Ksh ${this.formatNumber(this.assetFinancingData.monthlyPayment)}`,
        ],
        ["Interest Rate", `${this.assetFinancingData.interestRate}%`],
      ];
    },
    assetFinanceCheckout() {
      setTimeout(() => {}, 1000);
    },
    closeModal() {
      this.isSuccessModalOpen = false;
      this.$router.push("/find-vehicles");
    },
    openModal() {
      this.isSuccessModalOpen = true;
    },
    closeTermsModal() {
      this.isTermsModalOpen = false;
    },
    openTermsModal() {
      this.isTermsModalOpen = true;
    },
    async uploadAssetFinancingDetails() {
      const assetFinanceOrderDetailsArray = {
        total_order_items: 1,
        total_price: this.vehiclePrice,
        user_id: this.$store.state.user.id,
        finance_deposit: this.assetFinancingData.deposit,
        finance_term_length: this.assetFinancingData.monthsTermLength,
        finance_interest_rate: this.assetFinancingData.interestRate,
        order_items: [
          {
            quantity: 1,
            cash: false,
            total: this.vehiclePrice,
            orderStatus: "Processing",
            vehicleId: this.$store.state.selectedVehicle.id,
            tradeIn: this.$store.state.tradeInVehicleData
              ? this.$store.state.tradeInVehicleData.id
              : null,
          },
        ],
      };

      this.isFinanceUploadLoading = true;

      const uploadAssetFinanceVehicleResponse = await DataRepository.postVehicleOrder(
        "create_vehicle_order_procedure",
        assetFinanceOrderDetailsArray
      );

      if (uploadAssetFinanceVehicleResponse.success && !uploadAssetFinanceVehicleResponse.error) {
        this.openModal();
      } else {
        this.financeUploadError = uploadAssetFinanceVehicleResponse.error
          ? uploadAssetFinanceVehicleResponse.error.message
          : "An error occurred while uploading your order. Please try again";
      }
      this.isFinanceUploadLoading = false;

    },
  },
  created() {
    if (Object.keys(this.$store.state.selectedVehicle).length <= 0) {
      this.$router.go(-1);
    }
  },
};
</script>
  