export default class IDataService {
    constructor() {
        if(this.constructor === IDataService) {
            throw new TypeError("Abstract class IDataService cannot be Instantiated directly.")
        }
    }

    async login(...args) {
        throw new Error('Method "login({email, password})" should be implemented')
    }

    async register(...args) {
        throw new Error('Method "register({email, password, phoneNumber})" should be implemented')
    }

    async forgotPassword(...args) {
        throw new Error('Method "forgotPassword" should be implemented')
    }

    async updateUserDetails(...args) {
        throw new Error('Method "updateUserDetails" should be implemented')
    }

    async deleteUser(...args) {
        throw new Error('Method "deleteUser" should be implemented')
    }

    async signOut() {
        throw new Error('Method "signOut" should be implemented')
    }

    async getUser(...args) {
        throw new Error('Method "getUser" should be implemented')
    }

    async getUserSession() {
        throw new Error('Method "getUserSession" should be implemented')
    }

    async postData(...args) {
        throw new Error('Method "postData" should be implemented')
    }

    async fetchData(...args) {
        throw new Error('Method "fetchData" should be implemented')
    }

    async patchData(...args) {
        throw new Error('Method "patchData" should be implemented')
    }

    async deleteData(...args) {
        throw new Error('Method "deleteData" should be implemented')
    }

    async rpc(...args){
        throw new Error('Method "rpc" should be implemented')
    }
}