<template>
  <div class="container mx-auto max-w-7xl h-screen mt-3 bg-white z-0">
    <TabGroup vertical>
      <div class="flex space-x-1">
        <TabList
          class="flex flex-col basis-1/5 items-center h-screen bg-white shadow pb-4 pt-16"
        >
          <div>
            <Tab
              v-for="(menuItem, index) in profileMenuArray"
              :key="index"
              v-slot="{ selected }"
              @click="tabClicked(index)"
              class="outline-0 w-full text-start px-3 whitespace-nowrap"
            >
              <p
                :class="{
                  'w-full mb-6 font-[oxanium] text-black bg-orange-100 rounded-lg py-2 px-6':
                    selected,
                  'w-full text-gray-400 ml-6 hover:ml-0 hover:bg-orange-50 hover:text-gray-400 hover:py-2 hover:px-6 hover:rounded-lg':
                    !selected,
                }"
                class="w-full mb-6 font-[oxanium]"
              >
                {{ menuItem }}
              </p>
            </Tab>
          </div>
        </TabList>
        <TabPanels class="basis-4/5 h-screen px-8 flex">
          <!-- General profile panel -->
          <TabPanel class="mt-12 overflow-y-auto">
            <GeneralProfileComponent :User="user" />
          </TabPanel>
          <!-- Order history panel -->
          <TabPanel>
            <div class="mt-12 overflow-y-auto">
              <p class="font-[oxanium] text-lg font-medium">Order History</p>
              <p class="font-[sarabun] text-gray-500 mb-6">
                Track all your previous orders
              </p>
              <div v-for="(vehicleOrder, index) in vehicleOrders" :key="index">
                <OrderHistoryItemComponent
                  class="shadow mb-4"
                  :orderItem="vehicleOrder"
                />
                <OrderHistoryItemComponent
                  class="shadow mb-4"
                  :orderItem="vehicleOrder"
                />
              </div>
            </div>
          </TabPanel>
          <!-- Sell Requests Panel -->
          <TabPanel>
            <div class="mt-12">
              <p class="font-[oxanium] text-lg font-medium">Sell Requests</p>
              <p class="font-[sarabun] text-gray-500 mb-6">
                Track all requests to sell your vehicle to us
              </p>
              <SellRequestsCardComponent :userSellOrders="vehicleSellOrders"/>
              <!-- <div
                v-for="(vehicleSellOrder, index) in vehicleSellOrders"
                :key="index"
                class="grid gap-y-2"
              >
                <OrderHistorySubItemComponent class="shadow" :vehicleSellOrderObject = "vehicleSellOrder"/>
              </div> -->
            </div>
          </TabPanel>
        </TabPanels>
      </div>
    </TabGroup>
  </div>
</template>

<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
</script>

<script>
import OrderHistorySubItemComponent from "../components/OrderHistoryListSubItem.vue";
import OrderHistoryItemComponent from "../components/OrderHistoryListItem.vue";
import GeneralProfileComponent from "../components/GeneralProfile.vue";
import SellRequestsCardComponent from "../components/SellRequestsCard.vue";
import ApiState from "@/model/ApiState";
import DataRepository from "@/infrastructure/services/DataRepository";

export default {
  components: {
    OrderHistoryItemComponent,
    OrderHistorySubItemComponent,
    GeneralProfileComponent,
    SellRequestsCardComponent,
  },
  data() {
    return {
      profileMenuArray: ["General", "Order History", "Sell Requests"],
      vehicleOrdersApiState: new ApiState(),
      vehicleSellApiState: new ApiState(),
      vehicleOrders: null,
      vehicleSellOrders: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
  },
  methods: {
    async getUserVehicleOrders() {
      this.vehicleOrdersApiState = await DataRepository.fetchUserVehicleOrders(this.user.id);
      if (this.vehicleOrdersApiState.success) {
        this.vehicleOrders = this.vehicleOrdersApiState.success;
      } else {
        console.log(`failed`);
      }
    },
    async getUserVehicleSellOrders() {
      this.vehicleSellApiState = await DataRepository.fetchUserVehicleSellOrders(
        this.user.id
      );
      if (this.vehicleSellApiState.success) {
        this.vehicleSellOrders = this.vehicleSellApiState.success;
      } else {
        console.log(`failed`);
      }
    },
    tabClicked(clickedMenuItemIndex) {
      if (clickedMenuItemIndex === 1) {
        this.getUserVehicleOrders();
      } else if (clickedMenuItemIndex === 2) {
        this.getUserVehicleSellOrders();
      }
    },
    async updateUserDetails(userDetailsJson){
      return await DataRepository.updateUserDetails(userDetailsJson)
    }
  },
};
</script>
