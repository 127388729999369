<template>
    <div class="px-4 py-6">
      <h2 class="text-2xl font-semibold mb-4">Specs</h2>
      <hr>
      <div class="flex items-center mt-2 space-x-4">
        <div class="w-6 h-6 bg-gray-300 rounded-full"></div>
        <div class="w-6 h-6 bg-gray-300 rounded-full"></div>
        <div class="w-6 h-6 bg-gray-300 rounded-full"></div>
        <div class="w-6 h-6 bg-gray-300 rounded-full"></div>
      </div>
      <input
        class="mt-4 w-1/2 border-gray-300 rounded-md px-4 py-2"
        type="text"
        placeholder="Enter specs"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: 'Specs',
    props: {
      vehicleSpecsDataArray:{
        type: Object,
        required: true,
        default: []
      }
    }
  }
  </script>