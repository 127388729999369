<template>
  <div class="flex flex-col w-full">
    <div >
      <div>
        <img
          @click="openModal"
          :src="currentImage ? currentImage : images[0]"
          alt="Preview Image"
          class="h-[50vh] w-full object-cover"
        />
      </div>
      <carousel
        :wrap-around="true"
        v-bind="settings"
        :breakpoints="breakpoints"
      >
        <slide v-for="(slide, index) in images" :key="index" class="mx-1 mt-6">
          <div
            class="bg-white w-auto"
          >
            <img
              :src="slide"
              alt="preview image"
              class="object-cover rounded h-24 w-24"
              @click="currentImage = images[index]"
            />
          </div>
        </slide>
        <template #addons>
          <navigation  />
          <pagination />
        </template>
      </carousel>
    </div>

    <!-- Show Image -->
    <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/75" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="transform overflow-hidden rounded-xl  text-left align-middle shadow-xl transition-all">
            <img
              @click="closeModal"
              :src="currentImage ? currentImage : images[0]"
              alt="Preview Image"
              class="md:h-[80vh] w-full object-cover"/>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  </div>
</template>

<script setup>
import { ref } from 'vue'
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'

const isOpen = ref(false)

function closeModal() {
  isOpen.value = false
}
function openModal() {
  isOpen.value = true
}
</script>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      imageUrl: null,
      currentImage: null,
      settings: {
        itemsToShow: 4,
        snapAlign: "center",
      },
      breakpoints: {
        // 700px and up
        500: {
          itemsToShow: 4.8,
          snapAlign: "center",
        },

        700: {
          itemsToShow: 7,
          snapAlign: "center",
        },
        900: {
          itemsToShow: 7.9,
          snapAlign: "center",
        },

        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
        1500: {
          itemsToShow: 7,
          snapAlign: "start",
        },
      },
    };
  },
  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Check if the file is an image
      if (!file.type.match("image.*")) {
        alert("Please select an image file.");
        return;
      }

      // Read the file and display the image
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      reader.readAsDataURL(file);
    },
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
};
</script>
