<template>
    <div>
      <div class="flex absolute inset-0 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1.5px,transparent_1px)] [background-size:16px_16px] ">
        <!-- Right Section -->
        <div class="flex px-6 items-center basis-full ">
          <div class="md:border md:border-gray-200 container mx-auto rounded-lg w-full max-w-xl md:shadow-xl  md:backdrop-blur-sm md:p-6">
            <p class="font-sans font-semibold text-3xl py-6 px-6">Sign Up</p>
            <form class="py-6 px-6" @submit.prevent="registerUser">
              
              <div class="mb-5">
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email Address</label>
                <input
                  type="email"
                  id="email"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                  placeholder="e.g. johndoe@email.com"
                  required
                />
              </div>
                
              <div class="mb-5">
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">Password</label>
                <input
                  type="password"
                  id="password"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:border-orange-400 focus:ring-orange-400 block w-full p-2.5"
                  placeholder="*******"
                  required
                />
              </div>
              <div class="mb-5">
                <label for="repeat-password" class="block mb-2 text-sm font-medium text-gray-900">Repeat password</label>
                <input
                  type="password"
                  id="repeat-password"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:border-orange-400 focus:ring-orange-400 block w-full p-2.5"
                  placeholder="*******"
                  required
                />
              </div>
              <div class="flex items-start mb-5">
                <div class="flex items-center h-5">
                  <input
                    id="terms"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-orange-400 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-orange-400"
                    required
                  />
                </div>
                <label for="terms" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <a href="#" class="text-orange-400 hover:underline dark:text-blue-500">terms and conditions</a></label>
              </div>
              <div class="grid justify-items-center mt-12 mb-5">
                <button
                  type="submit"
                  class="text-white bg-orange-400 font-medium text-sm px-5 py-2.5 text-center w-full"
                >
                <span class="">Register Account</span>
                  <div
                  v-show="loading"
                    class="ml-5 inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                    role="status"
                  >
                    <span
                      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                      >Loading...</span
                    >
                  </div>
                </button>
              </div>
              <div class="flex w-full items-center mb-5">
                <div class="border-t flex-1"></div>
                <div class="border rounded-full p-3 text-black font-[sarabun] font-semibold">OR</div>
                <div class="border-t flex-1"></div>
              </div>
              <div class="flex w-full justify-center">
                <GoogleLogin :callback="signUpUserWithGoogleOAuth" :button-config="{ text: 'signup_with', shape:'circle'}"/>
              </div>
              <div class="flex flex-row justify-center mt-5">
                <label for="terms" class="font-sans ms-2 text-slate-500">Already have an account?</label>
                <RouterLink to="/login">
                  <p class="text-orange-400 hover:underline ml-3">Log In</p>
                </RouterLink>
              </div>
            </form>
          </div>
        </div>
      </div>
  
      <!-- Login Fail Alert -->
      <div v-show="apiState.error"  id="alert-2" class="absolute right-0 w-72 flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50  dark:text-red-400" role="alert">
          <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span class="sr-only">Info</span>
          <div class="ms-3 text-sm font-medium ">
            {{ apiState.error  }}
          </div>
          <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400" data-dismiss-target="#alert-2" aria-label="Close">
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
        </div>
  
        <!-- Login Success Alert -->
        <div v-show="apiState.success && apiState.error === null"  id="alert-3" class="absolute right-0 w-72 flex items-center p-4 mb-4  text-green-800 rounded-lg bg-green-50  border border-r-0 border-green-500 dark:text-green-400" role="alert">
          <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span class="sr-only">Info</span>
          <div class="ms-3 text-sm font-medium">
            Registration Successful
          </div>
          <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  dark:text-green-400" data-dismiss-target="#alert-3" aria-label="Close">
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
        </div>  
    </div>
  </template>
  
  <script setup>
  import { watch } from 'vue'  
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
import ApiState from '@/model/ApiState';
import DataRepository from '@/infrastructure/services/DataRepository';
  
  const store = useStore()
  const router = useRouter()
  
  watch(
    () => store.state.apiState,
    async (newValue) => {
      if(newValue.success) {
        await sleep(500)
        store.commit("resetAPIState")
        router.push("/login")
      } else if(newValue.error) {
        await sleep(4000)
        store.commit("resetAPIState")
      }
    }
  )
  </script>
  
  
    
  <script>
  
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  function* range(start, end) {
    yield start;
    if (start === end) return;
    yield* range(start + 1, end);
  }
  
  export default {
    data() {
      return {
        loading: false,
        apiState: new ApiState()
      }
    }, 
    methods: {
      async registerUser() {
        this.loading = true
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        const repeatPassword = document.getElementById('repeat-password').value;
  
        if (!password || !repeatPassword) {
          this.loading = false
          alert('Please provide both password fields');
          return;
        }
  
        if (password !== repeatPassword) {
          this.apiState  = new ApiState({error: 'Passwords do not match'})
          this.loading = false
          return;
        }
  
        this.apiState = await DataRepository.registerUser(email, password)
        this.loading = false
      },
      clearInputFields() {
        document.getElementById('email').value = '';
        document.getElementById('password').value = '';
        document.getElementById('repeat-password').value = '';
      },
      async signUpUserWithGoogleOAuth(response){
      this.loading = true;
      this.apiState = await DataRepository.signInWithGoogle(response.credential)
      this.loading = false;
    }
    },
    watch: {
      async apiState(newVal, oldVal) {
        if(newVal.error == null) {
          await sleep(1000)
          this.$router.push('/login')
        }
      }
    }
  };
  
  
  </script>
    
    