<template>
  <div class="bg-white">
    <div class="flex container mx-auto max-w-7xl">
      <!-- Filter Section -->
      <div class="hidden md:block w-64 shrink-0 mt-2 h-fit shadow">
        <div class="h-full px-6 pb-4 overflow-y-auto bg-white">
          <div class="bg-orange-400 py-3 -mx-6 flex justify-center mb-6">
            <p class="font-[oxanium] text-xl text-white">SEARCH VEHICLES</p>
          </div>

          <p
            v-if="filters.length > 0"
            class="flex justify-end mb-2 text-orange-400 cursor-pointer"
            @click="clearFilters"
          >
            Clear Filters
          </p>
          <ul class="space-y-2">
            <!-- Brand -->
            <div>
              <Listbox
                v-model="selectedBrand"
                class="border px-4 py-2.5"
                @click="brandItemClick"
              >
                <div class="relative">
                  <ListboxButton
                    class="relative w-full cursor-default bg-white text-left"
                  >
                    <span
                      v-if="selectedBrand"
                      class="font-[sarabun] block truncate"
                      >{{ selectedBrand.name }}</span
                    >
                    <span
                      v-else
                      class="font-[sarabun] block truncate text-gray-400"
                      >Select Brand</span
                    >
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      @click="fetchBrandModels"
                      class="absolute mt-1 -ml-4 max-h-60 w-full overflow-auto bg-white py-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none z-10"
                    >
                      <div
                        v-if="isBrandLoading"
                        class="flex justify-center py-3"
                      >
                        <svg
                          aria-hidden="true"
                          class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                      <div
                        v-else-if="brandErrorMessage"
                        class="flex justify-center py-3"
                      >
                        <p class="font-[sarabun]">{{ brandErrorMessage }}</p>
                      </div>
                      <ListboxOption
                        v-else
                        v-slot="{ active, selected }"
                        v-for="(brand, index) in brandsList"
                        :key="index"
                        :value="brand"
                        as="template"
                      >
                        <li
                          :class="[
                            active
                              ? 'bg-orange-100 text-amber-900'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-10 pr-4',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-3xl' : 'font-normal',
                              'block truncate',
                            ]"
                            class="font-[sarabun]"
                            >{{ brand.name }}</span
                          >
                          <span
                            v-if="selected"
                            class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
            <!-- Model -->
            <div>
              <Listbox v-model="selectedModel" class="border px-4 py-2.5">
                <div class="relative">
                  <ListboxButton
                    class="relative w-full cursor-default bg-white text-left"
                  >
                    <span
                      v-if="selectedModel"
                      class="font-[sarabun] block truncate"
                      >{{ selectedModel.model }}</span
                    >
                    <span
                      v-else
                      class="font-[sarabun] block truncate text-gray-400"
                      >Select Vehicle Model</span
                    >
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      @click="brandModelItemClicked"
                      class="absolute mt-1 -ml-4 max-h-60 w-full overflow-auto bg-white py-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none z-10"
                    >
                      <div
                        v-if="isBrandModelLoading"
                        class="flex justify-center py-3"
                      >
                        <svg
                          aria-hidden="true"
                          class="w-8 h-8 text-gray-200 animate-spin fill-orange-400"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                      <div
                        v-else-if="getBrandModelsError"
                        class="flex justify-center py-3"
                      >
                        <p class="font-[sarabun]">{{ getBrandModelsError }}</p>
                      </div>
                      <div
                        v-else-if="brandModelsList.length <= 0"
                        class="flex justify-center py-3"
                      >
                        <p class="font-[sarabun] font-light">
                          Select vehicle brand
                        </p>
                      </div>
                      <ListboxOption
                        v-else
                        v-slot="{ active, selected }"
                        v-for="(model, index) in brandModelsList"
                        :key="index"
                        :value="model"
                        as="template"
                      >
                        <li
                          :class="[
                            active
                              ? 'bg-orange-100 text-amber-900'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-10 pr-4',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                            ]"
                            class="font-[sarabun]"
                            >{{ model.model }}</span
                          >
                          <span
                            v-if="selected"
                            class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
            <!-- Condition -->
            <li>
              <!-- <Button class="w-full" data-dropdown-toggle="condition-dropdown">
                  <div
                    class="flex justify-between items-center border px-4 w-full py-2.5 text-gray-400"
                  >
                    <p>Condition</p>
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                  </div>
                </Button> -->
              <!-- Condition Dropdown menu -->
              <!-- <div
                  id="condition-dropdown"
                  class="w-full grid justify-items-start z-10 hidden mr-10"
                >
                  <div class="z-10 bg-gray-100 shadow w-auto -mt-2">
                    <ul
                      class="font-sans py-2 text-gray-700"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <li>
                        <p class="hover:bg-orange-400 px-4 py-2 block">New</p>
                      </li>
                      <li>
                        <p class="hover:bg-orange-400 px-4 py-2 block">Used</p>
                      </li>
                    </ul>
                  </div>
                </div> -->
            </li>
            <!-- Transmission -->
            <li>
              <div class="border-t my-5"></div>
              <DropDownComponent title="TRANSMISSION">
                <!-- Transmission Drop down -->
                <ul class="py-2 space-y-2 mt-2">
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        name="transmission"
                        v-model="isManualTransmissionChecked"
                        value="any"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                        :checked="isTransmissionAnyChecked"
                      />
                      <label for="default-checkbox" class="font-[sarabun] ms-2"
                        >Any</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        name="transmission"
                        v-model="isManualTransmissionChecked"
                        value="manual"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="font-[sarabun] ms-2"
                        >Manual</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        name="transmission"
                        value="automatic"
                        v-model="isManualTransmissionChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Automatic</label
                      >
                    </div>
                  </li>
                </ul>
              </DropDownComponent>
              <div class="border-t my-5"></div>
            </li>
            <!-- Fuel Type -->
            <li>
              <DropDownComponent title="FUEL TYPE">
                <!-- Fuel Type Drop down -->
                <ul id="fuel-type-dropdown" class="py-2 space-y-2 mt-2">
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        v-model="isPetrolChecked"
                        value="any"
                        :checked="isFuelNotSelected"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Any</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        v-model="isPetrolChecked"
                        value="petrol"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Petrol</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        v-model="isPetrolChecked"
                        value="diesel"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Diesel</label
                      >
                    </div>
                  </li>
                </ul>
              </DropDownComponent>

              <div class="border-t my-5"></div>
            </li>
            <!-- Price Range -->
            <li>
              <DropDownComponent title="PRICE">
                <!-- Price Drop down -->
                <div>
                  <label>Min</label>
                  <div
                    class="flex items-center border border-black mt-1.5 w-full focus:border-orange-400 focus:ring-0"
                  >
                    <span class="text-gray-500 mx-1">KES</span>
                    <input
                      class="w-full border-0 focus:ring-0"
                      type="number"
                      v-model="minPrice"
                      placeholder="Enter min price"
                    />
                  </div>
                  <p class="flex justify-center my-2">To</p>
                  <label>Max</label>
                  <div
                    class="flex items-center border border-black mt-1.5 w-full focus:border-orange-400 focus:ring-0"
                  >
                    <span class="text-gray-500 mx-1">KES</span>
                    <input
                      class="w-full border-0 focus:ring-0"
                      type="number"
                      v-model="maxPrice"
                      placeholder="Enter max price"
                    />
                  </div>
                  <p
                    v-if="priceFilterError"
                    class="text-red-400 font-[sarabun] text-sm mt-3"
                  >
                    {{ priceFilterError }}
                  </p>
                  <Button
                    @click="filterByPrice"
                    type="submit"
                    class="w-full bg-orange-300 py-1.5 mt-3 rounded-md text-white"
                  >
                    Filter
                  </Button>
                </div>
              </DropDownComponent>
              <div class="border-t my-5"></div>
            </li>
            <!-- YEAR -->
            <li>
              <DropDownComponent title="YEAR OF MANUFACTURE">
                <!-- Year Drop down -->
                <ul id="year-dropdown" class="py-2 space-y-2 mt-2">
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="any"
                        v-model="isYearChecked"
                        :checked="isYearNotSelected"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Any</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="1"
                        v-model="isYearChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Up to 1 year old</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="2"
                        v-model="isYearChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Up to 2 years old</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="3"
                        v-model="isYearChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Up to 3 years old</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="4"
                        v-model="isYearChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Up to 4 years old</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="5"
                        v-model="isYearChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Up to 5 years old</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="6"
                        v-model="isYearChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Over 5 years old</label
                      >
                    </div>
                  </li>
                </ul>
              </DropDownComponent>
              <div class="border-t my-5"></div>
            </li>
            <!-- Mileage -->
            <li>
              <DropDownComponent title="MILEAGE">
                <!-- Mileage Drop down -->
                <ul id="mileage-dropdown" class="py-2 space-y-2 mt-2">
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="any"
                        name="mileage"
                        v-model="mileageChecked"
                        :checked="isMileageNotSelected"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Any</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="0-50000"
                        name="mileage"
                        v-model="mileageChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >0 km - 50,000 km</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="50001-100000"
                        name="mileage"
                        v-model="mileageChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >50,001 km - 100,000 km</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="100001-150000"
                        name="mileage"
                        v-model="mileageChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >100,001 km - 150,000 km</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="150001-200000"
                        name="mileage"
                        v-model="mileageChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >150,001 km - 200,000 km</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="<200001"
                        name="mileage"
                        v-model="mileageChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Over 200,000 km</label
                      >
                    </div>
                  </li>
                </ul>
              </DropDownComponent>

              <div class="border-t my-5"></div>
            </li>
            <!-- Vehicle Body Type -->
            <li>
              <DropDownComponent title="BODY TYPE">
                <!-- Vehicle Body Type Drop down -->
                <ul class="py-2 space-y-2 mt-2">
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="any"
                        name="body-type"
                        v-model="isBodyTypeChecked"
                        :checked="isBodyTypeNotSelected"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Any</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="Tipper"
                        name="body-type"
                        v-model="isBodyTypeChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Tipper</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="Flatbed"
                        name="body-type"
                        v-model="isBodyTypeChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Flatbed</label
                      >
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="radio"
                        value="Tanker"
                        name="body-type"
                        v-model="isBodyTypeChecked"
                        class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                      />
                      <label for="default-checkbox" class="ms-2 font-[sarabun]"
                        >Tanker</label
                      >
                    </div>
                  </li>
                </ul>
              </DropDownComponent>
              <div class="border-t my-5"></div>
            </li>
          </ul>
        </div>
      </div>

      <div class="py-8 h-screen">
        <div class="p-4 -mt-6 md:mt-0">
          <!-- Mobile filter toggle -->
          <label
            @click="mobileFilterMenuOpen = true"
            class="md:hidden grid justify-center mb-6 border border-orange-400 text-white font-[oxanium] py-1.5 bg-orange-400"
            >FILTER VEHICLES</label
          >
          <!-- Mobile filter dialog -->
          <Dialog
            as="div"
            class="lg:hidden"
            @close="mobileFilterMenuOpen = false"
            :open="mobileFilterMenuOpen"
          >
            <div class="fixed inset-0 z-10" />
            <DialogPanel
              class="fixed inset-y-0 right-0 z-50 w-10/12 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm"
            >
              <div class="h-full">
                <div class="flex justify-between">
                  <label class="font-[oxanium] grid">SEARCH VEHICLES</label>
                  <font-awesome-icon
                    :icon="['fas', 'xmark']"
                    @click="mobileFilterMenuOpen = false"
                  />
                </div>
                <div class="border-t my-3"></div>
                <ul class="space-y-2">
                  <!-- Brand -->
                  <div>
                    <Listbox
                      v-model="selectedBrand"
                      class="border px-4 py-2.5"
                      @click="brandItemClick"
                    >
                      <div class="relative">
                        <ListboxButton
                          class="relative w-full cursor-default bg-white text-left"
                        >
                          <span
                            v-if="selectedBrand"
                            class="font-[sarabun] block truncate"
                            >{{ selectedBrand.name }}</span
                          >
                          <span
                            v-else
                            class="font-[sarabun] block truncate text-gray-400"
                            >Select Brand</span
                          >
                          <span
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                          >
                            <ChevronDownIcon
                              class="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </ListboxButton>

                        <transition
                          leave-active-class="transition duration-100 ease-in"
                          leave-from-class="opacity-100"
                          leave-to-class="opacity-0"
                        >
                          <ListboxOptions
                            @click="fetchBrandModels"
                            class="absolute mt-1 -ml-4 max-h-60 w-full overflow-auto bg-white py-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none z-10"
                          >
                            <div
                              v-if="isBrandLoading"
                              class="flex justify-center py-3"
                            >
                              <svg
                                aria-hidden="true"
                                class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            </div>
                            <div
                              v-else-if="brandErrorMessage"
                              class="flex justify-center py-3"
                            >
                              <p class="font-[sarabun]">
                                {{ brandErrorMessage }}
                              </p>
                            </div>
                            <ListboxOption
                              v-else
                              v-slot="{ active, selected }"
                              v-for="(brand, index) in brandsList"
                              :key="index"
                              :value="brand"
                              as="template"
                            >
                              <li
                                :class="[
                                  active
                                    ? 'bg-orange-100 text-amber-900'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-10 pr-4',
                                ]"
                              >
                                <span
                                  :class="[
                                    selected ? 'font-3xl' : 'font-normal',
                                    'block truncate',
                                  ]"
                                  class="font-[sarabun]"
                                  >{{ brand.name }}</span
                                >
                                <span
                                  v-if="selected"
                                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                                >
                                  <CheckIcon
                                    class="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              </li>
                            </ListboxOption>
                          </ListboxOptions>
                        </transition>
                      </div>
                    </Listbox>
                  </div>
                  <!-- Model -->
                  <div>
                    <Listbox v-model="selectedModel" class="border px-4 py-2.5">
                      <div class="relative">
                        <ListboxButton
                          class="relative w-full cursor-default bg-white text-left"
                        >
                          <span
                            v-if="selectedModel"
                            class="font-[sarabun] block truncate"
                            >{{ selectedModel.model }}</span
                          >
                          <span
                            v-else
                            class="font-[sarabun] block truncate text-gray-400"
                            >Select Vehicle Model</span
                          >
                          <span
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                          >
                            <ChevronDownIcon
                              class="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </ListboxButton>

                        <transition
                          leave-active-class="transition duration-100 ease-in"
                          leave-from-class="opacity-100"
                          leave-to-class="opacity-0"
                        >
                          <ListboxOptions
                            @click="brandModelItemClicked"
                            class="absolute mt-1 -ml-4 max-h-60 w-full overflow-auto bg-white py-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none z-10"
                          >
                            <div
                              v-if="isBrandModelLoading"
                              class="flex justify-center py-3"
                            >
                              <svg
                                aria-hidden="true"
                                class="w-8 h-8 text-gray-200 animate-spin fill-orange-400"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            </div>
                            <div
                              v-else-if="getBrandModelsError"
                              class="flex justify-center py-3"
                            >
                              <p class="font-[sarabun]">
                                {{ getBrandModelsError }}
                              </p>
                            </div>
                            <div
                              v-else-if="brandModelsList.length <= 0"
                              class="flex justify-center py-3"
                            >
                              <p class="font-[sarabun] font-light">
                                Select vehicle brand
                              </p>
                            </div>
                            <ListboxOption
                              v-else
                              v-slot="{ active, selected }"
                              v-for="(model, index) in brandModelsList"
                              :key="index"
                              :value="model"
                              as="template"
                            >
                              <li
                                :class="[
                                  active
                                    ? 'bg-orange-100 text-amber-900'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-10 pr-4',
                                ]"
                              >
                                <span
                                  :class="[
                                    selected ? 'font-medium' : 'font-normal',
                                    'block truncate',
                                  ]"
                                  class="font-[sarabun]"
                                  >{{ model.model }}</span
                                >
                                <span
                                  v-if="selected"
                                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                                >
                                  <CheckIcon
                                    class="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              </li>
                            </ListboxOption>
                          </ListboxOptions>
                        </transition>
                      </div>
                    </Listbox>
                  </div>
                  <!-- Condition -->
                  <li>
                    <!-- <Button class="w-full" data-dropdown-toggle="condition-dropdown">
                  <div
                    class="flex justify-between items-center border px-4 w-full py-2.5 text-gray-400"
                  >
                    <p>Condition</p>
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                  </div>
                </Button> -->
                    <!-- Condition Dropdown menu -->
                    <!-- <div
                  id="condition-dropdown"
                  class="w-full grid justify-items-start z-10 hidden mr-10"
                >
                  <div class="z-10 bg-gray-100 shadow w-auto -mt-2">
                    <ul
                      class="font-sans py-2 text-gray-700"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <li>
                        <p class="hover:bg-orange-400 px-4 py-2 block">New</p>
                      </li>
                      <li>
                        <p class="hover:bg-orange-400 px-4 py-2 block">Used</p>
                      </li>
                    </ul>
                  </div>
                </div> -->
                  </li>
                  <!-- Transmission -->
                  <li>
                    <div class="border-t my-5"></div>
                    <DropDownComponent title="TRANSMISSION">
                      <!-- Transmission Drop down -->
                      <ul class="py-2 space-y-2 mt-2">
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              name="transmission"
                              v-model="isManualTransmissionChecked"
                              value="any"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                              checked="true"
                            />
                            <label
                              for="default-checkbox"
                              class="font-[sarabun] ms-2"
                              >Any</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              name="transmission"
                              v-model="isManualTransmissionChecked"
                              value="manual"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="font-[sarabun] ms-2"
                              >Manual</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              name="transmission"
                              value="automatic"
                              v-model="isManualTransmissionChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Automatic</label
                            >
                          </div>
                        </li>
                      </ul>
                    </DropDownComponent>
                    <div class="border-t my-5"></div>
                  </li>
                  <!-- Fuel Type -->
                  <li>
                    <DropDownComponent title="FUEL TYPE">
                      <!-- Fuel Type Drop down -->
                      <ul id="fuel-type-dropdown" class="py-2 space-y-2 mt-2">
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              v-model="isPetrolChecked"
                              value="any"
                              checked="true"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Any</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              v-model="isPetrolChecked"
                              value="petrol"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Petrol</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              v-model="isPetrolChecked"
                              value="diesel"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Diesel</label
                            >
                          </div>
                        </li>
                      </ul>
                    </DropDownComponent>

                    <div class="border-t my-5"></div>
                  </li>
                  <!-- Price Range -->
                  <li>
                    <DropDownComponent title="PRICE">
                      <!-- Price Drop down -->
                      <div>
                        <label>Min</label>
                        <div
                          class="flex items-center border border-black mt-1.5 w-full focus:border-orange-400 focus:ring-0"
                        >
                          <span class="text-gray-500 mx-1">KES</span>
                          <input
                            class="w-full border-0 focus:ring-0"
                            type="number"
                            v-model="minPrice"
                            placeholder="Enter min price"
                          />
                        </div>
                        <p class="flex justify-center my-2">To</p>
                        <label>Max</label>
                        <div
                          class="flex items-center border border-black mt-1.5 w-full focus:border-orange-400 focus:ring-0"
                        >
                          <span class="text-gray-500 mx-1">KES</span>
                          <input
                            class="w-full border-0 focus:ring-0"
                            type="number"
                            v-model="maxPrice"
                            placeholder="Enter max price"
                          />
                        </div>
                        <p
                          v-if="priceFilterError"
                          class="text-red-400 font-[sarabun] text-sm mt-3"
                        >
                          {{ priceFilterError }}
                        </p>
                        <Button
                          @click="filterByPrice"
                          type="submit"
                          class="w-full bg-orange-300 py-1.5 mt-3 rounded-md text-white"
                        >
                          Filter
                        </Button>
                      </div>
                    </DropDownComponent>
                    <div class="border-t my-5"></div>
                  </li>
                  <!-- YEAR -->
                  <li>
                    <DropDownComponent title="YEAR OF MANUFACTURE">
                      <!-- Year Drop down -->
                      <ul id="year-dropdown" class="py-2 space-y-2 mt-2">
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="any"
                              v-model="isYearChecked"
                              checked="true"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Any</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="1"
                              v-model="isYearChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Up to 1 year old</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="2"
                              v-model="isYearChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Up to 2 years old</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="3"
                              v-model="isYearChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Up to 3 years old</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="4"
                              v-model="isYearChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Up to 4 years old</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="5"
                              v-model="isYearChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Up to 5 years old</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="6"
                              v-model="isYearChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Over 5 years old</label
                            >
                          </div>
                        </li>
                      </ul>
                    </DropDownComponent>
                    <div class="border-t my-5"></div>
                  </li>
                  <!-- Mileage -->
                  <li>
                    <DropDownComponent title="MILEAGE">
                      <!-- Mileage Drop down -->
                      <ul id="mileage-dropdown" class="py-2 space-y-2 mt-2">
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="any"
                              v-model="mileageChecked"
                              checked="true"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Any</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="0-50000"
                              v-model="mileageChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >0 km - 50,000 km</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="50001-100000"
                              v-model="mileageChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >50,001 km - 100,000 km</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="100001-150000"
                              v-model="mileageChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >100,001 km - 150,000 km</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="150001-200000"
                              v-model="mileageChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >150,001 km - 200,000 km</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="<200001"
                              v-model="mileageChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Over 200,000 km</label
                            >
                          </div>
                        </li>
                      </ul>
                    </DropDownComponent>

                    <div class="border-t my-5"></div>
                  </li>
                  <!-- Vehicle Body Type -->
                  <li>
                    <DropDownComponent title="BODY TYPE">
                      <!-- Vehicle Body Type Drop down -->
                      <ul id="body-type-dropdown" class="py-2 space-y-2 mt-2">
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="any"
                              v-model="isBodyTypeChecked"
                              checked="true"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Any</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="Tipper"
                              v-model="isBodyTypeChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Tipper</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="Flatbed"
                              v-model="isBodyTypeChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Flatbed</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              value="Tanker"
                              v-model="isBodyTypeChecked"
                              class="w-5 h-5 text-orange-400 bg-gray-100 border-gray-300 focus:ring-0"
                            />
                            <label
                              for="default-checkbox"
                              class="ms-2 font-[sarabun]"
                              >Tanker</label
                            >
                          </div>
                        </li>
                      </ul>
                    </DropDownComponent>
                    <div class="border-t my-5"></div>
                  </li>
                </ul>
                <button
                  @click="mobileFilterMenuOpen = false"
                  class="bg-orange-300 rounded mb-12 text-white font-[oxanium] py-2 px-4"
                >
                  View {{ this.$store.state.vehiclesList.length }} Results
                </button>
              </div>
            </DialogPanel>
          </Dialog>
          <!-- main page headings -->
          <div
            class="flex flex-wrap justify-between space-x-3 items-center mb-10 pl-2"
          >
            <p
              v-if="trucksList.length > 0"
              class="font-[oxanium] font-light text-orange-950 text-xl md:text-3xl"
            >
              {{ trucksList.length }} Vehicles Available
            </p>
            <p
              v-else
              class="font-[oxanium] font-light text-orange-950 text-xl md:text-3xl"
            >
              No Vehicles Available
            </p>
            <!-- Sorting -->
            <div>
              <Listbox v-model="selectedSortCriteria">
                <div class="relative">
                  <ListboxButton
                    class="relative w-full cursor-default bg-white text-left"
                  >
                    <div
                      v-if="trucksList.length > 0"
                      class="font-[sarabun] md:text-lg flex flex-wrap"
                    >
                      <span class="mr-3">Sort By:</span>
                      <div class="flex items-center">
                        <font-awesome-icon
                          :icon="['fas', 'arrow-up-long']"
                          class="text-orange-400"
                        />
                        <font-awesome-icon
                          :icon="['fas', 'arrow-down-long']"
                          class="text-orange-400"
                        />
                        <label
                          v-if="selectedSortCriteria"
                          class="font-[sarabun] text-orange-400 ml-1.5 truncate overflow-hidden"
                          >{{ selectedSortCriteria.name }}</label
                        >
                        <label
                          v-else
                          class="font-[sarabun] text-orange-400 ml-1.5"
                          >Recommended</label
                        >
                      </div>
                    </div>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      @click="sortingCriteriaIsSelected"
                      class="absolute mt-1 -ml-4 max-h-60 w-fit overflow-auto bg-white py-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none z-10"
                    >
                      <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="(sortCriteria, index) in sortingList"
                        :key="index"
                        :value="sortCriteria"
                        as="template"
                      >
                        <li
                          :class="[
                            active
                              ? 'bg-orange-100 text-amber-900'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-10 pr-4',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-3xl' : 'font-normal',
                              'block truncate',
                            ]"
                            class="font-[sarabun]"
                            >{{ sortCriteria.name }}</span
                          >
                          <span
                            v-if="selected"
                            class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>
          <!-- Loaded vehicles -->
          <div
            v-if="!isLoading && !errorMessage"
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center gap-y-3 gap-x-3 mb-4"
          >
            <div v-for="truck in trucksList" :key="truck.id" class="w-full">
              <router-link :to="`/find-vehicles/${truck.id}`">
                <TruckListingCard :VehicleModel="truck" class="border" />
              </router-link>
            </div>
          </div>
          <!-- Loader -->
          <div
            v-else-if="isLoading && !errorMessage"
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center gap-y-3 gap-x-3 mb-4"
          >
            <div class="p-4 animate-pulse md:p-6">
              <div
                class="w-52 flex items-center justify-center h-48 mb-4 bg-gray-200"
              >
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path
                    d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"
                  />
                  <path
                    d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"
                  />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full w-24 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full w-20 mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full"></div>
            </div>
            <div class="p-4 animate-pulse md:p-6">
              <div
                class="w-52 flex items-center justify-center h-48 mb-4 bg-gray-200"
              >
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path
                    d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"
                  />
                  <path
                    d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"
                  />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full w-24 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full w-20 mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full"></div>
            </div>
            <div class="p-4 animate-pulse md:p-6">
              <div
                class="w-52 flex items-center justify-center h-48 mb-4 bg-gray-200"
              >
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path
                    d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"
                  />
                  <path
                    d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"
                  />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full w-24 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full w-20 mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full"></div>
            </div>
            <div class="p-4 animate-pulse md:p-6">
              <div
                class="w-52 flex items-center justify-center h-48 mb-4 bg-gray-200"
              >
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path
                    d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"
                  />
                  <path
                    d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"
                  />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full w-24 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full w-20 mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full"></div>
            </div>
          </div>
          <!-- Error message -->
          <div
            v-else-if="!isLoading && errorMessage"
            class="flex flex-col justify-center items-center"
          >
            <p class="font-sans font-light text-orange-950 text-2xl mb-4">
              {{ errorMessage }}
            </p>
            <ButtonComponent
              label="Reload"
              :filled="true"
              @click="$router.go()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script setup>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";

import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { ref } from "vue";
const mobileFilterMenuOpen = ref(false);
</script>
  
  <script>
import TruckListingCard from "../components/TruckListingCard.vue";
import ButtonComponent from "@/components/Button.vue";
import DropDownComponent from "@/components/DropDown.vue";
import ApiState from "@/model/ApiState";
import DataRepository from "@/infrastructure/services/DataRepository";

export default {
  components: {
    TruckListingCard,
    ButtonComponent,
    DropDownComponent,
  },
  data() {
    return {
      isLoading: false,
      isBrandLoading: false,
      isBrandModelLoading: false,
      isManualTransmissionChecked: false,
      isAutomaticTransmissionChecked: false,
      isPetrolChecked: false,
      isDieselChecked: false,
      isYearChecked: false,
      brandsList: [],
      brandModelsList: [],
      sortingList: [
        {
          name: "Newest First",
          column: "created_at",
          isAscending: false,
        },
        {
          name: "Oldest First",
          column: "created_at",
          isAscending: true,
        },
        {
          name: "Price(Low to High)",
          column: "price",
          isAscending: true,
        },
        {
          name: "Price(High to Low)",
          column: "price",
          isAscending: false,
        },
        {
          name: "Mileage(Low to High)",
          column: "mileage",
          isAscending: true,
        },
        {
          name: "Mileage(High to Low)",
          column: "mileage",
          isAscending: false,
        },
        {
          name: "Year of Registration(New to Old)",
          column: "registration_year",
          isAscending: false,
        },
        {
          name: "Year of Registration(Old to New)",
          column: "registration_year",
          isAscending: true,
        },
      ],
      errorMessage: null,
      brandErrorMessage: null,
      getBrandModelsError: null,
      selectedBrand: null,
      selectedModel: null,
      selectedSortCriteria: null,
      selectedYear: null,
      filters: [],
      homePageBrandFilter: null,
      homePageVehicleTypeFilter: null,
      maxPrice: 0,
      minPrice: 0,
      priceFilterError: null,
      fetchVehiclesApiState: new ApiState(),
      mileageChecked: false,
      isBodyTypeChecked: false,
      isBodyTypeNotSelected: true,
      isMileageNotSelected: true,
    };
  },
  watch: {
    isManualTransmissionChecked(newVal, oldVal) {
      if (newVal) {
        this.transmissionChecked(newVal);
      }
    },
    isYearChecked(newVal) {
      if (newVal) {
        const gteYearFilterIndex = this.getFilterObjectIndex(
          "registration_year",
          "gte"
        );
        const lteYearFilterIndex = this.getFilterObjectIndex(
          "registration_year",
          "lte"
        );
        if (gteYearFilterIndex !== -1) {
          this.deleteFilterObject(gteYearFilterIndex);
        }
        if (lteYearFilterIndex !== -1) {
          this.deleteFilterObject(lteYearFilterIndex);
        }
        if (newVal == "any") {
          this.filterVehicles(this.filters);
        } else {
          this.yearChecked(Number(newVal));
        }
      }
    },
    isPetrolChecked(newVal) {
      if (newVal) {
        const fuelTypeFilterIndex = this.getFilterObjectIndex("drive");
        switch (newVal) {
          case "any":
            this.deleteFilterObject(fuelTypeFilterIndex);
            break;
          default:
            if (fuelTypeFilterIndex !== -1) {
              this.filters[fuelTypeFilterIndex].value = newVal;
            } else {
              this.addFilterObject("drive", "ilike", newVal);
            }
            break;
        }

        this.filterVehicles(this.filters);
      }
    },
    mileageChecked(newVal) {
      if (newVal) {
        switch (newVal) {
          case "any":
            const fromMileageFilterIndex = this.getFilterObjectIndex(
              "mileage",
              "gte"
            );
            const toMileageFilterIndex = this.getFilterObjectIndex(
              "mileage",
              "lte"
            );

            if (toMileageFilterIndex !== -1) {
              this.deleteFilterObject(toMileageFilterIndex);
            }
            if (fromMileageFilterIndex !== -1) {
              this.deleteFilterObject(fromMileageFilterIndex);
            }

            this.filterVehicles(this.filters);

            break;
          default:
            if (newVal.split("-").length == 2) {
              const from = newVal.split("-")[0];
              const to = newVal.split("-")[1];
              this.filterByMileage(from, to);
            } else {
              this.filterByMileage(newVal.split("<")[1], null);
            }
            break;
        }
      }
    },
    isBodyTypeChecked(newVal) {
      if (newVal) {
        const bodyTypeFilterIndex = this.getFilterObjectIndex("body_type");
        switch (newVal) {
          case "any":
            this.deleteFilterObject(bodyTypeFilterIndex);
            break;
          default:
            if (bodyTypeFilterIndex !== -1) {
              this.filters[bodyTypeFilterIndex].value = newVal;
            } else {
              this.addFilterObject("body_type", "ilike", newVal);
            }
            break;
        }

        this.filterVehicles(this.filters);
      }
    },
    filters: {
      handler: function () {
        this.isBodyTypeNotSelected =
          this.getFilterObjectIndex("body_type") == -1;
        this.isTransmissionAnyChecked =
          this.getFilterObjectIndex("transmission") == -1;
        this.isFuelNotSelected = this.getFilterObjectIndex("drive") == -1;
        this.isYearNotSelected =
          this.getFilterObjectIndex("registration_year") == -1;
        this.isMileageNotSelected =
          this.getFilterObjectIndex("mileage") == -1;
        if (this.isMileageNotSelected) {
          this.mileageChecked = false;
        }
        if (this.isBodyTypeNotSelected) {
          this.isBodyTypeChecked = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    trucksList() {
      return this.$store.state.vehiclesList;
    },
    errorMessage() {
      return this.$store.state.vehicleFetchError;
    },
  },
  methods: {
    async fetchVehicles() {
      this.isLoading = true;
      await this.$store.dispatch("fetchVehicles");
      this.isLoading = false;
    },
    async fetchBrands() {
      this.isBrandLoading = true;
      const brandsResult = await DataRepository.fetchVehicleBrands();
      if (brandsResult.success && !brandsResult.error) {
        this.brandsList = brandsResult.success;
      } else {
        this.brandErrorMessage = brandsResult.error.message;
      }
      this.isBrandLoading = false;
    },
    async fetchBrandModels() {
      if (this.selectedBrand) {
        this.isBrandModelLoading = true;
        const brandModelsListResult =
          await DataRepository.fetchVehicleBrandModels(this.selectedBrand.id);
        if (brandModelsListResult.success && !brandModelsListResult.error) {
          this.brandModelsList = brandModelsListResult.success;
        } else {
          this.getBrandModelsError = brandModelsListResult.error.message;
        }
        this.isBrandModelLoading = false;
      }
    },
    async filterVehicles(filters) {
      this.isLoading = true;

      const filterResults = await DataRepository.filterVehicles(filters);
      if (filterResults.success && !filterResults.error) {
        this.$store.commit("updateVehicleList", filterResults.success);
      } else {
        this.$store.commit("setVehicleFetchError", filterResults.error.message);
      }
      this.isLoading = false;
    },
    brandItemClick() {
      // get brand_model.brand filter already exists
      const brandFilterIndex = this.getFilterObjectIndex("brand_model.brand");

      //get brand_model filter index
      const brandModelFilterIndex = this.getFilterObjectIndex("brand_model");

      if (this.selectedBrand != null) {
        if (brandModelFilterIndex !== -1) {
          this.deleteFilterObject(brandModelFilterIndex);
        }
        // If a filter already exists, update its value
        if (brandFilterIndex !== -1) {
          this.filters[brandFilterIndex].value = this.selectedBrand.id;
        } else {
          // If no filter exists, add a new filter object
          this.addFilterObject(
            "brand_model.brand",
            "eq",
            this.selectedBrand.id
          );
        }

        this.brandModelsList = [];
        this.selectedModel = null;
      } else {
        this.deleteFilterObject(brandFilterIndex);
        this.selectedBrand = null;
        this.brandModelsList = [];
        this.selectedModel = null;
      }

      this.filterVehicles(this.filters);
    },
    brandModelItemClicked() {
      const brandModelFilterIndex = this.filters.findIndex(
        (filter) => filter.column === "brand_model"
      );

      if (this.selectedModel) {
        if (brandModelFilterIndex !== -1) {
          this.filters[brandModelFilterIndex].value = this.selectedModel.id;
        } else {
          // If no filter exists, add a new filter object
          this.addFilterObject("brand_model", "eq", this.selectedModel.id);
        }
      } else {
        this.selectedModel = null;
        if (brandModelFilterIndex !== -1) {
          this.filters.splice(brandModelFilterIndex, 1);
        }
      }
      this.filterVehicles(this.filters);
    },
    transmissionChecked(filter) {
      const transmissionFilterIndex = this.getFilterObjectIndex("transmission");
      switch (filter) {
        case "any":
          this.deleteFilterObject(transmissionFilterIndex);
          break;
        default:
          if (transmissionFilterIndex !== -1) {
            this.filters[transmissionFilterIndex].value = filter;
          } else {
            this.addFilterObject("transmission", "ilike", filter);
          }
          break;
      }
      this.filterVehicles(this.filters);
    },
    yearChecked(uptoYears) {
      const year = this.getDateFromToday(uptoYears);
      //get year filter index
      const yearFilterIndex = this.getFilterObjectIndex("registration_year");

      if (year) {
        if (uptoYears === 6) {
          if (
            yearFilterIndex !== -1 &&
            this.filters[yearFilterIndex].operator == "lte"
          ) {
            this.filters[yearFilterIndex].value = year;
          } else {
            if (yearFilterIndex !== -1) {
              this.deleteFilterObject(-1);
            }
            this.addFilterObject("registration_year", "lte", year);
          }
        } else {
          if (
            yearFilterIndex !== -1 &&
            this.filters[yearFilterIndex].operator == "gte"
          ) {
            this.filters[yearFilterIndex].value = year;
          } else {
            if (yearFilterIndex !== -1) {
              this.deleteFilterObject(-1);
            }
            this.addFilterObject("registration_year", "gte", year);
          }
        }
        this.filterVehicles(this.filters);
      }
    },
    getDateFromToday(yearDifference) {
      const today = new Date();
      const currentDay = today.getDate();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();
      const yearsFromToday = currentYear - yearDifference;
      return `${yearsFromToday}-${currentMonth}-${currentDay}`;
    },
    sortingCriteriaIsSelected() {
      if (this.selectedSortCriteria) {
        this.sortVehicles(
          this.selectedSortCriteria.column,
          this.selectedSortCriteria.isAscending
        );
      }
    },
    filterByPrice() {
      const fromPriceFilterIndex = this.getFilterObjectIndex("price", "gte");
      const toPriceFilterIndex = this.getFilterObjectIndex("price", "lte");

      if (this.minPrice > this.maxPrice && this.maxPrice > 0) {
        this.priceFilterError = "Min price should be lower than Max price";
        this.deleteFilterObject(fromPriceFilterIndex);
        this.deleteFilterObject(toPriceFilterIndex);
      } else {
        if (this.minPrice > 0) {
          if (fromPriceFilterIndex !== -1) {
            this.filters[fromPriceFilterIndex].value = this.minPrice;
          } else {
            this.addFilterObject("price", "gte", this.minPrice);
          }
        }else{
          //delete price if it is 0 or any other value filter 
          fromPriceFilterIndex !== -1 ? this.deleteFilterObject(fromPriceFilterIndex) : null
          
        }
        if (this.maxPrice > 0) {
          if (toPriceFilterIndex !== -1) {
            this.filters[toPriceFilterIndex].value = this.maxPrice;
          } else {
            this.addFilterObject("price", "lte", this.maxPrice);
          }
        }else{
          //delete price if it is 0 or any other value filter 
          toPriceFilterIndex !== -1 ? this.deleteFilterObject(toPriceFilterIndex) : null
          
        }
      }

      this.filterVehicles(this.filters);
    },
    clearFilters() {
      this.filters = [];
      this.selectedBrand = null;
      this.selectedModel = null;
      this.brandModelsList = [];
      this.isManualTransmissionChecked = false;
      this.isPetrolChecked = false;
      this.isYearChecked = false;
      this.minPrice = 0;
      this.maxPrice = 0;
      this.filterVehicles(this.filters);
    },
    getFilterObjectIndex(columnName, operator) {
      // Operator is optional. If operator is null the operator and section evaluates to true else it matches the operator
      return this.filters.findIndex(
        (filter) =>
          filter.column === columnName &&
          (!operator || filter.operator === operator)
      );
    },
    addFilterObject(table, operator, value) {
      this.filters.push({
        column: table,
        operator: operator,
        value: value,
      });
    },
    deleteFilterObject(objectIndex) {
      this.filters.splice(objectIndex, 1);
    },
    filterByMileage(fromMileage, toMileage) {
      const fromMileageFilterIndex = this.getFilterObjectIndex(
        "mileage",
        "gte"
      );
      const toMileageFilterIndex = this.getFilterObjectIndex("mileage", "lte");

      if (fromMileage && toMileage) {
        //check if filters exists
        if (fromMileageFilterIndex !== -1) {
          this.filters[fromMileageFilterIndex].value = fromMileage;
        } else {
          this.addFilterObject("mileage", "gte", fromMileage);
        }

        if (toMileageFilterIndex !== -1) {
          this.filters[toMileageFilterIndex].value = toMileage;
        } else {
          this.addFilterObject("mileage", "lte", toMileage);
        }
      } else {
        if (fromMileage) {
          //check if filters exists
          if (fromMileageFilterIndex !== -1) {
            this.filters[fromMileageFilterIndex].value = fromMileage;
          } else {
            this.addFilterObject("mileage", "gte", fromMileage);
          }

          //check if other to filter exist and delete
          if (toMileageFilterIndex !== -1) {
            this.deleteFilterObject(toMileageFilterIndex);
          }
        } else if (toMileage) {
          if (toMileageFilterIndex !== -1) {
            this.filters[toMileageFilterIndex].value = toMileage;
          } else {
            this.addFilterObject("mileage", "lte", toMileage);
          }

          //check if other to filter exist and delete
          if (fromMileageFilterIndex !== -1) {
            this.deleteFilterObject(fromMileageFilterIndex);
          }
        }
      }

      this.filterVehicles(this.filters);
    },
    async sortVehicles(column, isAscending) {
      this.isLoading = true;

      const sortResults = await DataRepository.sortVehicles({
        column: column,
        ascending: isAscending,
      });
      if (sortResults.success && !sortResults.error) {
        this.$store.commit("updateVehicleList", sortResults.success);
      } else {
        this.errorMessage = sortResults.error.message;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.fetchVehicles();
    this.fetchBrands();

    //get query params from home page
    this.homePageBrandFilter = this.$route.query.brand;
    this.homePageVehicleTypeFilter = this.$route.query.type;
    if (this.homePageBrandFilter) {
      this.addFilterObject("brand_model.brand", "eq", this.homePageBrandFilter);
      this.filterVehicles(this.filters);
    }
    if (this.homePageVehicleTypeFilter) {
      this.addFilterObject("body_type", "eq", this.homePageVehicleTypeFilter);
      this.filterVehicles(this.filters);
    }
    //reset filters array to prevent users home filters overlapping
    this.filters = [];
  },
};
</script>
  