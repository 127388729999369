<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="closeCart">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div class="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">Trucks you have selected</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative -m-2 p-2 text-gray-400 hover:text-gray-500" @click="closeCart">
                          <span class="absolute -inset-0.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div class="mt-8">
                      <div class="flow-root">
                        <ul role="list" class="-my-6 divide-y divide-gray-200">
                          <li v-for="item in cartItems" :key="item.id" class="flex py-6">
                            <div class="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                              <img :src="item.imageSrc" :alt="item.imageAlt" class="h-full w-full object-cover object-center" />
                            </div>

                            <div class="ml-4 flex flex-1 flex-col">
                              <div>
                                <div class="flex justify-between text-base font-medium text-gray-900">
                                  <h3>{{ item.name }}</h3>
                                  <p class="ml-4">{{ item.price }}</p>
                                </div>
                                <p class="mt-1 text-sm text-gray-500">{{ item.color }}</p>
                              </div>
                              <div class="flex flex-1 items-end justify-between text-sm">
                                <p class="text-gray-500">Qty {{ item.quantity }}</p>

                                <div class="flex">
                                  <button type="button" class="font-medium text-orange-600 hover:text-orange-500" @click="removeFromCart(item)">Remove</button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
                    <div class="flex justify-between text-base font-medium text-gray-900">
                      <p>Subtotal</p>
                      <p>{{ cartTotal }}</p>
                    </div>
                    <p class="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
                    <div class="mt-6">
                      <a href="#" class="flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700">Checkout</a>
                    </div>
                    <div class="mt-6 flex justify-center text-center text-sm text-gray-500">
                      <p>
                        or
                        <button type="button" class="font-medium text-orange-600 hover:text-orange-500" @click="closeCart">
                          Continue Shopping
                          <span aria-hidden="true"> &rarr;</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon
  },
  setup() {
    const open = ref(true)
    const cartItems = ref([])
    const cartTotal = ref('$0.00')
    const userId = ref(null)

    const fetchCartItems = async () => {
      try {
        const { data, error } = await DataRepository.fetchUserCartItems(userId.value)
        if (error) {
          console.error('Error fetching cart items:', error)
        } else {
          cartItems.value = data[0].cart
          calculateCartTotal()
        }
      } catch (error) {
        console.error('Error fetching cart items:', error)
      }
    }

    const calculateCartTotal = () => {
      const total = cartItems.value.reduce((acc, item) => acc + item.price * item.quantity, 0)
      cartTotal.value = `$${total.toFixed(2)}`
    }

    const removeFromCart = async (item) => {
      try {
        // Make a request to the backend to remove the item from the cart
        const response = await fetch(`/api/cart/${item.id}`, {
          method: 'DELETE'
        })
        if (response.ok) {
          cartItems.value = cartItems.value.filter(i => i.id !== item.id)
          calculateCartTotal()
        } else {
          console.error('Error removing item from cart')
        }
      } catch (error) {
        console.error('Error removing item from cart:', error)
      }
    }

    const closeCart = () => {
      open.value = false
    }

    fetchCartItems()

    return {
      open,
      cartItems,
      cartTotal,
      removeFromCart,
      closeCart
    }
  }
}
</script>